import React, { useEffect } from 'react'
import style from './index.module.scss'

import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const raggingPNG = require('@/assets/img/software/rigger.png').default

interface IProps {}

let initData = 0
const Rigger = (props: IProps) => {
  const Rigger = useRootStore(
    (state) => state.OperationPersonal.recent.soft.Rigger
  )
  const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Rigger',
      payload: { count: Number(e.target.value) },
    })
  }
  const Rigger_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Rigger',
      payload: { count: e.target.checked ? -1 : initData },
    })
  }
  const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Rigger',
      payload: { gift: Number(e.target.value) },
    })
  }
  const onChangeArkitTemplate = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Rigger',
      payload: { arkit_template: value ? 1 : 0 },
    })
  }
  const onChangEemilyTemplate = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Rigger',
      payload: { emily_template: value ? 1 : 0 },
    })
  }
  useEffect(() => {
    if (Rigger.count === -1) return
    initData = Rigger.count
  }, [])
  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={raggingPNG} alt="" />
        <div className={style.title}>Rigger权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineName}>每月可用总个数:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Rigger.count === -1 ? '无限' : Rigger.count}
              onChange={changeDay}
              spellCheck={false}
              suffix="个"
            />
            <Checkbox
              checked={Rigger.count === -1}
              onChange={Rigger_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>赠送:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Rigger.gift}
              onChange={changeGift}
              disabled={Rigger.count === -1}
              spellCheck={false}
              suffix="个"
            />
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>ARKit模板:</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Rigger.arkit_template)}
              onChange={onChangeArkitTemplate}
            />
          </div>
          <div className={style.lineName}>Emily模板：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Rigger.emily_template)}
              onChange={onChangEemilyTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rigger
