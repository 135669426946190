import {TranslateType} from "@/interface";
import {DropdownType} from "@/interface/recentType";


type recentDataMap = {
    "replace_Education_Dropdown": { grade_id: 0, grade_name: '' }[]
    "replace_Enterprise_Dropdown": { grade_id: 0, grade_name: '' }[]
}

let init: DropdownType = {
    EducationDropdown: [{grade_id: 0, grade_name: ''}],
    EnterpriseDropdown: [{grade_id: 0, grade_name: ''}],
}

const reducer = (dataInit = init, data: TranslateType<recentDataMap>): DropdownType => {
    switch (data.type) {
        case "replace_Education_Dropdown":
            return {
                ...dataInit,
                EducationDropdown: data.payload
            }
        case "replace_Enterprise_Dropdown":
            return {
                ...dataInit,
                EnterpriseDropdown: data.payload
            }
        default:
            return {...dataInit}
    }
}
export default reducer
