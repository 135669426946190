import { TranslateType } from '@/interface'
import {
  A_MarketEducationReduxType,
  MarketEducationRecent,
  MarketEducationReduxType,
} from '@/interface/recentType'
import Streamer from '@/components/SoftwareInfo_Add/Streamer'
import Retargeter from '@/components/SoftwareInfo_Add/Retargeter'
import Tracker from '@/components/SoftwareInfo_Add/Tracker'
import Muscledrive from '@/components/SoftwareInfo_Add/MuscleDrive'
import Rigger from '@/components/SoftwareInfo_Add/Rigger'
import Livedrive from '@/components/SoftwareInfo_Add/LiveDrive'
import Batchrobot from '@/components/SoftwareInfo_Add/Batchrobot'
import Seattlepro from '@/components/SoftwareInfo_Add/Seattlepro'

type recentDataMap = {
  replace_allData_MarketEducation_Add: MarketEducationReduxType[]
  replace_Recent_MarketEducation_Add: MarketEducationRecent
  change_Recent_MarketEducation_option_Add:
    | { grade_id: number }
    | { expire: number }
    | { company_usage_day: string }
    | { company_usage_date: string }
    | { company_upgrade_date: string }
    | { company_title: string }
    | { company_show_pass: string }
    | { company_account_num: string }
    | { company_effect_date: string }
    | { company_id: number }
    | { company_name: string }
  change_Recent_MarketEducation_soft_config_Add: {
    soft_title: string
    soft_on: number
  }
  clear_Recent_MarketEducation_Add: {}
  replace_Recent_MarketEducation_Account_soft_to_soft_config_Add: {
    soft_id: number
    soft_title: string
    soft_on: number
    soft_disable: number
  }[]
}

let init: A_MarketEducationReduxType = {
  allData: [],
  recent: {
    company_account_num: '',
    company_effect_date: '',
    company_id: 0,
    company_name: '',
    company_show_pass: '',
    company_title: '',
    company_upgrade_date: '',
    company_usage_date: '',
    company_usage_day: '',
    expire: 0,
    grade_id: 0,
    soft_config: [
      [
        { soft_id: 0, soft_title: 'Autoface', soft_on: 0, soft_disable: 0 },
        { soft_id: 0, soft_title: 'Rigger', soft_on: 0, soft_disable: 0 },
        { soft_id: 0, soft_title: 'Muscledrive', soft_on: 0, soft_disable: 0 },
        { soft_id: 0, soft_title: 'Tracker', soft_on: 0, soft_disable: 0 },
        { soft_id: 0, soft_title: 'Streamer', soft_on: 0, soft_disable: 0 },
        {
          soft_id: 0,
          soft_title: 'Seattleaitrainer',
          soft_on: 0,
          soft_disable: 0,
        },
        { soft_id: 0, soft_title: 'Fgmodeledit', soft_on: 0, soft_disable: 0 },
        { soft_id: 0, soft_title: 'Riggermeta', soft_on: 0, soft_disable: 0 },
        {
          soft_id: 0,
          soft_title: 'Posemakerposeasset',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Posemakerfbx',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Livedriverealtimemeta',
          soft_on: 0,
          soft_disable: 0,
        },
      ],
      [
        { soft_id: 0, soft_title: 'Retargeter', soft_on: 0, soft_disable: 0 },
        {
          soft_id: 0,
          soft_title: 'Retargeterofflinearkit',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Retargeterofflinemeta',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Retargeterofflineposeasset',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Retargeterseattleaiofflinecustom',
          soft_on: 0,
          soft_disable: 0,
        },
      ],
      [
        { soft_id: 0, soft_title: 'Livedrive', soft_on: 0, soft_disable: 0 },
        {
          soft_id: 0,
          soft_title: 'Livedriverealtimeposeasset',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Livedriverealtimecustom',
          soft_on: 0,
          soft_disable: 0,
        },
      ],
      [
        { soft_id: 0, soft_title: 'Batchrobot', soft_on: 0, soft_disable: 0 },
      
        {
          soft_id: 0,
          soft_title: 'Batchrobotarkit',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Batchrobotmeta',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Batchrobotposeasset',
          soft_on: 0,
          soft_disable: 0,
        },
        {
          soft_id: 0,
          soft_title: 'Batchrobotcustom',
          soft_on: 0,
          soft_disable: 0,
        },
      ],
    ],
    
  },
}

const reducer = (
  dataInit = init,
  data: TranslateType<recentDataMap>
): A_MarketEducationReduxType => {
  switch (data.type) {
    case 'replace_allData_MarketEducation_Add':
      return {
        ...dataInit,
        allData: data.payload,
      }
    case 'replace_Recent_MarketEducation_Add':
      return {
        ...dataInit,
        recent: data.payload,
      }
    case 'change_Recent_MarketEducation_option_Add':
      return {
        ...dataInit,
        recent: { ...dataInit.recent, ...data.payload },
      }
    case 'replace_Recent_MarketEducation_Account_soft_to_soft_config_Add':
      return {
        ...dataInit,
        recent: { ...dataInit.recent, soft_config: [{ ...data.payload }] },
      }
    case 'change_Recent_MarketEducation_soft_config_Add':
      let soft = dataInit.recent.soft_config.map((item:any, index :any) => {
        if (item[index].soft_title === data.payload.soft_title) {
          item[index].soft_on = data.payload.soft_on
        }
        return item
      })
      // console.log('---lll', {
      //   ...dataInit,
      //   recent: { ...dataInit.recent, soft_config: soft },
      // })
      return {
        ...dataInit,
        recent: { ...dataInit.recent, soft_config: soft },
      }
    case 'clear_Recent_MarketEducation_Add':
      return {
        ...dataInit,
        recent: {
          company_account_num: '',
          company_effect_date: '',
          company_id: 0,
          company_name: '',
          company_show_pass: '',
          company_title: '',
          company_upgrade_date: '',
          company_usage_date: '',
          company_usage_day: '',
          expire: 0,
          grade_id: 0,
          soft_config: [
            [
              { soft_id: 0, soft_title: 'Autoface', soft_on: 0, soft_disable: 0 },
              { soft_id: 0, soft_title: 'Rigger', soft_on: 0, soft_disable: 0 },
              { soft_id: 0, soft_title: 'Muscledrive', soft_on: 0, soft_disable: 0 },
              { soft_id: 0, soft_title: 'Tracker', soft_on: 0, soft_disable: 0 },
              { soft_id: 0, soft_title: 'Streamer', soft_on: 0, soft_disable: 0 },
              {
                soft_id: 0,
                soft_title: 'Seattleaitrainer',
                soft_on: 0,
                soft_disable: 0,
              },
              { soft_id: 0, soft_title: 'Fgmodeledit', soft_on: 0, soft_disable: 0 },
              { soft_id: 0, soft_title: 'Riggermeta', soft_on: 0, soft_disable: 0 },
              {
                soft_id: 0,
                soft_title: 'Posemakerposeasset',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Posemakerfbx',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Livedriverealtimemeta',
                soft_on: 0,
                soft_disable: 0,
              },
            ],
            [
              { soft_id: 0, soft_title: 'Retargeter', soft_on: 0, soft_disable: 0 },
              {
                soft_id: 0,
                soft_title: 'Retargeterofflinearkit',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Retargeterofflinemeta',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Retargeterofflineposeasset',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Retargeterseattleaiofflinecustom',
                soft_on: 0,
                soft_disable: 0,
              },
            ],
            [
              { soft_id: 0, soft_title: 'Livedrive', soft_on: 0, soft_disable: 0 },
              {
                soft_id: 0,
                soft_title: 'Livedriverealtimeposeasset',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Livedriverealtimecustom',
                soft_on: 0,
                soft_disable: 0,
              },
            ],
            [
              { soft_id: 0, soft_title: 'Batchrobot', soft_on: 0, soft_disable: 0 },
            
              {
                soft_id: 0,
                soft_title: 'Batchrobotarkit',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Batchrobotmeta',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Batchrobotposeasset',
                soft_on: 0,
                soft_disable: 0,
              },
              {
                soft_id: 0,
                soft_title: 'Batchrobotcustom',
                soft_on: 0,
                soft_disable: 0,
              },
            ],
          ],
        },
      }
    default:
      return { ...dataInit }
  }
}
export default reducer
