import React, { useEffect } from 'react'
import style from './index.module.scss'

import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const SeattleproPNG = require('@/assets/img/software/Seattlepro.png').default

interface IProps {}

let offlineInitData = 0,
  onloneInitData = 0

const Seattlepro = (props: IProps) => {
  const Seattlepro = useRootStore(
    (state) => state.OperationPersonal.recent.soft.Seattlepro
  )
  // console.log("Seattlepro--",Seattlepro)
  const changeOfflineFrame = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: { offline_frame: Number(e.target.value) },
    })
  }
  const changeOnlineTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: { ...Seattlepro, online_time: Number(e.target.value) },
    })
  }
  const Seattlepro_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: {
        ...Seattlepro,
        offline_frame: e.target.checked ? -1 : offlineInitData,
      },
    })
  }
  const Seattlepro_time_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: {
        ...Seattlepro,
        online_time: e.target.checked ? -1 : onloneInitData,
      },
    })
  }
  const onChangeOffline = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: {
        ...Seattlepro,
        offline: value ? 1 : 0,
        offline_frame: value ? offlineInitData : 0,
      },
    })
  }
  const onChangeOnline = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: {
        ...Seattlepro,
        online: value ? 1 : 0,
        online_time: value ? onloneInitData : 0,
      },
    })
  }
  const onChangeEditFgmodeledit = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Seattlepro',
      payload: { fgmodeledit: value ? 1 : 0 },
    })
  }
  useEffect(() => {
    if (Seattlepro.offline_frame === -1) return
    if (Seattlepro.online_time === -1) return
    offlineInitData = Seattlepro.offline_frame
    onloneInitData = Seattlepro.online_time
  }, [])

  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={SeattleproPNG} alt="" className={style.imgWidth} />
        <div className={style.title}>Seattlepro权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineFristName}>SeattlePro离线计算：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Seattlepro.offline)}
              onChange={onChangeOffline}
            />
          </div>
        </div>
        <div
          className={style.Line}
          style={{ display: Boolean(Seattlepro.offline) ? 'flex' : 'none' }}
        >
          <div className={style.lineName}>帧数：</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={
                Seattlepro.offline_frame === -1
                  ? '无限'
                  : Seattlepro.offline_frame
              }
              onChange={changeOfflineFrame}
              spellCheck={false}
              suffix="帧"
            />
            <Checkbox
              checked={Seattlepro.offline_frame === -1}
              onChange={Seattlepro_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineFristName}>SeattlePro实时计算：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Seattlepro.online)}
              onChange={onChangeOnline}
            />
          </div>
        </div>
        <div
          className={style.Line}
          style={{ display: Boolean(Seattlepro.online) ? 'flex' : 'none' }}
        >
          <div className={style.lineName}>时长：</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={
                Seattlepro.online_time === -1 ? '无限' : Seattlepro.online_time
              }
              onChange={changeOnlineTime}
              spellCheck={false}
              suffix="分钟"
            />
            <Checkbox
              checked={Seattlepro.online_time === -1}
              onChange={Seattlepro_time_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        {/* <div className={style.Line}>
                    <div className={style.lineFristName}>Fgmodeledit修改开关：</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Seattlepro.fgmodeledit)} onChange={onChangeEditFgmodeledit}/>
                    </div>
                </div> */}
      </div>
    </div>
  )
}

export default Seattlepro
