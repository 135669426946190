import {TranslateType} from "@/interface";
import {A_UserLoginReduxType, UserLoginReduxType} from "@/interface/recentType";


type recentDataMap = {
    "write_allData_UserLogin": UserLoginReduxType
    "write_recent_UserLogin": UserLoginReduxType
    "change_recent_a_data": { setting_account: number } | { setting_device: number } | { notify_day: number } | { notify_content: string }
    "merge_recent_to_allData": {}
}
// type bbb<T> = { [P in keyof T]: T[P]}
// type c = bbb<UserLoginReduxType>
// type  c = { a: string, b: number }
//
// type demo<K extends keyof any, T> = {[P in K]:T[keyof T]}
// type g=demo<keyof c,c>
//
// type a = Record<keyof UserLoginReduxType, UserLoginReduxType[keyof UserLoginReduxType]>

let init: A_UserLoginReduxType = {
    allData: [],
    recent: {
        setting_account: 0,
        setting_device: 0,
        notify_day: 0,
        notify_content: '',
        key: ''
    }
}

const reducer = (dataInit = init, data: TranslateType<recentDataMap>): A_UserLoginReduxType => {
    switch (data.type) {
        case "write_allData_UserLogin":
            return {
                ...dataInit,
                allData: [...dataInit.allData.filter(item => item.key !== data.payload.key), data.payload]
            }
        case "write_recent_UserLogin":
            return {...dataInit, recent: {...dataInit.recent, ...data.payload}}
        case "change_recent_a_data":
            return {...dataInit, recent: {...dataInit.recent, ...data.payload}}
        case "merge_recent_to_allData":
            let key = dataInit.recent.key
            let Data = dataInit.allData.filter(item => item.key !== key)
            return {...dataInit, allData: [...Data, dataInit.recent]}
        default:
            return {...dataInit}
    }
}
export default reducer
