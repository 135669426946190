import {TableType} from "@/interface/tableType";

const UserLoginTable: TableType[] = [
    {
        title: "单电脑允许账户数",
        key: "setting_account",
    }, {
        title: "允许修改登录次数",
        key: "setting_device",
    }, {
        title: "到期提前几天提醒",
        key: "notify_day",
    }, {
        title: "提醒内容",
        key: "notify_content",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "设置", title: "个人用户登录限制", key: "LimitSettingsModal", symbol: "PersonalLogin"},
            {buttonTitle: "提醒设置", title: "个人提醒设置", key: "ReminderSettingsModal", symbol: "PersonalLogin"},
        ]
    },
]
const EnterpriseLoginTable: TableType[] = [
    {
        title: "单电脑允许账户数",
        key: "setting_account",
    }, {
        title: "允许修改登录次数",
        key: "setting_device",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "设置", title: "企业用户登录限制", key: "LimitSettingsModal", symbol: "EnterpriseLogin"},
        ]
    }
]
const EducationLoginTable: TableType[] = [
    {
        title: "单电脑允许账户数",
        key: "setting_account",
    }, {
        title: "允许修改登录次数",
        key: "setting_device",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "设置", title: "教育用户登录限制", key: "LimitSettingsModal", symbol: "EducationLogin"},
        ]
    }
]
export {UserLoginTable, EnterpriseLoginTable, EducationLoginTable}
