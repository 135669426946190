import DelayModal from "./DelayModal"
import DeleteModal from "./DeleteModal"
import MarketEducationModal from "./MarketEducationModal"
import MarketEducationModal_Add from "./MarketEducationModal_Add"
import MarketEnterpriseModal from "./MarketEnterpriseModal"
import MarketEnterpriseModal_Add from "./MarketEnterpriseModal_Add"
import OperationEducationModal from "./OperationEducationModal"
import OperationEnterpriseModal from "./OperationEnterpriseModal"
import OperationPersonalModal from "./OperationPersonalModal"
import OperationEducationModal_Add from "./OperationEducationModal_Add"
import OperationEnterpriseModal_Add from "./OperationEnterpriseModal_Add"
import OperationPersonalModal_Add from "./OperationPersonalModal_Add"
import LimitSettingsModal from "./LimitSettingsModal"
import ReminderSettingsModal from "./ReminderSettingsModal"

const ModalMap = {
    DelayModal,
    DeleteModal,
    MarketEducationModal,
    MarketEnterpriseModal,
    MarketEducationModal_Add,
    MarketEnterpriseModal_Add,
    OperationEducationModal,
    OperationEnterpriseModal,
    OperationPersonalModal,
    OperationEducationModal_Add,
    OperationEnterpriseModal_Add,
    OperationPersonalModal_Add,
    LimitSettingsModal,
    ReminderSettingsModal,
}
export type ModalMapType = keyof typeof ModalMap

interface IProps {
    ModuleName: ModalMapType
}

const CreatModal = ({ModuleName}: IProps) => {
    let ModalType = ModalMap[ModuleName]
    return (<ModalType/>)
}
export default CreatModal
