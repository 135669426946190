import React, { useEffect, useState } from 'react'
import style from './index.module.scss'
import { Button, message, Space, Table } from 'antd'
import { PageSize } from '@/UISetting/DropdownSetting'
import OperationPersonalTable from '@/UISetting/OperationPersonalTable'
import { isNullOrUndefined } from '@/utils/util'
import Dialog from '@/components/Dialog'
import { OperationPersonalReduxType } from '@/interface/recentType'
import { spaceOptionType, symbolType } from '@/interface/tableType'
import { GlobalDispatch, useRootStore } from '@/store'
import {
  grade_info_OperationPersonal_Fun,
  personal_list_Fun,
} from '@/api/OperationApiFun'

const { Column } = Table

interface IProps {}

const OperationPersonal = (props: IProps) => {
  const OperationPersonalData = useRootStore(
    (state) => state.OperationPersonal.allData
  )
  // debugger
  let size = useRootStore((state) => state.PageSize.OperationPersonal)
  // console.log('取出的数量' + size)

  const [currentPage, setCurrentPage] = useState<number>(1)
  sessionStorage.setItem('PERSONAL-CURRENTPAGE', currentPage.toString())
  const [visible, setVisible] = useState(false)
  const [DelData, setDelData] = useState([])
  const [OptionType, setOptionType] = useState<spaceOptionType>({
    buttonTitle: '',
    title: '',
    key: 'LimitSettingsModal',
    symbol: 'EducationLogin',
  })

  useEffect(() => {
    personal_list_Fun(currentPage).then((_) => {})
  }, [currentPage])
  const changePage = (page: any) => {
    setCurrentPage(page)
    sessionStorage.setItem('PERSONAL-CURRENTPAGE', page)
    personal_list_Fun(page).then((_) => {})
  }
  const operationClickHandle = (
    e: any,
    item: spaceOptionType,
    recode: OperationPersonalReduxType
  ) => {
    // console.log('recode'+ recode);
    setOptionType(item)
    setVisible(true)
    if (item.key === 'DeleteModal') {
      GlobalDispatch({
        type: 'replace_del_array',
        payload: { Del_Key: recode.key, symbol: item.symbol },
      })
      return
    }
    grade_info_OperationPersonal_Fun(recode.key).then((_) => {})
  }
  const addRow = () => {
    setOptionType({
      buttonTitle: '新增',
      title: '新增个人选项',
      key: 'OperationPersonalModal_Add',
      symbol: 'OperationPersonal_Add',
    })
    setVisible(true)
  }
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setDelData(selectedRowKeys)
    },
  }
  useEffect(() => {
    if (isNullOrUndefined(DelData)) return
    let newArray = DelData.map<{ Del_Key: string; symbol: symbolType }>(
      (item) => {
        return { Del_Key: item, symbol: 'OperationPersonal' }
      }
    )
    GlobalDispatch({ type: 'replace_del', payload: newArray })
  }, [DelData])
  const DelMore = () => {
    if (DelData.length === 0) {
      message.error('请选择要删除的列表')
      return
    }
    setOptionType({
      buttonTitle: '删除',
      title: '全部删除',
      key: 'DeleteModal',
      symbol: 'OperationPersonal',
    })
    setVisible(true)
  }
  // console.log('pagination-current:' + currentPage)
  // console.log('pagination-PageSize:' + PageSize)
  // console.log('pagination-total:' + size)
  return (
    <div className={style.container}>
      <div className={style.control}>
        <div className={style.button}>
          <Button type="primary" onClick={addRow}>
            新增
          </Button>
          <Button type="primary" onClick={DelMore} danger>
            删除
          </Button>
        </div>
      </div>
      <div className={style.Table}>
        <Table
          dataSource={OperationPersonalData}
          pagination={{
            position: ['bottomLeft'],
            current: currentPage,
            pageSize: PageSize,
            total: size,
            onChange: changePage,
          }}
          rowKey={(record) => record.key}
          rowSelection={{ ...rowSelection }}
        >
          {OperationPersonalTable.map((item) => {
            return (
              <Column
                title={item.title}
                dataIndex={item.key}
                key={item.key}
                render={(
                  text: string,
                  recode: OperationPersonalReduxType,
                  index: number
                ) => {
                  if (isNullOrUndefined(item.SpaceOption)) {
                    if (item.key === 'SerialNumber') {
                     const currentpage:any = sessionStorage.getItem('personal-currentpage')
                    //  setCurrentPage(currentpage)
                      // const lengthTemp: any =
                        // sessionStorage.getItem('LIST-LENGTH')
                        // const length = parseInt(lengthTemp)
                        // console.log("currentPage:" + currentPage);
                        // OperationPersonalData.
                        // if(OperationPersonalData.length == 0){
                        //   return index + (currentPage - 2) * PageSize + 1
                        // }

                      
                        return index + (currentpage - 1) * PageSize + 1
                    }
                    return text
                  }
                  return (
                    <Space size="middle">
                      {item.SpaceOption!.map((item) => {
                        return (
                          <Button
                            type="primary"
                            key={item.key}
                            onClick={(e) => {
                              operationClickHandle(e, item, recode)
                            }}
                          >
                            {item.buttonTitle}
                          </Button>
                        )
                      })}
                    </Space>
                  )
                }}
              />
            )
          })}
        </Table>
      </div>
      <Dialog
        OptionType={OptionType}
        ModuleVisible={visible}
        setModuleVisible={setVisible}
      />
    </div>
  )
}
export default OperationPersonal
