import React from 'react'
import style from './index.module.scss'
import { GlobalDispatch, MarketMap, useRootStore } from '@/store'
import { Switch } from 'antd'
import { softNameMap } from '@/components/SoftwareInfo'
import { isNullOrUndefined } from '@/utils/util'

interface IProps {
  recentName: MarketMap
}

const SoftwareControl = ({ recentName }: IProps) => {
  const softwareList = useRootStore((state) => state.software)
  const MarketAccount_soft = useRootStore((state) => state.MarketAccount_soft)
  console.log(softwareList, 'softwareList')
  console.log(MarketAccount_soft, 'MarketAccount_soft')

  const recentData = useRootStore((state) => state[recentName]).recent
  console.log(recentData.soft_config, 'recentData')

  // console.log(recentName,'recentName');

  const onChange = (checked: boolean, softwareKey: softNameMap) => {
    if (recentName === 'MarketEducation_Add') {
      GlobalDispatch({
        type: 'change_Recent_MarketEducation_soft_config_Add',
        payload: { soft_title: softwareKey, soft_on: checked ? 1 : 0 },
      })
    }
    if (recentName === 'MarketEnterprise_Add') {
      GlobalDispatch({
        type: 'change_Recent_MarketEnterprise_soft_config_Add',
        payload: { soft_title: softwareKey, soft_on: checked ? 1 : 0 },
      })
    }
    if (recentName === 'MarketEducation') {
      GlobalDispatch({
        type: 'change_Recent_MarketEducation_soft_config',
        payload: { soft_title: softwareKey, soft_on: checked ? 1 : 0 },
      })
    }
    if (recentName === 'MarketEnterprise') {
      GlobalDispatch({
        type: 'change_Recent_MarketEnterprise_soft_config',
        payload: { soft_title: softwareKey, soft_on: checked ? 1 : 0 },
      })
    }
  }

  return (
    <div>
      <div className={style.softBox}>
        {softwareList[0].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              disabled={Boolean(
                MarketAccount_soft[0].find(
                  (MarketAccount_soft_item: any) =>
                    MarketAccount_soft_item.soft_title === item.service_title
                )!.soft_disable
              )}
              checked={
                // recentData.soft_config不为空或者undefined 在recetData.soft_config里面拿第一个对象，再寻找里面soft_on不为空且soft_tittle等于第一个对象service_tittle的是否存在
                !isNullOrUndefined(recentData.soft_config) &&
                Boolean(
                  recentData.soft_config[0].find(
                    (soft_config_item: any) =>
                      item.service_title ===
                      soft_config_item.soft_title!.soft_on
                  )
                )
              }
            
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[1].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              disabled={Boolean(
                MarketAccount_soft[1].find(
                  (MarketAccount_soft_item: any) =>
                    MarketAccount_soft_item.soft_title === item.service_title
                )!.soft_disable
              )}
              checked={
                !isNullOrUndefined(recentData.soft_config) &&
                Boolean(
                  recentData.soft_config[1].find(
                    (soft_config_item :any) =>
                      item.service_title === soft_config_item.soft_title
                  )!.soft_on
                )
              }
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[2].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              disabled={Boolean(
                MarketAccount_soft[2].find(
                  (MarketAccount_soft_item: any) =>
                    MarketAccount_soft_item.soft_title === item.service_title
                )!.soft_disable
              )}
              checked={
                !isNullOrUndefined(recentData.soft_config) &&
                Boolean(
                  recentData.soft_config[2].find(
                    (soft_config_item :any) =>
                      item.service_title === soft_config_item.soft_title
                  )!.soft_on
                )
              }
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[3].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              disabled={Boolean(
                MarketAccount_soft[3].find(
                  (MarketAccount_soft_item: any) =>
                    MarketAccount_soft_item.soft_title === item.service_title
                )!.soft_disable
              )}
              checked={
                !isNullOrUndefined(recentData.soft_config) &&
                Boolean(
                  recentData.soft_config[3].find(
                    (soft_config_item:any) =>
                      item.service_title === soft_config_item.soft_title
                  )!.soft_on
                )
              }
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default SoftwareControl
