import Autoface from './AutoFace'
import Livedrive from './LiveDrive'
import Rigger from './Rigger'
import Riggermeta from './Riggermeta'
import Muscledrive from './MuscleDrive'
import Retargeter from './Retargeter'
import Streamer from './Streamer'
import Tracker from './Tracker'
import Batchrobot from './Batchrobot'
import Seattlepro from './Seattlepro'
import Seattleaitrainer from './Seattleaitrainer'
// import Fgmodeledit from './Fgmodeledit'
// import Posemakerfbx from './Posemakerfbx'
// import Posemakerposeasset from './Posemakerposeasset'

let softMap = {
  Streamer,
  Retargeter,
  Tracker,
  Muscledrive,
  Rigger,
  Riggermeta,
  Livedrive,
  Autoface,
  Batchrobot,
  Seattlepro,
  Seattleaitrainer,
  Fgmodeledit: 'Fgmodeledit',
  Posemakerfbx: 'Posemakerfbx',
  Posemakerposeasset: 'Posemakerposeasset',
  Retargeterofflinearkit: 'Retargeterofflinearkit',
  Retargeterofflinemeta: 'Retargeterofflinemeta',
  Retargeterofflineposeasset: 'Retargeterofflineposeasset',
  Retargeterseattleaiofflinecustom: 'Retargeterseattleaiofflinecustom',
  Livedriverealtimemeta: 'Livedriverealtimemeta',
  Livedriverealtimeposeasset: 'Livedriverealtimeposeasset',
  Livedriverealtimecustom: 'Livedriverealtimecustom',
  Batchrobotarkit: 'Batchrobotarkit',
  Batchrobotmeta: 'Batchrobotmeta',
  Batchrobotposeasset: 'Batchrobotposeasset',
  Batchrobotcustom: 'Batchrobotcustom',
}

export type softNameMap = keyof typeof softMap

interface IProps {
  name: softNameMap
}

const SoftWare = ({ name }: IProps) => {
  let SoftWareName = softMap[name]
  {
    // @ts-ignore
    if (name !== 'Seattlelitemetahuman') {
      return <SoftWareName />
    } else {
      return null
    }
  }
}
export default SoftWare
