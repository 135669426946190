import React, {useState} from 'react';
import {GlobalDispatch, useRootStore} from "@/store";
import PublicStyle from "../index.module.scss"
import {Input, message} from "antd";
import TextArea from "antd/es/input/TextArea";

interface IProp {
}

const ReminderSettingsModal = (props: IProp) => {
    const optionKey = useRootStore(state => state.LoginSetting.recent.key)
    const allData = useRootStore(state => state.LoginSetting.allData)
    const data = allData.filter(item => item.key === optionKey)[0]
    const [Day, setDay] = useState(data.notify_day)
    const [NotifyContent, setNotifyContent] = useState(data.notify_content)
    const changeNotifyDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        setDay(Number(e.target.value))
        GlobalDispatch({type: "change_recent_a_data", payload: {notify_day: Number(e.target.value)}})
    }
    const changeNotifyContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotifyContent(e.target.value)
        GlobalDispatch({type: "change_recent_a_data", payload: {notify_content: e.target.value}})
    }
    return (<div className={PublicStyle.Box}>
        <div className={PublicStyle.title}>基础设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>到期前几天提醒：</span><Input
                style={{width: "45%"}} value={Day} onChange={changeNotifyDay} suffix="天"/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>提醒内容：</span>
                <TextArea value={NotifyContent} onChange={changeNotifyContent} rows={4}/>
            </div>
            <div>注：设置提醒天数后，系统会每隔24小时，进行一次弹窗提醒，直至账户续费，或者过期。</div>
        </div>
    </div>)
}
export default ReminderSettingsModal
