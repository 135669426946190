import {
  enterprise_listApi,
  grade_infoApi,
  grade_listApi,
  softApi,
} from '@/api/OperationApi'
import { message } from 'antd'
import { GlobalDispatch } from '@/store'
import {
  education_list_interface,
  enterprise_list_interface,
  grade_info_Education_interface,
  grade_info_Enterprise_interface,
  grade_info_Personal_interface,
  market_index_education_interface,
  market_index_EnterPrice_interface,
} from '@/interface/phpInterface'
import { gradeApi, market_indexApi } from '@/api/MarketApi'
import { OperationType } from '@/interface/EnumList'
import packageConfig from '../../package.json'

const enterprise_list_Fun = async (currentPage: number, type: number) => {
  try {
    let login_setting_Enterprise = await enterprise_listApi(currentPage, type)
    if (login_setting_Enterprise.data.code !== 200) {
      message.error(login_setting_Enterprise.data.msg)
      return
    }
    GlobalDispatch({
      type: 'replace_allData_OperationEnterprise',
      payload: (login_setting_Enterprise.data as enterprise_list_interface).data
        .data,
    })
    sessionStorage.setItem(
      'enterprise-currentpage',
      login_setting_Enterprise.data.data.current_page.toString()
    )

    GlobalDispatch({
      type: 'replace_page_size',
      payload: {
        OperationEnterprise: (
          login_setting_Enterprise.data as enterprise_list_interface
        ).data.total,
      },
    })
  } catch {
    message.error('网络不通畅')
  }
}
const education_list_Fun = async (currentPage: number, type: number) => {
  try {
    let login_setting_Education = await enterprise_listApi(currentPage, type)
    if (login_setting_Education.data.code !== 200) {
      message.error(login_setting_Education.data.msg)
      return
    }

    GlobalDispatch({
      type: 'replace_allData_OperationEducation',
      payload: (login_setting_Education.data as education_list_interface).data
        .data,
    })
    sessionStorage.setItem(
      'educationl-currentpage',
      login_setting_Education.data.data.current_page.toString()
    )

    console.log('currentpage:' + login_setting_Education.data.data.current_page)
    GlobalDispatch({
      type: 'replace_page_size',
      payload: {
        OperationEducation: (
          login_setting_Education.data as education_list_interface
        ).data.total,
      },
    })
  } catch {
    message.error('网络不通畅')
  }
}
const personal_list_Fun = async (currentPage: number) => {
  try {
    let login_setting_Personal = await grade_listApi(currentPage)
    if (login_setting_Personal.data.code !== 200) {
      message.error(login_setting_Personal.data.msg)
      return
    }
    GlobalDispatch({
      type: 'replace_allData_OperationPersonal',
      payload: login_setting_Personal.data.data.data,
    })
    // sessionStorage.setItem('LIST-LENGTH' , login_setting_Personal.data.data.data.length.toString())

    sessionStorage.setItem(
      'personal-currentpage',
      login_setting_Personal.data.data.current_page.toString()
    )
    // console.log( '返回总数'+login_setting_Personal.data.data.total);
    GlobalDispatch({
      type: 'replace_page_size',
      payload: { OperationPersonal: login_setting_Personal.data.data.total },
    })
  } catch {
    message.error('网络不通畅')
  }
}
const market_Education_index_Fun = async (
  type: number,
  currentPage: number,
  grade?: number,
  state?: string,
  title?: string
) => {
  try {
    let market_education = await market_indexApi(
      type,
      currentPage,
      grade,
      state,
      title
    )
    GlobalDispatch({
      type: 'replace_allData_MarketEducation',
      payload: (market_education.data as market_index_education_interface).data
        .data,
    })
    GlobalDispatch({
      type: 'replace_page_size',
      payload: {
        MarketEducation: (
          market_education.data as market_index_EnterPrice_interface
        ).data.total,
      },
    })
  } catch {
    message.error('网络不通畅')
  }
}
const market_Enterprise_index_Fun = async (
  type: number,
  currentPage: number,
  grade?: number,
  state?: string,
  title?: string
) => {
  try {
    let market_Enterprise = await market_indexApi(
      type,
      currentPage,
      grade,
      state,
      title
    )
    GlobalDispatch({
      type: 'replace_allData_MarketEnterPrice',
      payload: (market_Enterprise.data as market_index_EnterPrice_interface)
        .data.data,
    })
    GlobalDispatch({
      type: 'replace_page_size',
      payload: {
        MarketEnterprise: (
          market_Enterprise.data as market_index_EnterPrice_interface
        ).data.total,
      },
    })
  } catch {
    message.error('网络不通畅')
  }
}
const grade_info_OperationEducation_Fun = async (key: string) => {
  try {
    let res = await grade_infoApi(key)
    let dataArray = (res.data as grade_info_Education_interface).data
    // console.log(dataArray);

    GlobalDispatch({
      type: 'replace_Recent_OperationEducation',
      payload: dataArray,
    })
  } catch {
    message.error('网络不通畅')
  }
}
const grade_info_OperationPersonal_Fun = async (key: string) => {
  // debugger
  try {
    let res = await grade_infoApi(key)
      // 国内版编辑数据回显
      let dataArray = (res.data as grade_info_Personal_interface).data
      if (dataArray.soft.Muscledrive.soft_on === 0) {
        dataArray.soft.Muscledrive.count = 0
        dataArray.soft.Muscledrive.gift = 0
      }
      if (dataArray.soft.Streamer.soft_on === 0) {
        dataArray.soft.Streamer.timecode = 0
        dataArray.soft.Streamer.capture = 0
        dataArray.soft.Streamer.multichannel_recording = 0
      }
      if (dataArray.soft.Rigger.soft_on === 0) {
        dataArray.soft.Rigger.count = 0
        dataArray.soft.Rigger.gift = 0
        dataArray.soft.Rigger.arkit_template = 0
        dataArray.soft.Rigger.emily_template = 0
      }
      if (dataArray.soft.Retargeter.soft_on === 0) {
        dataArray.soft.Retargeter.frame = 0
        dataArray.soft.Retargeter.gift = 0
        dataArray.soft.Retargeter.keyframe_solve = 0
        dataArray.soft.Retargeter.expression_library_solve = 0
      }
      if (dataArray.soft.Livedrive.soft_on === 0) {
        dataArray.soft.Livedrive.capture = 0
        dataArray.soft.Livedrive.timecode = 0
        dataArray.soft.Livedrive.seattle_ai_arkit = 0
        dataArray.soft.Livedrive.open3d = 0
        dataArray.soft.Livedrive.usage_time = 0
        dataArray.soft.Livedrive.algorithm = ''
        dataArray.soft.Livedrive.gift = 0
      }
      if (dataArray.soft.Autoface.soft_on === 0) {
        dataArray.soft.Autoface.count = 0
        dataArray.soft.Autoface.gift = 0
        dataArray.soft.Autoface.default_template = 0
        dataArray.soft.Autoface.custom_template = 0
      }
      if (dataArray.soft.Tracker.soft_on === 0) {
        dataArray.soft.Tracker.frame = 0
        dataArray.soft.Tracker.gift = 0
        dataArray.soft.Tracker.ai01 = 0
        dataArray.soft.Tracker.ai02 = 0
        dataArray.soft.Tracker.ai03 = 0
        dataArray.soft.Tracker.ai04 = 0
        dataArray.soft.Tracker.ai_library = 0
        dataArray.soft.Tracker.ai_template = 0
      }
      if (dataArray.soft.Batchrobot.soft_on === 0) {
        dataArray.soft.Batchrobot.count = 0
        dataArray.soft.Batchrobot.gift = 0
        dataArray.soft.Batchrobot.tracker_batch_processing = 0
        dataArray.soft.Batchrobot.retargeter_batch_processing = 0
      }
    
      if (dataArray.soft.Riggermeta.soft_on === 0) {
        dataArray.soft.Riggermeta.count = 0
        dataArray.soft.Riggermeta.gift = 0
       
      }
      if (dataArray.soft.Seattleaitrainer.soft_on === 0) {
        dataArray.soft.Seattleaitrainer.file_export = 0
      }
    
      GlobalDispatch({
        type: 'replace_Recent_OperationPersonal',
        payload: dataArray,
      })
    // }
  } catch (error) {
    message.error('网络不通畅')
    console.log('error',error);
    
  }
}

const grade_info_OperationEnterprise_Fun = async (key: string) => {
  try {
    let res = await grade_infoApi(key)
    let dataArray = (res.data as grade_info_Enterprise_interface).data
    GlobalDispatch({
      type: 'replace_Recent_OperationEnterprise',
      payload: dataArray,
    })
  } catch {
    message.error('网络不通畅')
  }
}
const soft_Fun = async () => {
  try {
    let soft = await softApi()
    // console.log(soft.data.data, '1111111111')

    GlobalDispatch({ type: 'replace_soft_info', payload: soft.data.data })
  } catch {
    message.error('网络不通畅')
  }
}
const grade_Fun = async () => {
  try {
    let education = await gradeApi(OperationType.education)
    let enterprise = await gradeApi(OperationType.enterprise)
    GlobalDispatch({
      type: 'replace_Education_Dropdown',
      payload: education.data.data,
    })
    GlobalDispatch({
      type: 'replace_Enterprise_Dropdown',
      payload: enterprise.data.data,
    })
  } catch {
    message.error('网络不通畅')
  }
}
export {
  enterprise_list_Fun,
  education_list_Fun,
  personal_list_Fun,
  soft_Fun,
  grade_Fun,
  market_Education_index_Fun,
  market_Enterprise_index_Fun,
  grade_info_OperationEducation_Fun,
  grade_info_OperationPersonal_Fun,
  grade_info_OperationEnterprise_Fun,
}
