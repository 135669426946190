const PageSize = 10


const StatusFiltering: { grade_id: number; grade_name: string }[] = [
    {grade_id: 1, grade_name: "使用中"},
    {grade_id: 2, grade_name: "已过期"},
]
const TopBar: { grade_id: number; grade_name: string }[] = [
    {grade_id: 10009, grade_name: "退出登录"},
]
export {StatusFiltering, TopBar, PageSize}
