import {HashRouter, Route} from 'react-router-dom'
import React from "react"
import Login from "../view/Login"
import Home from "../view/Home"

const router = () => {
    return (
        <HashRouter>
            <Route path="/" exact component={Login}/>
            <Route path="/home" component={Home}/>
        </HashRouter>
    )
}
export default router
