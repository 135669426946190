import {TranslateType} from "@/interface";

type UserInfoMap = {
    "replace_delay_array": { Del_Key: string, effect_date: string, usage_date: string }
    "replace_delay": { Del_Key: string, effect_date: string, usage_date: string }
    "change_delay": { Del_Key: string } | { effect_date: string } | { usage_date: string }
    "clear_delay_array": {}
}

let init: { Del_Key: string, effect_date: string, usage_date: string } = {
    Del_Key: "",
    effect_date: '',
    usage_date: ''
}

const reducer = (dataInit = init, data: TranslateType<UserInfoMap>): { Del_Key: string, effect_date: string, usage_date: string } => {
    switch (data.type) {
        case "replace_delay_array":
            return data.payload
        case "replace_delay":
            return data.payload
        case "change_delay":
            return {...dataInit, ...data.payload}
        case "clear_delay_array":
            return {
                Del_Key: "",
                effect_date: '',
                usage_date: ''
            }

        default:
            return {
                Del_Key: "",
                effect_date: '',
                usage_date: ''
            }

    }
}
export default reducer
