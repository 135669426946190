import {TranslateType} from "@/interface";
import {Account_soft} from "@/interface/recentType";

type AccountSoftMap = {
    "replace_Account_soft": Account_soft[]
    "clear_Account_soft": []
}
let init: Account_soft[] = []

const reducer = (dataInit = init, data: TranslateType<AccountSoftMap>): Account_soft[] => {
    switch (data.type) {
        case "replace_Account_soft":
            return data.payload
        case "clear_Account_soft":
            return data.payload
        default:
            return [...dataInit]
    }
}
export default reducer
