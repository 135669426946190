import {TranslateType} from "@/interface";

type UserInfoMap = {
    "SAVE_TOKEN_AND_TYPE_WITH_NAME": userData,
    "Clear_INFO": userData
}

type userData = { token: string, type: number, name: string }

let init: userData = {
    name: "",
    token: "",
    type: 0
}

const reducer = (dataInit = init, data: TranslateType<UserInfoMap>): userData => {
    switch (data.type) {
        case "SAVE_TOKEN_AND_TYPE_WITH_NAME":
            return data.payload
        case "Clear_INFO":
            return data.payload
        default:
            return dataInit
    }
}
export default reducer
