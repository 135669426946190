import {TranslateType} from "@/interface";
import {Soft} from "@/interface/recentType";

type UserInfoMap = {
    "replace_soft_info": Soft[]
}

let init: Soft[] = []

const reducer = (dataInit = init, data: TranslateType<UserInfoMap>): Soft[] => {
    switch (data.type) {
        case "replace_soft_info":
            return data.payload
        default:
            return [...dataInit]
    }
}
export default reducer
