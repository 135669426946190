import packageConfig from '../../package.json'

const isNullOrUndefined = (obj: string | {} | [] | undefined) => {
    return obj === null || obj === "" || JSON.stringify(obj) === '{}' || JSON.stringify(obj) === "[]" || obj === undefined;
}
const getTimesTamps = () => {
    return Date.parse(new Date().toString()).toString().substring(0, 10)
}
// 'appid' => 'EC60cVQxpf2sg7YctCUhk7Bo', //英文版
const APP_ID: string = packageConfig.isEnglish ? "EC60cVQxpf2sg7YctCUhk7Bo" : "hEfCZ9EAnFDybmD6iR5NZVTc"

// 'appsecret' => 'tExeKWcBznnxeetU9eWE3cIcSsc1n9a0', //英文版
const app_secret: string = packageConfig.isEnglish ? 'tExeKWcBznnxeetU9eWE3cIcSsc1n9a0' : "smGupxGmnMonRyUnK6FFBZ7p4UAQKMVB"

const objKeySort = (obj: any) => {
    let newKey = Object.keys(obj).sort();
    let newObj = [];
    let newObjList = '';
    for (let i = 0; i < newKey.length; i++) {
        const element = newKey[i] + "=" + obj[newKey[i]];
        newObj.push(element)
    }
    newObj.forEach(item => {
        newObjList += item + '&';
    });
    let sing = newObjList.slice(0, -1);
    return `${APP_ID}${sing}${app_secret}`
}

export {isNullOrUndefined, getTimesTamps, APP_ID, objKeySort}
