import {Menu} from "antd";
import React from "react";
import {isNullOrUndefined} from "@/utils/util";

const CreatAntdMenu = (MenuArray: { grade_id: number, grade_name: string }[], click: Function) => {
    if (isNullOrUndefined(MenuArray)) {
        return <Menu/>
    }
    const clickHandle = (menuOption: { grade_id: number, grade_name: string }) => {
        click(menuOption)
    }
    return (<Menu>
        {MenuArray.map(item => {
            return <Menu.Item key={item.grade_id}>
                <div onClick={() => {
                    clickHandle(item)
                }}>{item.grade_name}</div>
            </Menu.Item>
        })}
    </Menu>)

}
export default CreatAntdMenu
