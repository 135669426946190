import axios, {AxiosPromise} from "axios"
import {APP_ID, getTimesTamps, isNullOrUndefined, objKeySort} from "@/utils/util";
import {Token, Url} from "./HttpSetting"
import md5 from "js-md5"
import {PageSize} from "@/UISetting/DropdownSetting";
import {account_soft_interface, grade_interface, Market_EnterPrice_interface} from "@/interface/phpInterface";
import {GlobalState} from "@/store";

//账户列表（校企账户包含搜索）
export const market_indexApi = (type: number, page: number, grade?: number, state?: string, title?: string): AxiosPromise<any> => {
    let getParams = {
        grade: isNullOrUndefined(grade) ? '' : grade === 0 ? "" : grade,
        title: isNullOrUndefined(title) ? '' : title,
        token: Token || GlobalState.userInfo.token,
        type,
        page,
        per_page: PageSize,
        state: isNullOrUndefined(state) ? '' : state,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/index`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户信息（校企账户）
export const account_infoApi = (id: string): AxiosPromise<Market_EnterPrice_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        id,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_info`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户信息（校企账户）
export const gradeApi = (type: number): AxiosPromise<grade_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        type,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/grade`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//根据账户类型获取各软件开闭配置（增加或编辑中使用）
export const account_softApi = (id: string, grade: number): AxiosPromise<account_soft_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        id,
        grade,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_soft`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户延期
export const account_delayApi = (id: string, effect_date: string, usage_date: string): AxiosPromise<account_soft_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        id,
        effect_date,
        usage_date,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_delay`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户编辑（企）
export const enterprise_account_editApi = (id: number, title: string, name: string, pass: string, grade: number, num: string, effect_date: string, usage_date: string, config: string): AxiosPromise<account_soft_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        id,
        title,
        name,
        pass,
        grade,
        num,
        effect_date,
        usage_date,
        config,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_edit`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户编辑（校）
export const education_account_editApi = (id: number, title: string, name: string, pass: string, grade: number, num: string, usage_date: string, config: string): AxiosPromise<account_soft_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        id,
        title,
        name,
        pass,
        grade,
        num,
        usage_date,
        config,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_edit`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户添加（校）
export const education_account_addApi = (type: number, title: string, name: string, pass: string, grade: number, num: string, usage_date: string, config: string): AxiosPromise<grade_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        type,
        title,
        pass,
        grade,
        name,
        num,
        usage_date,
        config,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_add`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
//账户添加（企）
export const enterprise_account_addApi = (type: number, title: string, name: string, pass: string, grade: number, num: string, effect_date: string, usage_date: string, config: string): AxiosPromise<grade_interface> => {
    let getParams = {
        token: Token || GlobalState.userInfo.token,
        pass,
        grade,
        num,
        name,
        title,
        effect_date,
        usage_date,
        config,
        timestamp: getTimesTamps()
    }
    return axios({
        url: `${Url}/api.php/market/index/account_add`,
        method: 'post',
        data: {
            appid: APP_ID,
            sign: md5(objKeySort(getParams)),
            ...getParams
        },
    })
}
