import React, { useEffect } from 'react'
import style from './index.module.scss'
import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const trackerPNG = require('@/assets/img/software/tracker.png').default

interface IProps {}

let initData = 0
const Tracker = (props: IProps) => {
  const Tracker = useRootStore(
    (state) => state.OperationPersonal.recent.soft.Tracker
  )
  const changeImport_frame = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { frame: Number(e.target.value) },
    })
  }
  const Tracker_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { frame: e.target.checked ? -1 : initData },
    })
  }
  const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { gift: Number(e.target.value) },
    })
  }
  const onChangeAi01 = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai01: value ? 1 : 0 },
    })
  }
  const onChangeAi02 = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai02: value ? 1 : 0 },
    })
  }
  const onChangeAi03 = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai03: value ? 1 : 0 },
    })
  }
  const onChangeAi04 = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai04: value ? 1 : 0 },
    })
  }
  const onChangeAiLibrary = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai_library: value ? 1 : 0 },
    })
  }
  const onChangeAiTemplate = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Tracker',
      payload: { ai_template: value ? 1 : 0 },
    })
  }
  useEffect(() => {
    if (Tracker.frame === -1) return
    initData = Tracker.frame
  }, [])
  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={trackerPNG} alt="" />
        <div className={style.title}>Tracker权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineName}>每月可用总帧数:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Tracker.frame === -1 ? '无限' : Tracker.frame}
              onChange={changeImport_frame}
              spellCheck={false}
              suffix="帧"
            />
            <Checkbox
              checked={Tracker.frame === -1}
              onChange={Tracker_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>赠送:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Tracker.gift}
              onChange={changeGift}
              disabled={Tracker.frame === -1}
              spellCheck={false}
              suffix="帧"
            />
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>AI01：</div>
          <div className={style.chosePart}>
            <Switch checked={Boolean(Tracker.ai01)} onChange={onChangeAi01} />
          </div>
          <div className={style.lineName}>AI02：</div>
          <div className={style.chosePart}>
            <Switch checked={Boolean(Tracker.ai02)} onChange={onChangeAi02} />
          </div>
          <div className={style.lineName}>AI03：</div>
          <div className={style.chosePart}>
            <Switch checked={Boolean(Tracker.ai03)} onChange={onChangeAi03} />
          </div>
          <div className={style.lineName}>AI04：</div>
          <div className={style.chosePart}>
            <Switch checked={Boolean(Tracker.ai04)} onChange={onChangeAi04} />
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>AI库：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Tracker.ai_library)}
              onChange={onChangeAiLibrary}
            />
          </div>
          <div className={style.lineName}>AI模板:</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Tracker.ai_template)}
              onChange={onChangeAiTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tracker
