import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import {Checkbox, Col, Input, message, Row, Switch} from "antd";
import {GlobalDispatch, useRootStore} from "@/store";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {isNullOrUndefined} from "@/utils/util";

const liveDrivePNG = require("@/assets/img/software/livedrive.png").default

interface IProps {

}

let initData = 0
const LiveDrive = (props: IProps) => {
    const Livedrive = useRootStore(state => state.OperationPersonal.recent.soft.Livedrive)
    const [checkValue, setCheckValue] = useState<CheckboxValueType[]>([])
    const getCheck = () => {
        if (isNullOrUndefined(Livedrive.algorithm)) {
            setCheckValue([])
            return
        }
        let checkList = Livedrive.algorithm.toString().split(",")
        setCheckValue(checkList)
    }
    const onChange = (checkedValues: CheckboxValueType[]) => {
        setCheckValue(checkedValues)
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {algorithm: checkedValues.toString()}})
    }
    const onChange3d = (value: boolean) => {
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {open3d: value ? 1 : 0}})
    }
    const onChangeCapture = (value: boolean) => {
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {capture: value ? 1 : 0}})
    }
    const onChangeSeattleAiArkit = (value: boolean) => {
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {seattle_ai_arkit: value ? 1 : 0}})
    }
    const onChangeTimecode = (value: boolean) => {
      GlobalDispatch({type: "change_Recent_Livedrive", payload: {timecode: value ? 1 : 0}})
  }
    const Livedrive_frame_Checkbox = (e: CheckboxChangeEvent) => {
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {usage_time: e.target.checked ? -1 : initData}})
    }
    const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {gift: Number(e.target.value)}})
    }
    const changeUsage_time = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Livedrive", payload: {usage_time: Number(e.target.value)}})
    }

    useEffect(() => {
        getCheck()
        if (Livedrive.usage_time === -1) return
        initData = Livedrive.usage_time
    }, [Livedrive.algorithm])
    return (
        <div className={style.softwareContainer}>
            <div className={style.imgAndTitle}>
                <img src={liveDrivePNG} alt=""/>
                <div className={style.title}>Livedrive权限</div>
            </div>
            <div className={style.content}>
                <div className={style.Line}>
                    <div className={style.lineName}>每月可用总时长:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Livedrive.usage_time === -1 ? "无限" : Livedrive.usage_time}
                               onChange={changeUsage_time}
                               spellCheck={false} suffix="分钟"/>
                        <Checkbox checked={Livedrive.usage_time === -1}
                                  onChange={Livedrive_frame_Checkbox}>无限制</Checkbox>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>赠送:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Livedrive.gift}
                               onChange={changeGift}
                               disabled={Livedrive.usage_time === -1}
                               spellCheck={false} suffix="分钟"/>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>支持算法:</div>
                    <div className={style.chosePart}>
                        <Checkbox.Group style={{width: '100%'}} value={checkValue} onChange={onChange}>
                            <Row>
                                <Col span={5}>
                                    <Checkbox value="1">双线性</Checkbox>
                                </Col>
                                <Col span={5}>
                                    <Checkbox value="2">西雅图</Checkbox>
                                </Col>
                                <Col span={5}>
                                    <Checkbox value="3">西雅图定制</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>开启3D:</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Livedrive.open3d)} onChange={onChange3d}/>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>动捕触发:</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Livedrive.capture)} onChange={onChangeCapture}/>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>时间码录入:</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Livedrive.timecode)} onChange={onChangeTimecode}/>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>SeattleAI(ARKit):</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Livedrive.seattle_ai_arkit)} onChange={onChangeSeattleAiArkit}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveDrive
