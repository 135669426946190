import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux"
import './index.scss';
import {persistor, store} from "./store"
import Router from "./router/firstRouter"
import 'antd/dist/antd.css';
import {PersistGate} from "redux-persist/integration/react";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router/>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
