import { TranslateType } from '@/interface'
import {
  A_OperationEnterpriseReduxType,
  OperationEnterpriseRecent,
  OperationEnterpriseReduxType,
} from '@/interface/recentType'

type recentDataMap = {
  replace_allData_OperationEnterprise: OperationEnterpriseReduxType[]
  replace_Recent_OperationEnterprise: OperationEnterpriseRecent
  change_Recent_OperationEnterprise_option:
    | { grade_id: number }
    | { grade_image: string }
    | { grade_tag: string }
    | { grade_param: string }
    | { grade_name: string }
    | { grade_type: number }
  change_Recent_grade_setting_OperationEnterprise_option:
    | { market_edit: number }
    | { is_show: number }
    | { user_min: number }
    | { user_max: number }
    | { price_setting: any }
  change_Recent_OperationEnterprise_soft_option: {
    [key: string]: { soft_on: number; limit_setting: {} }
  }
}
let init: A_OperationEnterpriseReduxType = {
  allData: [],
  recent: {
    grade_id: 0,
    grade_image: '',
    grade_name: '',
    grade_type: 0,
    market_edit: 0,
    user_max: 0,
    user_min: 0,
    is_show: 0,
    grade_tag: '',
    grade_param: '',
    price_setting: [],
    soft: {
      Autoface: {
        soft_on: 0,
        default_template: 0,
        custom_template: 0,
        limit_setting: {},
      },
      Livedrive: { soft_on: 0, limit_setting: {} },
      Muscledrive: { soft_on: 0, limit_setting: {} },
      Retargeter: {
        soft_on: 0,
        keyframe_solve: 0,
        expression_library_solve: 0,
        limit_setting: {},
      },
      Rigger: {
        soft_on: 0,
        arkit_template: 0,
        emily_template: 0,
        limit_setting: {},
      },
      Riggermeta: { soft_on: 0, limit_setting: {} },
      Streamer: { soft_on: 0, limit_setting: {} },
      Tracker: {
        soft_on: 0,
        ai01: 0,
        ai02: 0,
        ai03: 0,
        ai04: 0,
        ai_library: 0,
        ai_template: 0,
        limit_setting: {},
      },
      Batchrobot: {
        soft_on: 0,
        tracker_batch_processing: 0,
        retargeter_batch_processing: 0,
        limit_setting: {},
      },
      Seattlepro: { soft_on: 0, limit_setting: {} },
      Seattleaitrainer: { soft_on: 0, limit_setting: {} },
      Fgmodeledit: { soft_on: 0, limit_setting: {} },
      Seattlelitemetahuman: { soft_on: 0, limit_setting: {} },
      Posemakerposeasset: { soft_on: 0, limit_setting: {} },
      Posemakerfbx: { soft_on: 0, limit_setting: {} },
      Retargeterofflinearkit: { soft_on: 0, limit_setting: {} },
      Retargeterofflinemeta: { soft_on: 0, limit_setting: {} },
      Retargeterofflineposeasset: { soft_on: 0, limit_setting: {} },
      Retargeterseattleaiofflinecustom: { soft_on: 0, limit_setting: {} },
      Livedriverealtimemeta: { soft_on: 0, limit_setting: {} },
      Livedriverealtimeposeasset: { soft_on: 0, limit_setting: {} },
      Livedriverealtimecustom: { soft_on: 0, limit_setting: {} },
      Batchrobotarkit: { soft_on: 0, limit_setting: {} },
      Batchrobotmeta: { soft_on: 0, limit_setting: {} },
      Batchrobotposeasset: { soft_on: 0, limit_setting: {} },
      Batchrobotcustom: { soft_on: 0, limit_setting: {} },
    },
  },
}

const reducer = (
  dataInit = init,
  data: TranslateType<recentDataMap>
): A_OperationEnterpriseReduxType => {
  switch (data.type) {
    case 'replace_allData_OperationEnterprise':
      return {
        ...dataInit,
        allData: data.payload,
      }
    case 'replace_Recent_OperationEnterprise':
      return { ...dataInit, recent: data.payload }
    case 'change_Recent_OperationEnterprise_option':
      return { ...dataInit, recent: { ...dataInit.recent, ...data.payload } }
    case 'change_Recent_grade_setting_OperationEnterprise_option':
      return {
        ...dataInit,
        recent: { ...dataInit.recent, ...data.payload },
      }
    case 'change_Recent_OperationEnterprise_soft_option':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            ...data.payload,
          },
        },
      }
    default:
      return { ...dataInit }
  }
}
export default reducer
