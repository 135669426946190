import {
  education_editApi,
  education_gradeApi,
  enterprise_editApi,
  enterprise_gradeApi,
  expireApi,
  grade_addApi,
  grade_delApi,
  grade_editApi,
  login_setApi,
  market_grade_delApi,
} from '@/api/OperationApi'
import { GlobalDispatch, GlobalState, store } from '@/store'
import { message } from 'antd'
import {
  MarketType,
  OperationLoginSetType,
  OperationType,
} from '@/interface/EnumList'
import {
  education_list_Fun,
  enterprise_list_Fun,
  market_Education_index_Fun,
  market_Enterprise_index_Fun,
  personal_list_Fun,
} from '@/api/OperationApiFun'
import { spaceOptionType } from '@/interface/tableType'
import {
  account_delayApi,
  education_account_addApi,
  education_account_editApi,
  enterprise_account_addApi,
  enterprise_account_editApi,
} from '@/api/MarketApi'
// import { log } from 'console'
import { useRootStore } from '@/store'
import packageConfig from '../../../package.json'

export const ReminderSettingsModal_callback = async () => {
  let notify_day = GlobalState.LoginSetting.recent.notify_day
  let notify_content = GlobalState.LoginSetting.recent.notify_content
  let expireRes = await expireApi(notify_day, notify_content)
  if (expireRes.data.code !== 200) {
    message.error(expireRes.data.msg)
    return
  }
  message.success(expireRes.data.msg)
  GlobalDispatch({ type: 'merge_recent_to_allData', payload: {} })
}
export const OperationEducationModal_Add_callback = async () => {
  let OperationEducationCache = GlobalState.OperationEducation_Add.recent
  let data = {
    name: OperationEducationCache.grade_name,
    image: OperationEducationCache.grade_image,
    lifelong_price: OperationEducationCache.grade_setting.lifelong_price,
    user_min: OperationEducationCache.grade_setting.user_min,
    user_max: OperationEducationCache.grade_setting.user_max,
    market_edit: OperationEducationCache.grade_setting.market_edit,
    config: JSON.stringify({
      streamer: { soft_on: OperationEducationCache.soft.Streamer.soft_on },
      tracker: { soft_on: OperationEducationCache.soft.Tracker.soft_on },
      autoface: { soft_on: OperationEducationCache.soft.Autoface.soft_on },
      rigger: { soft_on: OperationEducationCache.soft.Rigger.soft_on },
      retargeter: {
        soft_on: OperationEducationCache.soft.Retargeter.soft_on,
      },
      livedrive: { soft_on: OperationEducationCache.soft.Livedrive.soft_on },
      muscledrive: {
        soft_on: OperationEducationCache.soft.Muscledrive.soft_on,
      },
      batchrobot: {
        soft_on: OperationEducationCache.soft.Batchrobot.soft_on,
      },
      // Seattlepro: {
      //   soft_on: OperationEducationCache.soft.Seattlepro.soft_on,
      // },
      riggermeta: {
        soft_on: OperationEducationCache.soft.Riggermeta.soft_on,
      },
      fgmodeledit: {
        soft_on: OperationEducationCache.soft.Fgmodeledit.soft_on,
      },
      posemakerfbx: {
        soft_on: OperationEducationCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationEducationCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationEducationCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationEducationCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on:
          OperationEducationCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationEducationCache.soft.Retargeterseattleaiofflinecustom.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationEducationCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on:
          OperationEducationCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationEducationCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationEducationCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationEducationCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationEducationCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationEducationCache.soft.Batchrobotcustom.soft_on,
      },
      seattleaitrainer: {
        soft_on: OperationEducationCache.soft.Seattleaitrainer.soft_on,
      },
    }),
    type: OperationType.education,
  }
  let education_gradeRes = await education_gradeApi(
    data.name,
    data.image,
    data.user_min,
    data.user_max,
    data.market_edit,
    data.config,
    data.lifelong_price
  )
  if (education_gradeRes.data.code !== 200) {
    message.error(education_gradeRes.data.msg)
    return
  }
  message.success(education_gradeRes.data.msg)
  const currentPage: any = sessionStorage.getItem('EDUCATION-CURRENTPAGE')
  const page = parseInt(currentPage)
  education_list_Fun(page, OperationLoginSetType.education).then((_) => {})
  GlobalDispatch({
    type: 'clear_Recent_OperationEducation_data_Add',
    payload: {},
  })
}
export const OperationEnterpriseModal_Add_callback = async () => {
  let OperationEnterpriseCache = GlobalState.OperationEnterprise_Add.recent
  console.log('OperationEnterpriseCache====', OperationEnterpriseCache)


  let data = {
    name: OperationEnterpriseCache.grade_name,
    image: OperationEnterpriseCache.grade_image,
    market_edit: OperationEnterpriseCache.market_edit,
    user_min: OperationEnterpriseCache.user_min,
    user_max: OperationEnterpriseCache.user_max,
    is_show: OperationEnterpriseCache.is_show,
    tag: OperationEnterpriseCache.grade_tag,
    param: OperationEnterpriseCache.grade_param,
    price_setting:
      JSON.stringify(OperationEnterpriseCache.price_setting.setting) ===
      undefined
        ? '{market_price:0,discount_price:0}'
        : JSON.stringify(OperationEnterpriseCache.price_setting.setting),
    config: JSON.stringify({
      streamer: { soft_on: OperationEnterpriseCache.soft.Streamer.soft_on },
      tracker: { soft_on: OperationEnterpriseCache.soft.Tracker.soft_on },
      autoface: { soft_on: OperationEnterpriseCache.soft.Autoface.soft_on },
      rigger: { soft_on: OperationEnterpriseCache.soft.Rigger.soft_on },
      retargeter: { soft_on: OperationEnterpriseCache.soft.Retargeter.soft_on },
      livedrive: { soft_on: OperationEnterpriseCache.soft.Livedrive.soft_on },
      muscledrive: {
        soft_on: OperationEnterpriseCache.soft.Muscledrive.soft_on,
      },
      batchrobot: { soft_on: OperationEnterpriseCache.soft.Batchrobot.soft_on },
      // Seattlepro: { soft_on: OperationEnterpriseCache.soft.Seattlepro.soft_on },
      riggermeta: { soft_on: OperationEnterpriseCache.soft.Riggermeta.soft_on },
      fgmodeledit: {
        soft_on: OperationEnterpriseCache.soft.Fgmodeledit.soft_on,
      },
      seattleaitrainer: {
        soft_on: OperationEnterpriseCache.soft.Seattleaitrainer.soft_on,
      },
      posemakerfbx: {
        soft_on: OperationEnterpriseCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationEnterpriseCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationEnterpriseCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationEnterpriseCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on:
          OperationEnterpriseCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationEnterpriseCache.soft.Retargeterseattleaiofflinecustom
            .soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationEnterpriseCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on:
          OperationEnterpriseCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationEnterpriseCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotcustom.soft_on,
      },
      // seattlelitemetahuman: {
      //   soft_on: OperationEnterpriseCache.soft.Seattlelitemetahuman.soft_on,
      // },
    }),
    type: OperationType.enterprise,
  }

  let enterprise_gradeRes = await enterprise_gradeApi(
    data
  )
  if (enterprise_gradeRes.data.code !== 200) {
    message.error(enterprise_gradeRes.data.msg)
    return
  }
  message.success(enterprise_gradeRes.data.msg)
  const currentPage: any = sessionStorage.getItem('ENTERPRISE-CURRENTPAGE')
  const page = parseInt(currentPage)
  enterprise_list_Fun(page, OperationLoginSetType.enterprise).then((_) => {})
  GlobalDispatch({
    type: 'clear_Recent_OperationEnterprise_data_Add',
    payload: {},
  })
}
export const OperationEducationModal_callback = async () => {
  let OperationEducationCache = GlobalState.OperationEducation.recent
 
  let data = {
    id: OperationEducationCache.grade_id,
    name: OperationEducationCache.grade_name,
    image: OperationEducationCache.grade_image,
    lifelong_price: OperationEducationCache.grade_setting.lifelong_price,
    user_min: OperationEducationCache.grade_setting.user_min,
    user_max: OperationEducationCache.grade_setting.user_max,
    market_edit: OperationEducationCache.grade_setting.market_edit,
    config: JSON.stringify({
      streamer: { soft_on: OperationEducationCache.soft.Streamer.soft_on },
      tracker: { soft_on: OperationEducationCache.soft.Tracker.soft_on },
      autoface: { soft_on: OperationEducationCache.soft.Autoface.soft_on },
      rigger: { soft_on: OperationEducationCache.soft.Rigger.soft_on },
      retargeter: {
        soft_on: OperationEducationCache.soft.Retargeter.soft_on,
      },
      livedrive: { soft_on: OperationEducationCache.soft.Livedrive.soft_on },
      muscledrive: {
        soft_on: OperationEducationCache.soft.Muscledrive.soft_on,
      },
      batchrobot: {
        soft_on: OperationEducationCache.soft.Batchrobot.soft_on,
      },
     
      riggermeta: {
        soft_on: OperationEducationCache.soft.Riggermeta.soft_on,
      },
      fgmodeledit: {
        soft_on: OperationEducationCache.soft.Fgmodeledit.soft_on,
      },
      seattleaitrainer: {
        soft_on: OperationEducationCache.soft.Seattleaitrainer.soft_on,
      },
      posemakerfbx: {
        soft_on: OperationEducationCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationEducationCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationEducationCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationEducationCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on:
          OperationEducationCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationEducationCache.soft.Retargeterseattleaiofflinecustom.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationEducationCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on:
          OperationEducationCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationEducationCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationEducationCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationEducationCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationEducationCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationEducationCache.soft.Batchrobotcustom.soft_on,
      },
    }),
  }
  let education_editRes = await education_editApi(
    data.id,
    data.name,
    data.image,
    data.user_min,
    data.user_max,
    data.market_edit,
    data.config,
    data.lifelong_price
  )
  if (education_editRes.data.code !== 200) {
    message.error(education_editRes.data.msg)
    return
  }
  message.success(education_editRes.data.msg)
  const currentPage: any = sessionStorage.getItem('EDUCATION-CURRENTPAGE')
  const page = parseInt(currentPage)
  education_list_Fun(page, OperationLoginSetType.education).then((_) => {})
  // }
}

export const OperationEnterpriseModal_callback = async () => {
  let OperationEnterpriseCache = GlobalState.OperationEnterprise.recent
 
  let data = {
    id: OperationEnterpriseCache.grade_id,
    name: OperationEnterpriseCache.grade_name,
    image: OperationEnterpriseCache.grade_image,
    market_edit: OperationEnterpriseCache.market_edit,
    user_min: OperationEnterpriseCache.user_min,
    user_max: OperationEnterpriseCache.user_max,
    is_show: OperationEnterpriseCache.is_show,
    tag: OperationEnterpriseCache.grade_tag,
    param: OperationEnterpriseCache.grade_param,
    price_setting: JSON.stringify(OperationEnterpriseCache.price_setting),
    config: JSON.stringify({
      streamer: { soft_on: OperationEnterpriseCache.soft.Streamer.soft_on },
      tracker: { soft_on: OperationEnterpriseCache.soft.Tracker.soft_on },
      autoface: { soft_on: OperationEnterpriseCache.soft.Autoface.soft_on },
      rigger: { soft_on: OperationEnterpriseCache.soft.Rigger.soft_on },
      retargeter: {
        soft_on: OperationEnterpriseCache.soft.Retargeter.soft_on,
      },
      livedrive: { soft_on: OperationEnterpriseCache.soft.Livedrive.soft_on },
      muscledrive: {
        soft_on: OperationEnterpriseCache.soft.Muscledrive.soft_on,
      },
      batchrobot: {
        soft_on: OperationEnterpriseCache.soft.Batchrobot.soft_on,
      },
    
      riggermeta: {
        soft_on: OperationEnterpriseCache.soft.Riggermeta.soft_on,
      },
      fgmodeledit: {
        soft_on: OperationEnterpriseCache.soft.Fgmodeledit.soft_on,
      },
      seattleaitrainer: {
        soft_on: OperationEnterpriseCache.soft.Seattleaitrainer.soft_on,
      },
      posemakerfbx: {
        soft_on: OperationEnterpriseCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationEnterpriseCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationEnterpriseCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationEnterpriseCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on:
          OperationEnterpriseCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationEnterpriseCache.soft.Retargeterseattleaiofflinecustom
            .soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationEnterpriseCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on:
          OperationEnterpriseCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationEnterpriseCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationEnterpriseCache.soft.Batchrobotcustom.soft_on,
      },
     
    }),
  }
  let enterprise_editRes = await enterprise_editApi(data)

  if (enterprise_editRes.data.code !== 200) {
    message.error(enterprise_editRes.data.msg)
    return
  }
  message.success(enterprise_editRes.data.msg)

  const currentPage: any = sessionStorage.getItem('ENTERPRISE-CURRENTPAGE')
  const page = parseInt(currentPage)
  enterprise_list_Fun(page, OperationLoginSetType.enterprise).then((_) => {})
  // }
}

export const LimitSettingsModal_callback = async (
  OptionType: spaceOptionType
) => {
  let Account = GlobalState.LoginSetting.recent.setting_account
  let device = GlobalState.LoginSetting.recent.setting_device
  if (OptionType.symbol === 'PersonalLogin') {
    let res = await login_setApi(
      Account,
      device,
      OperationLoginSetType.personal
    )
    if (res.data.code === 200) {
      message.success(res.data.msg)
      GlobalDispatch({ type: 'merge_recent_to_allData', payload: {} })
      return
    }
    message.error(res.data.msg)
  }
  if (OptionType.symbol === 'EnterpriseLogin') {
    let res = await login_setApi(
      Account,
      device,
      OperationLoginSetType.enterprise
    )
    if (res.data.code === 200) {
      message.success(res.data.msg)
      GlobalDispatch({ type: 'merge_recent_to_allData', payload: {} })
      return
    }
    message.error(res.data.msg)
  }
  if (OptionType.symbol === 'EducationLogin') {
    let res = await login_setApi(
      Account,
      device,
      OperationLoginSetType.education
    )
    if (res.data.code === 200) {
      message.success(res.data.msg)
      GlobalDispatch({ type: 'merge_recent_to_allData', payload: {} })
      return
    }
    message.error(res.data.msg)
  }
}

export const OperationPersonalModal_Add_callback = async () => {
  let OperationPersonalCache = GlobalState.OperationPersonal_Add.recent
  const StreamerData =
    OperationPersonalCache.soft.Streamer.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Streamer }
  const TrackerData =
    OperationPersonalCache.soft.Tracker.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Tracker }
  const AutofaceData =
    OperationPersonalCache.soft.Autoface.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Autoface }
  const RiggerData =
    OperationPersonalCache.soft.Rigger.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Rigger }
  const LivedriveData =
    OperationPersonalCache.soft.Livedrive.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Livedrive }
  const RetargeterData =
    OperationPersonalCache.soft.Retargeter.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Retargeter }
  const MuscledriveData =
    OperationPersonalCache.soft.Muscledrive.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Muscledrive }
  const BatchrobotData =
    OperationPersonalCache.soft.Batchrobot.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Batchrobot }

  const riggermetaData =
    OperationPersonalCache.soft.Riggermeta.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Riggermeta }
  const seattleaitrainerData =
    OperationPersonalCache.soft.Seattleaitrainer.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Seattleaitrainer }
  const fgmodeleditData =
    OperationPersonalCache.soft.Fgmodeledit.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Fgmodeledit }

  
  console.log(OperationPersonalCache.price_setting.setting, 'price_setting')

  let data = {
    name: OperationPersonalCache.grade_name,
    image: OperationPersonalCache.grade_image,
    price_setting:
      JSON.stringify(OperationPersonalCache.price_setting.setting) == undefined
        ? '{market_price:0,discount_price:0}'
        : JSON.stringify(OperationPersonalCache.price_setting.setting),
    is_show: OperationPersonalCache.is_show,
    tag: OperationPersonalCache.grade_tag,
    param: OperationPersonalCache.grade_param,
    config: JSON.stringify({
      streamer: {
        soft_on: OperationPersonalCache.soft.Streamer.soft_on,
        ...StreamerData,
      },
      tracker: {
        soft_on: OperationPersonalCache.soft.Tracker.soft_on,
        ...TrackerData,
      },
      autoface: {
        soft_on: OperationPersonalCache.soft.Autoface.soft_on,
        ...AutofaceData,
      },
      rigger: {
        soft_on: OperationPersonalCache.soft.Rigger.soft_on,
        ...RiggerData,
      },
      retargeter: {
        soft_on: OperationPersonalCache.soft.Retargeter.soft_on,
        ...RetargeterData,
      },
      livedrive: {
        soft_on: OperationPersonalCache.soft.Livedrive.soft_on,
        ...LivedriveData,
      },
      muscledrive: {
        soft_on: OperationPersonalCache.soft.Muscledrive.soft_on,
        ...MuscledriveData,
      },
      Batchrobot: {
        soft_on: OperationPersonalCache.soft.Batchrobot.soft_on,
        ...BatchrobotData,
      },
     
      riggermeta: {
        soft_on: OperationPersonalCache.soft.Riggermeta.soft_on,
        ...riggermetaData,
      },
      seattleaitrainer: {
        soft_on: OperationPersonalCache.soft.Seattleaitrainer.soft_on,
        ...seattleaitrainerData,
      },
      fgmodeledit: {
        soft_on: OperationPersonalCache.soft.Fgmodeledit.soft_on,
        ...fgmodeleditData,
      },
      posemakerfbx: {
        soft_on: OperationPersonalCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationPersonalCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationPersonalCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationPersonalCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: OperationPersonalCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationPersonalCache.soft.Retargeterseattleaiofflinecustom.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationPersonalCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationPersonalCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationPersonalCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationPersonalCache.soft.Batchrobotcustom.soft_on,
      },
    
    }),
  }
  
  let grade_addRes = await grade_addApi(data)
  if (grade_addRes.data.code !== 200) {
    message.error(grade_addRes.data.msg)
    return
  }
  message.success(grade_addRes.data.msg)
  const currentPage: any = sessionStorage.getItem('PERSONAL-CURRENTPAGE')
  const page = parseInt(currentPage)

  personal_list_Fun(page).then((_) => {})
  GlobalDispatch({
    type: 'clear_Recent_OperationPersonal_data_Add',
    payload: {},
  })
}

export const OperationPersonalModal_callback = async () => {
  let OperationPersonalCache = GlobalState.OperationPersonal.recent
 
  const StreamerData =
    OperationPersonalCache.soft.Streamer.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Streamer }
  const TrackerData =
    OperationPersonalCache.soft.Tracker.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Tracker }
  const AutofaceData =
    OperationPersonalCache.soft.Autoface.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Autoface }
  const RiggerData =
    OperationPersonalCache.soft.Rigger.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Rigger }
  const LivedriveData =
    OperationPersonalCache.soft.Livedrive.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Livedrive }
  const RetargeterData =
    OperationPersonalCache.soft.Retargeter.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Retargeter }
  const MuscledriveData =
    OperationPersonalCache.soft.Muscledrive.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Muscledrive }
  const BatchrobotData =
    OperationPersonalCache.soft.Batchrobot.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Batchrobot }
 
  const riggermetaData =
    OperationPersonalCache.soft.Riggermeta.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Riggermeta }
  const seattleaitrainerData =
    OperationPersonalCache.soft.Seattleaitrainer.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Seattleaitrainer }
  const fgmodeleditData =
    OperationPersonalCache.soft.Fgmodeledit.soft_on === 0
      ? {}
      : { ...OperationPersonalCache.soft.Fgmodeledit }
  let data = {
    id: OperationPersonalCache.grade_id,
    name: OperationPersonalCache.grade_name,
    image: OperationPersonalCache.grade_image,
    is_show: OperationPersonalCache.is_show,
    tag: OperationPersonalCache.grade_tag,
    param: OperationPersonalCache.grade_param,
    price_setting: JSON.stringify(OperationPersonalCache.price_setting),
    config: JSON.stringify({
      streamer: {
        soft_on: OperationPersonalCache.soft.Streamer.soft_on,
        ...StreamerData,
      },
      tracker: {
        soft_on: OperationPersonalCache.soft.Tracker.soft_on,
        ...TrackerData,
      },
      autoface: {
        soft_on: OperationPersonalCache.soft.Autoface.soft_on,
        ...AutofaceData,
      },
      rigger: {
        soft_on: OperationPersonalCache.soft.Rigger.soft_on,
        ...RiggerData,
      },
      retargeter: {
        soft_on: OperationPersonalCache.soft.Retargeter.soft_on,
        ...RetargeterData,
      },
      livedrive: {
        soft_on: OperationPersonalCache.soft.Livedrive.soft_on,
        ...LivedriveData,
      },
      muscledrive: {
        soft_on: OperationPersonalCache.soft.Muscledrive.soft_on,
        ...MuscledriveData,
      },
      batchrobot: {
        soft_on: OperationPersonalCache.soft.Batchrobot.soft_on,
        ...BatchrobotData,
      },
   
      riggermeta: {
        soft_on: OperationPersonalCache.soft.Riggermeta.soft_on,
        ...riggermetaData,
      },
      seattleaitrainer: {
        soft_on: OperationPersonalCache.soft.Seattleaitrainer.soft_on,
        ...seattleaitrainerData,
      },
      fgmodeledit: {
        soft_on: OperationPersonalCache.soft.Fgmodeledit.soft_on,
        ...fgmodeleditData,
      },
      posemakerfbx: {
        soft_on: OperationPersonalCache.soft.Posemakerfbx.soft_on,
      },
      posemakerposeasset: {
        soft_on: OperationPersonalCache.soft.Posemakerposeasset.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: OperationPersonalCache.soft.Retargeterofflinearkit.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: OperationPersonalCache.soft.Retargeterofflinemeta.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: OperationPersonalCache.soft.Retargeterofflineposeasset.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on:
          OperationPersonalCache.soft.Retargeterseattleaiofflinecustom.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimemeta.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimeposeasset.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: OperationPersonalCache.soft.Livedriverealtimecustom.soft_on,
      },
      batchrobotarkit: {
        soft_on: OperationPersonalCache.soft.Batchrobotarkit.soft_on,
      },
      batchrobotmeta: {
        soft_on: OperationPersonalCache.soft.Batchrobotmeta.soft_on,
      },
      batchrobotposeasset: {
        soft_on: OperationPersonalCache.soft.Batchrobotposeasset.soft_on,
      },
      batchrobotcustom: {
        soft_on: OperationPersonalCache.soft.Batchrobotcustom.soft_on,
      },
     
    }),
  }
  let grade_addRes = await grade_editApi(data)
  if (grade_addRes.data.code !== 200) {
    message.error(grade_addRes.data.msg)
    return
  }
  message.success(grade_addRes.data.msg)
  const currentPage: any = sessionStorage.getItem('PERSONAL-CURRENTPAGE')
  const page = parseInt(currentPage)

  personal_list_Fun(page).then((_) => {})
  // }
}

export const DeleteModal_callback = async () => {
  // 取当前页面的列表数据
  const OperationPersonalData = GlobalState.OperationPersonal.allData
  const OperationEducationData = GlobalState.OperationEducation.allData
  const OperationEnterpriseData = GlobalState.OperationEnterprise.allData
  // console.log(OperationPersonalData);

  let Del = GlobalState.Del

  if (Del.length === 0) return
  GlobalDispatch({ type: 'clear_del_array', payload: [] })
  if (Del.length !== 1) {
    for (const item of Del) {
      let res =
        Del[0].symbol.indexOf('Operation') !== -1
          ? await grade_delApi(item.Del_Key)
          : await market_grade_delApi(item.Del_Key)
      if (res.data.code !== 200) {
        message.error(res.data.msg)
        continue
      }
      message.success(res.data.msg)
    }
  } else {
    let DelRes =
      Del[0].symbol.indexOf('Operation') !== -1
        ? await grade_delApi(Del[0].Del_Key)
        : await market_grade_delApi(Del[0].Del_Key)
    if (DelRes.data.code !== 200) {
      message.error(DelRes.data.msg)
      return
    }
    message.success(DelRes.data.msg)
  }

  if (Del[0].symbol === 'OperationEducation') {
    // const size = useRootStore(state => state.PageSize.OperationEnterprise)
    const currentPage: any = sessionStorage.getItem('EDUCATION-CURRENTPAGE')
    const page = parseInt(currentPage)
    if (page !== 1 && Del.length == OperationEducationData.length) {
      education_list_Fun(page - 1, OperationLoginSetType.education).then(
        (_) => {}
      )
    } else {
      education_list_Fun(page, OperationLoginSetType.education).then((_) => {})
      // personal_list_Fun(page).then((_) => {})
    }
  }
  if (Del[0].symbol === 'OperationEnterprise') {
    const currentPage: any = sessionStorage.getItem('ENTERPRISE-CURRENTPAGE')
    const page = parseInt(currentPage)
    if (page !== 1 && Del.length == OperationEnterpriseData.length) {
      enterprise_list_Fun(page - 1, OperationLoginSetType.enterprise).then(
        (_) => {}
      )
    } else {
      enterprise_list_Fun(page, OperationLoginSetType.enterprise).then(
        (_) => {}
      )
    }
  }

  // console.log('当前页长度' + OperationPersonalData.length)

  if (Del[0].symbol === 'OperationPersonal') {
    const currentPage: any = sessionStorage.getItem('PERSONAL-CURRENTPAGE')
    const page = parseInt(currentPage)
    // 如果当前页的数据长度等于要删除的数组长度，则加载前一页的数据列

    if (page !== 1 && Del.length == OperationPersonalData.length) {
      personal_list_Fun(page - 1).then((_) => {})
    } else {
      personal_list_Fun(page).then((_) => {})
    }
    //
  }
  if (Del[0].symbol === 'MarketEducation') {
    market_Education_index_Fun(MarketType.education, 1).then((_) => {})
  }
  if (Del[0].symbol === 'MarketEnterprise') {
    market_Enterprise_index_Fun(MarketType.enterprise, 1).then((_) => {})
  }
}
export const MarketEducationModal_Add_callback = async () => {
  let MarketEducation_Add =
    GlobalState.MarketEducation_Add.recent[0] ||
    GlobalState.MarketEducation_Add.recent[1] ||
    GlobalState.MarketEducation_Add.recent[2] ||
    GlobalState.MarketEducation_Add.recent[3] ||
    GlobalState.MarketEducation_Add.recent
  
  let data = {
    type: MarketType.education,
    title: MarketEducation_Add.company_title,
    name: MarketEducation_Add.company_name,
    pass: MarketEducation_Add.company_show_pass,
    grade: MarketEducation_Add.grade_id,
    num: MarketEducation_Add.company_account_num,
    usage_date: MarketEducation_Add.company_upgrade_date,
    config: JSON.stringify({
      streamer: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Streamer'
        )!.soft_on,
      },
      tracker: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Tracker'
        )!.soft_on,
      },
      autoface: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Autoface'
        )!.soft_on,
      },
      rigger: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Rigger'
        )!.soft_on,
      },
      retargeter: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Retargeter'
        )!.soft_on,
      },
      livedrive: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Livedrive'
        )!.soft_on,
      },
      muscledrive: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Muscledrive'
        )!.soft_on,
      },
      batchrobot: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobot'
        )!.soft_on,
      },
    
      riggermeta: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Riggermeta'
        )!.soft_on,
      },
      seattleaitrainer: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Seattleaitrainer'
        )!.soft_on,
      },
      fgmodeledit: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Fgmodeledit'
        )!.soft_on,
      },
      posemakerfbx: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Posemakerfbx'
        )!.soft_on,
      },
      posemakerposeasset: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Posemakerposeasset'
        )!.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinearkit'
        )!.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinemeta'
        )!.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflineposeasset'
        )!.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterseattleaiofflinecustom'
        )!.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimemeta'
        )!.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimeposeasset'
        )!.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimecustom'
        )!.soft_on,
      },
      batchrobotarkit: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotarkit'
        )!.soft_on,
      },
      batchrobotmeta: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobotmeta'
        )!.soft_on,
      },
      batchrobotposeasset: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotposeasset'
        )!.soft_on,
      },
      batchrobotcustom: {
        soft_on: MarketEducation_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotcustom'
        )!.soft_on,
      },
    }),
  }
  let education_accountRes = await education_account_addApi(
    data.type,
    data.title,
    data.name,
    data.pass,
    data.grade,
    data.num,
    data.usage_date,
    data.config
  )
  if (education_accountRes.data.code !== 200) {
    message.error(education_accountRes.data.msg)
    return
  }
  message.success(education_accountRes.data.msg)
  GlobalDispatch({ type: 'clear_Recent_MarketEducation_Add', payload: {} })
  market_Education_index_Fun(MarketType.education, 1).then((_) => {})
  // }
}

export const MarketEnterpriseModal_Add_callback = async () => {
  let MarketEnterprise_Add =
    GlobalState.MarketEnterprise_Add.recent[0] ||
    GlobalState.MarketEnterprise_Add.recent[1] ||
    GlobalState.MarketEnterprise_Add.recent[2] ||
    GlobalState.MarketEnterprise_Add.recent[3] ||
    GlobalState.MarketEnterprise_Add.recent
 
    console.log(MarketEnterprise_Add.soft_config,'MarketEnterprise_Add.soft_config');
    
  let data = {
    type: MarketType.enterprise,
    title: MarketEnterprise_Add.company_title,
    name: MarketEnterprise_Add.company_name,
    pass: MarketEnterprise_Add.company_show_pass,
    grade: MarketEnterprise_Add.grade_id,
    num: MarketEnterprise_Add.company_account_num,
    effect_date: MarketEnterprise_Add.company_effect_date,
    usage_date: MarketEnterprise_Add.company_usage_day,
    config: JSON.stringify({
      streamer: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Streamer'
        )!.soft_on,
      },
      tracker: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Tracker'
        )!.soft_on,
      },
      autoface: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Autoface'
        )!.soft_on,
      },
      rigger: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Rigger'
        )!.soft_on,
      },
      retargeter: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Retargeter'
        )!.soft_on,
      },
      livedrive: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Livedrive'
        )!.soft_on,
      },
      muscledrive: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Muscledrive'
        )!.soft_on,
      },
      batchrobot: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobot'
        )!.soft_on,
      },
     
      riggermeta: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Riggermeta'
        )!.soft_on,
      },
      seattleaitrainer: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Seattleaitrainer'
        )!.soft_on,
      },
      fgmodeledit: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Fgmodeledit'
        )!.soft_on,
      },
      posemakerfbx: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Posemakerfbx'
        )!.soft_on,
      },
      posemakerposeasset: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Posemakerposeasset'
        )!.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinearkit'
        )!.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinemeta'
        )!.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflineposeasset'
        )!.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterseattleaiofflinecustom'
        )!.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimemeta'
        )!.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimeposeasset'
        )!.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimecustom'
        )!.soft_on,
      },
      batchrobotarkit: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotarkit'
        )!.soft_on,
      },
      batchrobotmeta: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobotmeta'
        )!.soft_on,
      },
      batchrobotposeasset: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotposeasset'
        )!.soft_on,
      },
      batchrobotcustom: {
        soft_on: MarketEnterprise_Add.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotcustom'
        )!.soft_on,
      },
    }),
  }
  let enterprise_accountRes = await enterprise_account_addApi(
    data.type,
    data.title,
    data.name,
    data.pass,
    data.grade,
    data.num,
    data.effect_date,
    data.usage_date,
    data.config
  )
  if (enterprise_accountRes.data.code !== 200) {
    message.error(enterprise_accountRes.data.msg)
    return
  }
  message.success(enterprise_accountRes.data.msg)
  GlobalDispatch({ type: 'clear_Recent_MarketEnterprise_Add', payload: {} })
  market_Enterprise_index_Fun(MarketType.enterprise, 1).then((_) => {})
  // }
}

export const MarketEducationModal_callback = async () => {
  let MarketEducation =
    GlobalState.MarketEducation.recent
  let data = {
    id: MarketEducation.company_id,
    title: MarketEducation.company_title,
    name: MarketEducation.company_name,
    pass: MarketEducation.company_show_pass,
    grade: MarketEducation.grade_id,
    num: MarketEducation.company_account_num,
    usage_date: MarketEducation.company_upgrade_date,
    config: JSON.stringify({
      streamer: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Streamer'
        )!.soft_on,
      },
      tracker: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Tracker'
        )!.soft_on,
      },
      autoface: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Autoface'
        )!.soft_on,
      },
      rigger: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Rigger'
        )!.soft_on,
      },
      retargeter: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Retargeter'
        )!.soft_on,
      },
      livedrive: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Livedrive'
        )!.soft_on,
      },
      muscledrive: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Muscledrive'
        )!.soft_on,
      },
      batchrobot: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobot'
        )!.soft_on,
      },

      riggermeta: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Riggermeta'
        )!.soft_on,
      },
      seattleaitrainer: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Seattleaitrainer'
        )!.soft_on,
      },
      fgmodeledit: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Fgmodeledit'
        )!.soft_on,
      },
      posemakerfbx: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Posemakerfbx'
        )!.soft_on,
      },
      posemakerposeasset: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Posemakerposeasset'
        )!.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinearkit'
        )!.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinemeta'
        )!.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflineposeasset'
        )!.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterseattleaiofflinecustom'
        )!.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimemeta'
        )!.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimeposeasset'
        )!.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimecustom'
        )!.soft_on,
      },
      batchrobotarkit: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotarkit'
        )!.soft_on,
      },
      batchrobotmeta: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobotmeta'
        )!.soft_on,
      },
      batchrobotposeasset: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotposeasset'
        )!.soft_on,
      },
      batchrobotcustom: {
        soft_on: MarketEducation.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotcustom'
        )!.soft_on,
      },
    }),
  }
  let education_account_editRes = await education_account_editApi(
    data.id,
    data.title,
    data.name,
    data.pass,
    data.grade,
    data.num,
    data.usage_date,
    data.config
  )
  if (education_account_editRes.data.code !== 200) {
    message.error(education_account_editRes.data.msg)
    return
  }
  message.success(education_account_editRes.data.msg)
  GlobalDispatch({ type: 'clear_Recent_MarketEducation', payload: {} })
  market_Education_index_Fun(MarketType.education, 1).then((_) => {})
  // }
}

export const MarketEnterpriseModal_callback = async () => {
  let MarketEnterprise =
    GlobalState.MarketEnterprise.recent[0] ||
    GlobalState.MarketEnterprise.recent[1] ||
    GlobalState.MarketEnterprise.recent[2] ||
    GlobalState.MarketEnterprise.recent[3] ||
    GlobalState.MarketEnterprise.recent

  let data = {
    id: MarketEnterprise.company_id,
    title: MarketEnterprise.company_title,
    name: MarketEnterprise.company_name,
    pass: MarketEnterprise.company_show_pass,
    grade: MarketEnterprise.grade_id,
    num: MarketEnterprise.company_account_num,
    effect_date: MarketEnterprise.company_effect_date,
    usage_date: MarketEnterprise.company_usage_day,
    config: JSON.stringify({
      streamer: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Streamer'
        )!.soft_on,
      },
      tracker: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Tracker'
        )!.soft_on,
      },
      autoface: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Autoface'
        )!.soft_on,
      },
      rigger: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Rigger'
        )!.soft_on,
      },
      retargeter: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Retargeter'
        )!.soft_on,
      },
      livedrive: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Livedrive'
        )!.soft_on,
      },
      muscledrive: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Muscledrive'
        )!.soft_on,
      },
      batchrobot: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobot'
        )!.soft_on,
      },
   
      riggermeta: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Riggermeta'
        )!.soft_on,
      },
      seattleaitrainer: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Seattleaitrainer'
        )!.soft_on,
      },
      fgmodeledit: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Fgmodeledit'
        )!.soft_on,
      },
      posemakerfbx: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Posemakerfbx'
        )!.soft_on,
      },
      posemakerposeasset: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Posemakerposeasset'
        )!.soft_on,
      },
      retargeterofflinearkit: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinearkit'
        )!.soft_on,
      },
      retargeterofflinemeta: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflinemeta'
        )!.soft_on,
      },
      retargeterofflineposeasset: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterofflineposeasset'
        )!.soft_on,
      },
      retargeterseattleaiofflinecustom: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Retargeterseattleaiofflinecustom'
        )!.soft_on,
      },
      livedriverealtimemeta: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimemeta'
        )!.soft_on,
      },
      livedriverealtimeposeasset: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimeposeasset'
        )!.soft_on,
      },
      livedriverealtimecustom: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Livedriverealtimecustom'
        )!.soft_on,
      },
      batchrobotarkit: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotarkit'
        )!.soft_on,
      },
      batchrobotmeta: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) => item.soft_title === 'Batchrobotmeta'
        )!.soft_on,
      },
      batchrobotposeasset: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotposeasset'
        )!.soft_on,
      },
      batchrobotcustom: {
        soft_on: MarketEnterprise.soft_config.find(
          (item: { soft_title: string }) =>
            item.soft_title === 'Batchrobotcustom'
        )!.soft_on,
      },
    }),
  }
  let enterprise_account_editRes = await enterprise_account_editApi(
    data.id,
    data.title,
    data.name,
    data.pass,
    data.grade,
    data.num,
    data.effect_date,
    data.usage_date,
    data.config
  )
  if (enterprise_account_editRes.data.code !== 200) {
    message.error(enterprise_account_editRes.data.msg)
    return
  }
  message.success(enterprise_account_editRes.data.msg)
  GlobalDispatch({ type: 'clear_Recent_MarketEnterprise', payload: {} })
  market_Enterprise_index_Fun(MarketType.enterprise, 1).then((_) => {})
  // }
}

export const DelayModal_callback = async () => {
  let Delay = GlobalState.Delay
  let account_delayRes = await account_delayApi(
    Delay.Del_Key,
    Delay.effect_date,
    Delay.usage_date
  )
  if (account_delayRes.data.code !== 200) {
    message.error(account_delayRes.data.msg)
    return
  }
  message.success(account_delayRes.data.msg)
  GlobalDispatch({ type: 'clear_delay_array', payload: {} })
  market_Enterprise_index_Fun(MarketType.enterprise, 1).then((_) => {})
}
