import React from 'react';
import PublicStyle from "@/components/Dialog/DialogTypeComponent/index.module.scss";

interface IProp {
}

const DeleteModal = (props: IProp) => {

    return (
        <div className={PublicStyle.Box}>一旦删除将造成不可逆转的结果，是否继续进行删除?</div>
    )
}
export default DeleteModal
