import React, { useEffect, useState } from 'react'
import { GlobalDispatch, GlobalState, useRootStore } from '@/store'
import PublicStyle from '../index.module.scss'
import { Input, message, Switch, Card, Select, Form, Button, Space } from 'antd'
import {
  CloseCircleFilled,
  PlusCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import SoftwareControl from '@/components/OperationSoftwareControl'

const { Option } = Select
const { useForm } = Form
interface IProp {}

const OperationEnterpriseModal = (props: IProp) => {
  const UrlOperationEnterprise = useRootStore(
    (state) => state.OperationEnterprise_Add.recent
  )
  const [form] = useForm()
  let ArrayList = {
    market_price: 0,
    discount_price: 0,
    cycle_num: 0,
    cycle_type: 1,
  }
  const onChange = (checked: any) => {
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationEnterPrice_option_Add',
      payload: { market_edit: checked ? 1 : 0 },
    })
  }
  const onIsShowChange = (checked: any) => {
    // debugger
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationEnterPrice_option_Add',
      payload: { is_show: checked ? 1 : 0 },
    })
  }
  const changeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
  }
  const changeGrade_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationEnterPrice_option_Add',
      payload: { grade_name: e.target.value },
    })
  }
  const changeGrade_image = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationEnterPrice_option_Add',
      payload: { grade_image: e.target.value },
    })
  }
  const changeGrade_tag = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationEnterPrice_option_Add',
      payload: { grade_tag: e.target.value },
    })
  }
  const changeGrade_param = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationEnterPrice_option_Add',
      payload: { grade_param: e.target.value },
    })
  }
  const changeUser_min = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationEnterPrice_option_Add',
      payload: { user_min: Number(e.target.value) },
    })
  }
  const changeUser_max = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationEnterPrice_option_Add',
      payload: { user_max: Number(e.target.value) },
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      setting: UrlOperationEnterprise.price_setting.setting,
    })
  }, [form])

  const onFinish = (changedValues: any, allValues: any) => {
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationEnterPrice_option_Add',
      payload: { price_setting: allValues },
    })
  }

  return (
    <div className={PublicStyle.Box}>
      <div className={PublicStyle.title}>基础设定</div>
      <div className={PublicStyle.infoBox}>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>等级名称：</span>
          <Input
            value={UrlOperationEnterprise.grade_name}
            onChange={changeGrade_name}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>适用人群：</span>
          <Input
            value={UrlOperationEnterprise.grade_image}
            onChange={changeGrade_image}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>标签(选填)：</span>
          <Input
            value={UrlOperationEnterprise.grade_tag}
            onChange={changeGrade_tag}
            placeholder={'以逗号分隔。例如：xxx,xxx'}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>参数文档：</span>
          <Input
            value={UrlOperationEnterprise.grade_param}
            onChange={changeGrade_param}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>
            是否允许市场人员修改权限：
          </span>
          <Switch
            checked={Boolean(UrlOperationEnterprise.market_edit)}
            onChange={onChange}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>是否Avatary页面显示：</span>
          <Switch
            checked={Boolean(UrlOperationEnterprise.is_show)}
            onChange={onIsShowChange}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>子账号数：</span>
          <Input
            style={{ width: 120, textAlign: 'center', borderRightWidth: '1' }}
            value={UrlOperationEnterprise.user_min}
            onChange={changeUser_min}
            placeholder="min"
            spellCheck={false}
            suffix="个"
          />
          &nbsp; - &nbsp;
          <Input
            style={{ width: 120, textAlign: 'center' }}
            value={UrlOperationEnterprise.user_max}
            onChange={changeUser_max}
            placeholder="Max"
            suffix="个"
          />
        </div>
      </div>
      <div className={PublicStyle.title}>收费设定</div>
      <div className={PublicStyle.infoBox} style={{ flexDirection: 'row' }}>
        <Form
          name="dynamic_form_nest_item"
          onValuesChange={onFinish}
          autoComplete="off"
          form={form}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <Form.List name="setting">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      padding: 20,
                      margin: '20px',
                      border: '1px solid #e1e1e1',
                      position: 'relative',
                      width: '300px',
                      height: '200px',
                    }}
                  >
                    <Form.Item
                      label="单 价："
                      labelCol={{ span: 7 }}
                      {...restField}
                      name={[name, 'market_price']}
                    >
                      <Input onChange={changeData} />
                    </Form.Item>
                    <Form.Item
                      label="折扣价："
                      labelCol={{ span: 7 }}
                      {...restField}
                      name={[name, 'discount_price']}
                    >
                      <Input onChange={changeData} />
                    </Form.Item>
                    <div className={PublicStyle.part}>
                      <Form.Item
                        label="周 期"
                        labelCol={{ span: 9 }}
                        style={{ marginBottom: 0 }}
                        {...restField}
                        name={[name, 'cycle_num']}
                      >
                        <Input onChange={changeData} />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        {...restField}
                        name={[name, 'cycle_type']}
                      >
                        <Select>
                          <Option value={1}>月</Option>
                          <Option value={12}>年</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <CloseCircleFilled
                      onClick={() => remove(name)}
                      className={PublicStyle.add}
                      style={{ fontSize: 22 }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Card
                    style={{ width: '250px', height: 200, marginTop: 20 }}
                    onClick={() => add(ArrayList)}
                  >
                    <PlusCircleFilled
                      className={PublicStyle.PlusCircleFilled}
                    />
                  </Card>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </div>
      <div className={PublicStyle.title}>软件权限</div>
      <div className={PublicStyle.infoBox}>
        <SoftwareControl recentName={'OperationEnterprise_Add'} />
      </div>
    </div>
  )
}
export default OperationEnterpriseModal
