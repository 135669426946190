import {TableType} from "@/interface/tableType";

const OperationPersonalTable: TableType[] = [
    {
        title: "序号",
        key: "SerialNumber",
    }, {
        title: "等级名称",
        key: "GradeName",
    }, {
        title: "收费",
        key: "Charge",
    }, {
        title: "Streamer",
        key: "Streamer",
    }, {
        title: "Tracker",
        key: "Tracker",
    }, {
        title: "Retargeter",
        key: "Retargeter",
    }, {
        title: "Rigger",
        key: "Rigger",
    }, {
        title: "LiveDrive_2D",
        key: "LiveDrive_2D",
    }, {
        title: "LiveDrive_3D",
        key: "LiveDrive_3D",
    }, {
        title: "AutoFace",
        key: "AutoFace",
    }, {
        title: "MuscleDrive",
        key: "MuscleDrive",
    },
    {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "编辑", title: "运营端个人编辑", key: "OperationPersonalModal", symbol: "OperationPersonal"},
            {buttonTitle: "删除", title: "删除", key: "DeleteModal", symbol: "OperationPersonal"}
        ]
    },
]
export default OperationPersonalTable
