import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import {Button, message, Space, Table} from 'antd';
import {PageSize} from "@/UISetting/DropdownSetting";
import OperationEnterpriseTable from "@/UISetting/OperationEnterpriseTable";
import {isNullOrUndefined} from "@/utils/util";
import Dialog from "@/components/Dialog";
import {OperationEnterpriseReduxType} from "@/interface/recentType";
import {spaceOptionType, symbolType} from "@/interface/tableType";
import {GlobalDispatch, useRootStore} from "@/store";
import {OperationLoginSetType} from "@/interface/EnumList";
import {enterprise_list_Fun, grade_info_OperationEnterprise_Fun} from "@/api/OperationApiFun";

const {Column} = Table;

interface IProps {

}

const OperationEnterprise = (props: IProps) => {
    const OperationEnterpriseData = useRootStore(state => state.OperationEnterprise.allData)
    const size = useRootStore(state => state.PageSize.OperationEnterprise)
    const [currentPage, setCurrentPage] = useState<number>(1)
    sessionStorage.setItem('ENTERPRISE-CURRENTPAGE', currentPage.toString())
    const [visible, setVisible] = useState(false);
    const [DelData, setDelData] = useState([]);
    const [OptionType, setOptionType] = useState<spaceOptionType>({
        buttonTitle: "",
        title: "",
        key: "LimitSettingsModal",
        symbol: "EducationLogin"
    });
   
    const changePage = (page: any) => {
        setCurrentPage(page)
        sessionStorage.setItem('ENTERPRISE-CURRENTPAGE', page)
        enterprise_list_Fun(page, OperationLoginSetType.enterprise).then(_ => {
        })
    }
    useEffect(() => {
      enterprise_list_Fun(currentPage, OperationLoginSetType.enterprise).then(_=> {
        
      })
  }, [])

    const operationClickHandle = (e: any, item: spaceOptionType, recode: OperationEnterpriseReduxType) => {
      
      
        setOptionType(item)
        setVisible(true)
        if (item.key === "DeleteModal") {
            GlobalDispatch({type: "replace_del_array", payload: {Del_Key: recode.key, symbol: item.symbol}})
            return
        }
        grade_info_OperationEnterprise_Fun(recode.key).then(_ => {
        })
    }
    const addRow = () => {
        setOptionType({
            buttonTitle: "新增",
            title: "新增企业选项",
            key: "OperationEnterpriseModal_Add",
            symbol: "OperationEnterprise_Add"
        })
        setVisible(true)
    }
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setDelData(selectedRowKeys)
        }
    }
    
    useEffect(() => {
        if (isNullOrUndefined(DelData)) return
        let newArray = DelData.map<{ Del_Key: string, symbol: symbolType }>(item => {
            return {Del_Key: item, symbol: "OperationEnterprise"}
        })
        GlobalDispatch({type: "replace_del", payload: newArray})
    }, [DelData])
    const DelMore = () => {
        if (DelData.length === 0) {
            message.error("请选择要删除的列表");
            return
        }
        setOptionType({buttonTitle: "删除", title: "全部删除", key: "DeleteModal", symbol: "OperationEnterprise"})
        setVisible(true)
    }
    return (
        <div className={style.container}>
            <div className={style.control}>
                <div className={style.button}>
                    <Button type="primary" onClick={addRow}>新增</Button>
                    <Button type="primary" onClick={DelMore} danger>删除</Button>
                </div>
            </div>
            <div className={style.Table}>
                <Table dataSource={OperationEnterpriseData}
                       pagination={{
                           position: ["bottomLeft"],
                           current: currentPage,
                           pageSize: PageSize,
                           total: size,
                           onChange: changePage
                       }}
                       rowSelection={{...rowSelection}}>
                    {OperationEnterpriseTable.map((item) => {
                            return <Column title={item.title} dataIndex={item.key} key={item.key}
                                           render={(text: string, recode: OperationEnterpriseReduxType, index: number) => {
                                               if (isNullOrUndefined(item.SpaceOption)) {
                                                   if (item.key === "SerialNumber") {
                                                    const currentPage:any = sessionStorage.getItem('enterprise-currentpage')
                                                       return index + (currentPage - 1) * PageSize + 1
                                                   }
                                                   return text
                                               }
                                               return <Space size="middle">
                                                   {item.SpaceOption!.map(item => {
                                                       return <Button type="primary" key={item.key}
                                                                      onClick={(e) => {
                                                                          operationClickHandle(e, item, recode)
                                                                      }}>{item.buttonTitle}</Button>
                                                   })}
                                               </Space>
                                           }}/>
                        }
                    )}

                </Table>
            </div>
            <Dialog OptionType={OptionType} ModuleVisible={visible}
                    setModuleVisible={setVisible}/>
        </div>
    )
}
export default OperationEnterprise
