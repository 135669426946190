import React, {useState} from 'react';
import {GlobalDispatch, useRootStore} from "@/store";
import PublicStyle from "../index.module.scss"
import {Input, message} from "antd";

interface IProp {

}

const LimitSettingsModal = (props: IProp) => {
    const optionKey = useRootStore(state => state.LoginSetting.recent.key)
    const allData = useRootStore(state => state.LoginSetting.allData)
    const data = allData.filter(item => item.key === optionKey)[0]
    const [Account, setAccount] = useState<number>(data.setting_account)
    const [Device, setDevice] = useState(data.setting_device)
    const changeSettingAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        setAccount(Number(e.target.value))
        GlobalDispatch({type: "change_recent_a_data", payload: {setting_account: Number(e.target.value)}})
    }
    const changeSettingDevice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        setDevice(Number(e.target.value))
        GlobalDispatch({type: "change_recent_a_data", payload: {setting_device: Number(e.target.value)}})
    }
    return (<div className={PublicStyle.Box}>
        <div className={PublicStyle.title}>基础设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>单电脑允许账户数：</span><Input
                style={{width: "45%"}} value={Account} onChange={changeSettingAccount} suffix="个"/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>单账户允许修改多少次登录设备：</span><Input
                style={{width: "45%"}} value={Device} onChange={changeSettingDevice} suffix="次"/>
            </div>
        </div>
    </div>)
}
export default LimitSettingsModal
