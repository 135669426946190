import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import {Button, Dropdown, Input, message, Space, Table} from 'antd';
import {PageSize} from "@/UISetting/DropdownSetting";
import MarketEducationTable from "@/UISetting/MarketEducationTable";
import {isNullOrUndefined} from "@/utils/util";
import Dialog from "@/components/Dialog";
import {MarketEducationReduxType} from "@/interface/recentType";
import {DownOutlined} from "@ant-design/icons";
import CreatAntdMenu from "@/utils/creatAntdUI";
import {spaceOptionType, symbolType} from "@/interface/tableType";
import {GlobalDispatch, useRootStore} from "@/store";
import {MarketType} from "@/interface/EnumList";
import {market_Education_index_Fun} from "@/api/OperationApiFun";
import {account_infoApi, account_softApi} from "@/api/MarketApi";

const {Column} = Table;

interface IProps {

}

const MarketEducation = (props: IProps) => {
    const [Level, setLevel] = useState<string>('')
    const EducationDropdown = useRootStore(state => state.DropdownTitle.EducationDropdown)
    const size = useRootStore(state => state.PageSize.MarketEducation)
    const searchEducationDropdown = [{grade_id: 0, grade_name: "请选择"}, ...EducationDropdown]
    const [currentPage, setCurrentPage] = useState<number>(1)
    const MarketEducation = useRootStore(state => state.MarketEducation.allData)
    const [visible, setVisible] = useState(false);
    const [DelData, setDelData] = useState([]);
    const [currentGradeId, setCurrentGradeId] = useState(0);
    const [searchTitle, setSearchTitle] = useState("");
    const [OptionType, setOptionType] = useState<spaceOptionType>({
        buttonTitle: "",
        title: "",
        key: "LimitSettingsModal",
        symbol: "EducationLogin"
    });
    const changePage = (page: any) => {
        setCurrentPage(page)
        market_Education_index_Fun(MarketType.education, page).then(_ => {
        })
    }
    const addRow = () => {
        GlobalDispatch({type: "clear_Account_soft", payload: []})
        setOptionType({
            buttonTitle: "新增",
            title: "新增教育选项",
            key: "MarketEducationModal_Add",
            symbol: "MarketEducation_Add"
        })
        setVisible(true)
    }
    const operationClickHandle = async (e: any, item: spaceOptionType, recode: MarketEducationReduxType) => {
        let account_infoRes = await account_infoApi(recode.key)
        setOptionType(item)
        setVisible(true)
        if (item.key === "DeleteModal") {
            GlobalDispatch({type: "replace_del_array", payload: {Del_Key: recode.key, symbol: item.symbol}})
            return
        }
        let account_soft = await account_softApi(recode.key, account_infoRes.data.data.grade_id)
        if (!isNullOrUndefined(account_soft.data.data)) {
            GlobalDispatch({type: "replace_Account_soft", payload: account_soft.data.data})
            account_infoRes.data.data.soft_config = [{...account_soft.data.data}]
        } else {
            GlobalDispatch({type: "clear_Account_soft", payload: []})
        }
        GlobalDispatch({type: "replace_Recent_MarketEducation", payload: account_infoRes.data.data})
    }
    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            setDelData(selectedRowKeys)
        }
    }
    const getLevel = (name: { grade_id: number, grade_name: string }) => {
        setCurrentGradeId(name.grade_id)
        setLevel(name.grade_name)
    }
    useEffect(() => {
        market_Education_index_Fun(MarketType.education, currentPage).then(_ => {
        })
        setLevel(searchEducationDropdown[0].grade_name)
    }, [searchTitle])
    useEffect(() => {
        if (isNullOrUndefined(DelData)) return
        let newArray = DelData.map<{ Del_Key: string, symbol: symbolType }>(item => {
            return {Del_Key: item, symbol: "MarketEducation"}
        })
        GlobalDispatch({type: "replace_del", payload: newArray})
    }, [DelData])
    const DelMore = () => {
        if (DelData.length === 0) {
            message.error("请选择要删除的列表");
            return
        }
        setOptionType({buttonTitle: "删除", title: "全部删除", key: "DeleteModal", symbol: "MarketEducation"})
        setVisible(true)
    }
    const SearchHandler = () => {
        market_Education_index_Fun(MarketType.education, currentPage, currentGradeId, "", searchTitle).then(_ => {
        })
    }
    const changeSearchTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isNullOrUndefined(searchTitle)) {
            market_Education_index_Fun(MarketType.education, currentPage).then(_ => {
            })
        }
        setSearchTitle(e.target.value)
    }
    return (
        <div className={style.container}>
            <div className={style.control}>
                <div className={style.button}>
                    <Button type="primary" onClick={addRow}>新增</Button>
                    <Button type="primary" onClick={DelMore} danger>删除</Button>
                </div>
                <div className={style.search}>
                    <Dropdown overlay={CreatAntdMenu(searchEducationDropdown, getLevel)} placement="bottomCenter">
                        <Button>{Level}<DownOutlined/></Button>
                    </Dropdown>
                    <Input spellCheck={false}
                           value={searchTitle}
                           allowClear
                           onChange={changeSearchTitle}
                           style={{margin: "0 10px", width: "300px"}}/>
                    <Button style={{margin: "0 10px"}} onClick={SearchHandler} type="primary">搜索</Button>
                </div>
            </div>
            <div className={style.Table}>
                <Table dataSource={MarketEducation}
                       pagination={{
                           position: ["bottomLeft"],
                           current: currentPage,
                           pageSize: PageSize,
                           total: size,
                           onChange: changePage
                       }}
                       rowSelection={{...rowSelection}}>
                    {MarketEducationTable.map((item) => {
                            return <Column title={item.title} dataIndex={item.key} key={item.key}
                                           render={(text: string, recode: MarketEducationReduxType, index: number) => {
                                               if (isNullOrUndefined(item.SpaceOption)) {
                                                   if (item.key === "SerialNumber") {
                                                       return index + (currentPage - 1) * PageSize + 1
                                                   }
                                                   return text
                                               }
                                               return <Space size="middle">
                                                   {item.SpaceOption!.map(item => {
                                                       return <Button type="primary" key={item.key}
                                                                      onClick={(e) => {
                                                                          operationClickHandle(e, item, recode)
                                                                      }}>{item.buttonTitle}</Button>
                                                   })}
                                               </Space>
                                           }}/>
                        }
                    )}

                </Table>
            </div>
            <Dialog OptionType={OptionType} ModuleVisible={visible}
                    setModuleVisible={setVisible}/>
        </div>
    )
}
export default MarketEducation
