import React, { useEffect } from 'react'
import style from './index.module.scss'
import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const autofacePNG = require('@/assets/img/software/autoface.png').default

interface IProps {}

let initData = 0
const AutoFace = (props: IProps) => {
  const Autoface = useRootStore(
    (state) => state.OperationPersonal.recent.soft.Autoface
  )
  const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Autoface',
      payload: { count: Number(e.target.value) },
    })
  }
  const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Autoface',
      payload: { gift: Number(e.target.value) },
    })
  }
  const Autoface_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Autoface',
      payload: { count: e.target.checked ? -1 : initData },
    })
  }
  const onChangeCustomTemplate = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Autoface',
      payload: { custom_template: value ? 1 : 0 },
    })
  }
  const onChangeDefaultTemplate = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Autoface',
      payload: { default_template: value ? 1 : 0 },
    })
  }
  useEffect(() => {
    if (Autoface.count === -1) return
    initData = Autoface.count
  }, [])
  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={autofacePNG} alt="" />
        <div className={style.title}>Autoface权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineName}>每月可用总个数:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Autoface.count === -1 ? '无限' : Autoface.count}
              onChange={changeDay}
              spellCheck={false}
              suffix="个"
            />
            <Checkbox
              checked={Autoface.count === -1}
              onChange={Autoface_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>赠送:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Autoface.gift}
              onChange={changeGift}
              disabled={Autoface.count === -1}
              spellCheck={false}
              suffix="个"
            />
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>52BS，129BS模板：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Autoface.default_template)}
              onChange={onChangeDefaultTemplate}
            />
          </div>
          <div className={style.lineName}>自定义模板：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Autoface.custom_template)}
              onChange={onChangeCustomTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AutoFace
