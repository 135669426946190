import {TranslateType} from "@/interface";
import {symbolType} from "@/interface/tableType";

type UserInfoMap = {
    "replace_del_array": { Del_Key: string, symbol: symbolType }
    "replace_del": { Del_Key: string, symbol: symbolType }[]
    "clear_del_array": []
}

let init: { Del_Key: string, symbol: symbolType }[] = []

const reducer = (dataInit = init, data: TranslateType<UserInfoMap>): { Del_Key: string, symbol: symbolType }[] => {
    switch (data.type) {
        case "replace_del_array":
            return [...dataInit, data.payload]
        case "replace_del":
            return data.payload
        case "clear_del_array":
            return data.payload
        default:
            return [...dataInit]
    }
}
export default reducer
