import axios, { AxiosPromise } from 'axios'
import { APP_ID, getTimesTamps, objKeySort } from '@/utils/util'
import { Token, Url } from './HttpSetting'
import md5 from 'js-md5'
import {
  expire_info_interface,
  grade_list_interface,
  Login_interface,
  login_set_interface,
  Login_setting_interface,
  soft_interface,
} from '@/interface/phpInterface'
import { PageSize } from '@/UISetting/DropdownSetting'
import { OperationType } from '@/interface/EnumList'
import { GlobalState } from '@/store'

//登录
export const loginApi = (
  name: string,
  pass: string
): AxiosPromise<Login_interface> => {
  let getParams = {
    name,
    pass,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/login`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//设置登陆限制
export const login_setApi = (
  account: number,
  device: number,
  type: number
): AxiosPromise<login_set_interface> => {
  let getParams = {
    token: Token || GlobalState.userInfo.token,
    account,
    device,
    type,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/login_set`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//获取登录限制内容
export const login_settingApi = (
  type: number
): AxiosPromise<Login_setting_interface> => {
  let getParams = {
    token: Token || GlobalState.userInfo.token,
    type,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/login_setting`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//获取个人会员到期提醒内容
export const expire_infoApi = (): AxiosPromise<expire_info_interface> => {
  let getParams = {
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/expire_info`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//个人会员等级列表
export const grade_listApi = (
  page: number
): AxiosPromise<grade_list_interface> => {
  let getParams = {
    token: Token || GlobalState.userInfo.token,
    page,
    per_page: PageSize,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/grade_list`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//个人会员等级提醒设置
export const expireApi = (
  day: number,
  notify_content: string
): AxiosPromise<expire_info_interface> => {
  let getParams = {
    notify_content,
    day,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/expire`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//校企会员等级列表
export const enterprise_listApi = (
  page: number,
  type: number
): AxiosPromise<any> => {
  let getParams = {
    per_page: PageSize,
    page,
    token: Token || GlobalState.userInfo.token,
    type,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/enterprise_list`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//获取会员等级信息（包括校企）
export const grade_infoApi = (id: string): AxiosPromise<any> => {
  let getParams = {
    id,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/grade_info`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//获取软件及软件图标
export const softApi = (): AxiosPromise<soft_interface> => {
  let getParams = {
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/soft`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//会员等级删除（包括校企）运营
export const grade_delApi = (id: string): AxiosPromise<login_set_interface> => {
  let getParams = {
    id,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/grade_del`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//账户删除（校企账户）市场
export const market_grade_delApi = (
  id: string
): AxiosPromise<login_set_interface> => {
  let getParams = {
    id,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/market/index/account_del`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//校企会员等级添加(企业)
export const enterprise_gradeApi = (
  data: object
): AxiosPromise<login_set_interface> => {
  let getParams = {
    ...data,
    type: OperationType.enterprise,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/enterprise_grade`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//校企会员等级添加（校园）
export const education_gradeApi = (
  name: string,
  image: string,
  user_min: number,
  user_max: number,
  market_edit: number,
  config: string,
  lifelong_price: number
): AxiosPromise<login_set_interface> => {
  let getParams = {
    name,
    image,
    user_min,
    user_max,
    market_edit,
    config,
    lifelong_price,
    type: OperationType.education,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/enterprise_grade`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//校企会员等级编辑(企业)
export const enterprise_editApi = (
  data: object
): AxiosPromise<login_set_interface> => {
  let getParams = {
    ...data,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/enterprise_edit`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//校企会员等级编辑（校园）
export const education_editApi = (
  id: number,
  name: string,
  image: string,
  user_min: number,
  user_max: number,
  market_edit: number,
  config: string,
  lifelong_price: number
): AxiosPromise<login_set_interface> => {
  let getParams = {
    id,
    name,
    image,
    user_min,
    user_max,
    market_edit,
    config,
    lifelong_price,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/enterprise_edit`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//个人会员等级添加
export const grade_addApi = (
  data: object
): AxiosPromise<login_set_interface> => {
  let getParams = {
    ...data,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  console.log(objKeySort(getParams),'签名');
  return axios({
    url: `${Url}/api.php/operation/index/grade_add`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
//个人会员等级添加
export const grade_editApi = (
  data: object
): AxiosPromise<login_set_interface> => {
  let getParams = {
    ...data,
    token: Token || GlobalState.userInfo.token,
    timestamp: getTimesTamps(),
  }
  return axios({
    url: `${Url}/api.php/operation/index/grade_edit`,
    method: 'post',
    data: {
      appid: APP_ID,
      sign: md5(objKeySort(getParams)),
      ...getParams,
    },
  })
}
