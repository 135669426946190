import React, { useEffect } from 'react'
import style from './index.module.scss'

import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const retargeterPNG = require('@/assets/img/software/retargeter.png').default

interface IProps {}

let initData = 0
const Retargeter = (props: IProps) => {
  const Retargeter = useRootStore(
    (state) => state.OperationPersonal_Add.recent.soft.Retargeter
  )
  const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Retargeter_Add',
      payload: { frame: Number(e.target.value) },
    })
  }
  const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Retargeter_Add',
      payload: { gift: Number(e.target.value) },
    })
  }
  const Retargeter_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Retargeter_Add',
      payload: { frame: e.target.checked ? -1 : initData },
    })
  }
  const onChangeKeyframeSolve = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Retargeter_Add',
      payload: { keyframe_solve: value ? 1 : 0 },
    })
  }
  const onChangeExpressionLibrarySolve = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Retargeter_Add',
      payload: { expression_library_solve: value ? 1 : 0 },
    })
  }
  useEffect(() => {
    if (Retargeter.frame === -1) return
    initData = Retargeter.frame
  }, [])
  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={retargeterPNG} alt="" />
        <div className={style.title}>Retargeter权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineName}>关键帧解算：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Retargeter.keyframe_solve)}
              onChange={onChangeKeyframeSolve}
            />
          </div>
          <div className={style.lineName}>表情库解算：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Retargeter.expression_library_solve)}
              onChange={onChangeExpressionLibrarySolve}
            />
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>每月可用总帧数:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Retargeter.frame === -1 ? '无限' : Retargeter.frame}
              onChange={changeDay}
              spellCheck={false}
              suffix="帧"
            />
            <Checkbox
              checked={Retargeter.frame === -1}
              onChange={Retargeter_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>赠送:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Retargeter.gift}
              onChange={changeGift}
              disabled={Retargeter.frame === -1}
              spellCheck={false}
              suffix="帧"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Retargeter
