import React, {useEffect, useState} from 'react';
import {GlobalDispatch, useRootStore} from "@/store";
import PublicStyle from "../index.module.scss"
import {Button, DatePicker, Dropdown, Input, message} from "antd";
import SoftwareControl from "@/components/MarketSoftwareControl";
import {DownOutlined} from "@ant-design/icons";
import CreatAntdMenu from "@/utils/creatAntdUI";
import moment from "moment";
import {isNullOrUndefined} from "@/utils/util";
import {account_softApi} from "@/api/MarketApi";


interface IProp {
}

const MarketEducationModal = (props: IProp) => {
    const MarketEducation = useRootStore(state => state.MarketEducation.recent)
    const EducationDropdown = useRootStore(state => state.DropdownTitle.EducationDropdown)
    const MarketAccount_soft = useRootStore(state => state.MarketAccount_soft)
    const [DropOption, setDropOption] = useState<string>("")
    const onChange = (value: any, dateString: any) => {
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {company_upgrade_date: dateString}})
    }

    const getDropdownOption = async (name: { grade_id: number, grade_name: string }) => {
        GlobalDispatch({type: "replace_Recent_MarketEducation_soft_init", payload: {}})
        let account_soft = await account_softApi("0", name.grade_id)
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {grade_id: name.grade_id}})
        if (!isNullOrUndefined(account_soft.data.data)) {
            GlobalDispatch({type: "replace_Account_soft", payload: account_soft.data.data})
            GlobalDispatch({
                type: "replace_Recent_MarketEducation_Account_soft_to_soft_config",
                payload: account_soft.data.data
            })
        } else {
            GlobalDispatch({type: "clear_Account_soft", payload: []})
        }
        setDropOption(name.grade_name)
    }
    const changeCompany_title = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {company_title: e.target.value}})
    }
    const changeCompany_name = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {company_name: e.target.value}})
    }
    const changeCompany_show_pass = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {company_show_pass: e.target.value}})
    }
    const changeCompany_account_num = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_MarketEducation_option", payload: {company_account_num: e.target.value}})
    }
    useEffect(() => {
        if (MarketEducation.grade_id === 0) {
            setDropOption('请选择')
            return
        }
        setDropOption(EducationDropdown.find(item => item.grade_id === MarketEducation.grade_id)!.grade_name)
        console.log('DropOption===',DropOption)
    }, [EducationDropdown, MarketEducation])
    return (<div className={PublicStyle.Box}>
        <div className={PublicStyle.title}>基础设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>机构名称：</span><Input
                value={MarketEducation.company_title} onChange={changeCompany_title} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>登录账号：</span><Input
                value={MarketEducation.company_name} onChange={changeCompany_name} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>登录密码：</span><Input
                value={MarketEducation.company_show_pass} onChange={changeCompany_show_pass} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>账号数量：</span>
                <Input style={{width: "45%"}} value={MarketEducation.company_account_num}
                       onChange={changeCompany_account_num} spellCheck={false} suffix="个"/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>账户类型：</span>
                <Dropdown overlay={CreatAntdMenu(EducationDropdown, getDropdownOption)} placement="bottomCenter">
                    <Button>{DropOption}<DownOutlined/></Button>
                </Dropdown>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>截至日期：</span>
                <DatePicker defaultValue={
                    moment(isNullOrUndefined(MarketEducation.company_upgrade_date) ? moment().format("YYYY-MM-DD") : MarketEducation.company_upgrade_date, 'YYYY-MM-DD')
                }
                            onChange={onChange}/>
            </div>
        </div>
        {isNullOrUndefined(MarketAccount_soft) ? <div className={PublicStyle.title}>软件权限</div> : null}
        {isNullOrUndefined(MarketAccount_soft) ?
            null :
            <div className={PublicStyle.infoBox}>
                <SoftwareControl recentName={"MarketEducation"}/>
            </div>}
    </div>)
}
export default MarketEducationModal
