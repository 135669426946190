import React, {useEffect} from 'react'
import style from './index.module.scss'

import {Checkbox, Input, message,Switch} from "antd";
import {GlobalDispatch, useRootStore} from "@/store";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const batchrobotPNG = require("@/assets/img/software/batchrobot.png").default

interface IProps {

}

let initData = 0
const Batchrobot = (props: IProps) => {
    const Batchrobot = useRootStore(state => state.OperationPersonal_Add.recent.soft.Batchrobot)
    const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Batchrobot_Add", payload: {count: Number(e.target.value)}})
    }
    const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Batchrobot_Add", payload: {gift: Number(e.target.value)}})
    }
    const Batchrobot_frame_Checkbox = (e: CheckboxChangeEvent) => {
        GlobalDispatch({type: "change_Recent_Batchrobot_Add", payload: {count: e.target.checked ? -1 : initData}})
    }
    const onChangeTrackerBatchProcessing = (value: boolean) => {
      GlobalDispatch({type: "change_Recent_Batchrobot_Add", payload: {tracker_batch_processing : value ? 1 : 0}})
  }
  const onChangeRetargeterBatchProcessing = (value: boolean) => {
    GlobalDispatch({type: "change_Recent_Batchrobot_Add", payload: {retargeter_batch_processing: value ? 1 : 0}})
  }
    useEffect(() => {
        if (Batchrobot.count === -1) return
        initData = Batchrobot.count
    }, [])
    return (
        <div className={style.softwareContainer}>
            <div className={style.imgAndTitle}>
                <img src={batchrobotPNG} alt="" className={style.imgWidth}/>
                <div className={style.title}>Batchrobot权限</div>
            </div>
            <div className={style.content}>
            <div className={style.Line}>
          <div className={style.lineName}>Tracker批处理：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Batchrobot.tracker_batch_processing)}
              onChange={onChangeTrackerBatchProcessing}
            />
          </div>
          <div className={style.lineName}>Retargeter批处理：</div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Batchrobot.retargeter_batch_processing)}
              onChange={onChangeRetargeterBatchProcessing}
            />
          </div>
        </div>
                <div className={style.Line}>
                    <div className={style.lineName}>每月可用总个数:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Batchrobot.count === -1 ? "无限" : Batchrobot.count}
                               onChange={changeDay}
                               spellCheck={false} suffix="个"/>
                        <Checkbox checked={Batchrobot.count === -1}
                                  onChange={Batchrobot_frame_Checkbox}>无限制</Checkbox>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>赠送:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Batchrobot.gift}
                               onChange={changeGift}
                               disabled={Batchrobot.count === -1}
                               spellCheck={false} suffix="个"/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Batchrobot
