import {combineReducers, createStore} from "redux"
import {TypedUseSelectorHook, useSelector} from 'react-redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import MarketEnterprise from "./MarketEnterprise/reducerer"
import MarketEducation from "./MarketEducation/reducerer"
import MarketEnterprise_Add from "./MarketEnterprise_Add/reducerer"
import MarketEducation_Add from "./MarketEducation_Add/reducerer"
import OperationEducation from "./OperationEducation/reducerer"
import OperationEducation_Add from "./OperationEducation_Add/reducerer"
import OperationEnterprise from "./OperationEnterprise/reducerer"
import OperationEnterprise_Add from "./OperationEnterprise_Add/reducerer"
import OperationPersonal from "./OperationPersonal/reducerer"
import OperationPersonal_Add from "./OperationPersonal_Add/reducerer"
import userInfo from "./userInfo/reducerer"
import software from "./software/reducerer"
import LoginSetting from "./LoginSetting/reducerer"
import DropdownTitle from "./DropdownTitle/reducerer"
import Del from "./Del/reducerer"
import Delay from "./Delay/reducerer"
import PageSize from "./PageSize/reducerer"
import MarketAccount_soft from "./MarketAccount_soft/reducerer"

let ReduxMap = {
    MarketEnterprise,
    MarketEducation,
    MarketEnterprise_Add,
    MarketEducation_Add,
    OperationEducation,
    OperationEducation_Add,
    OperationEnterprise,
    OperationEnterprise_Add,
    OperationPersonal,
    OperationPersonal_Add,
    software,
    LoginSetting,
    Delay,
    userInfo,
    DropdownTitle,
    MarketAccount_soft,
    PageSize,
    Del
}
let OperationRecentMap = {
    OperationEducation,
    OperationEnterprise,
    OperationPersonal,
    OperationEnterprise_Add,
    OperationEducation_Add,
    OperationPersonal_Add,
}
let MarketRecentMap = {
    MarketEnterprise,
    MarketEducation,
    MarketEnterprise_Add,
    MarketEducation_Add,
}
export type OperationMap = keyof typeof OperationRecentMap
export type MarketMap = keyof typeof MarketRecentMap

let reducers = combineReducers(ReduxMap)
const storageConfig = {
    key: 'root', // 必须有的
    storage, // 缓存机制
}


const persistedReducer = persistReducer(storageConfig, reducers);

const store = createStore(persistedReducer)

const persistor = persistStore(store as any)

type stateType = ReturnType<typeof reducers>

let GlobalState: stateType

//监听拿redux的值，不能做成相应式，方法里可以用，页面不能用
store.subscribe(() => {
    GlobalState = store.getState()
})

//使用react-redux封装的createSelectorHook方法,页面方法都可以用
// const useRootStore = createSelectorHook<stateType>()

//使用react-redux封装的TypedUseSelectorHook方法,页面方法都可以用
const useRootStore: TypedUseSelectorHook<stateType> = useSelector

//使用ts封装方法,页面方法都可以用
// function useRootStore<T = unknown>(fn: (state: stateType) => T) {
//     return useSelector(fn)
// }

const GlobalDispatch = store.dispatch


export {
    store,
    persistor,
    GlobalDispatch,
    useRootStore,
    GlobalState
}


