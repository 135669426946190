import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import style from './index.module.scss'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {Button, Checkbox, Input, message} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {isNullOrUndefined} from "@/utils/util";
import {loginApi} from "@/api/OperationApi";
import {GlobalDispatch} from "@/store";

let isFirstLogin = true

interface IProps {

}


const Login = (props: IProps) => {
    let history = useHistory();
    const [userName, setUserName] = useState(JSON.parse(localStorage.getItem("account")!))
    const [password, setPassword] = useState(JSON.parse(localStorage.getItem("password")!))
    const [RememberPassword, setRememberPassword] = useState(JSON.parse(localStorage.getItem("RememberPassword")!))
    const [AutomaticLogon, setAutomaticLogon] = useState(JSON.parse(localStorage.getItem("AutomaticLogon")!))

    const LoginInitOption = [
        {id: "1", key: "RememberPassword", checked: RememberPassword, title: "记住密码"},
        {id: "2", key: "AutomaticLogon", checked: AutomaticLogon, title: "自动登录"}
    ]

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(e.target.value)
    }
    const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }
    const login = async (name: string, pass: string) => {
        let res = await loginApi(name, pass)
        if (res.data.code === 200) {
            GlobalDispatch({type: "SAVE_TOKEN_AND_TYPE_WITH_NAME", payload: {...res.data.data, name}})
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            localStorage.setItem("RememberPassword", JSON.stringify(RememberPassword));
            localStorage.setItem("AutomaticLogon", JSON.stringify(AutomaticLogon));
            localStorage.setItem("account", JSON.stringify(name));
            localStorage.setItem("password", JSON.stringify(pass));
            isFirstLogin = false
            history.push("/home")
        } else {
            message.error(res.data.msg);
        }
    }
    const onChange = (e: CheckboxChangeEvent, id: string) => {
        if (id === "1") {
            setRememberPassword(e.target.checked)
        }
        if (id === "2") {
            setAutomaticLogon(e.target.checked)
        }
    }
    const changeChose = useCallback(() => {
        if (isNullOrUndefined(RememberPassword) && isNullOrUndefined(AutomaticLogon)) return
        setRememberPassword(RememberPassword)
        setAutomaticLogon(AutomaticLogon)
        if (RememberPassword) {
            setUserName(userName)
            setPassword(password)
        }
        if (AutomaticLogon) {
            if (!isFirstLogin) return;
            login(userName, password).then(_ => {
            })
        }
    }, [history])

    useEffect(() => {
            changeChose()
        }, [changeChose]
    )
    return (
        <div className={style.container}>
            <div className={style.pageLogo}/>
            <div className={style.Login}>
                <div className={style.LoginInput}>
                    <div className={style.LoginText}>FACEGOOD后台</div>
                    <div className={style.InputOption}>
                        <Input placeholder="请输入用户名" spellCheck={false} value={userName} onChange={changeName}/>
                    </div>
                    <div className={style.InputOption}>
                        <Input.Password placeholder="请输入密码"
                                        value={password}
                                        onChange={changePassword}
                                        iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}/>
                    </div>
                    <div className={style.LoginOption}>
                        {LoginInitOption.map(item => <Checkbox key={item.title}
                                                               checked={item.checked}
                                                               onChange={(e) => onChange(e, item.id)}>{item.title}</Checkbox>)}
                    </div>
                    <Button type="primary" onClick={() => {
                        login(userName, password).then(_ => {
                        })
                    }}>登录</Button>
                </div>
            </div>
            <div className={style.foot}>
                <div className={style.footOption}>量子动力（深圳）计算机科技有限公司</div>
                <div className={style.footOption}>粤ICP备17025206号</div>
            </div>
        </div>
    )

}

export default Login
