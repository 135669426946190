import React from 'react';
import {DatePicker} from "antd";
import moment from "moment";
import style from "./index.module.scss";
import PublicStyle from "@/components/Dialog/DialogTypeComponent/index.module.scss";
import {GlobalDispatch} from "@/store";

const RangePicker:any= DatePicker.RangePicker;

interface IProp {
}

const DelayModal = (props: IProp) => {
    const disabledDate = (current: any) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    const onChange = (value: any, dateString: any) => {
        GlobalDispatch({
            type: "change_delay",
            payload: {effect_date: dateString[0]}
        })
        GlobalDispatch({type: "change_delay", payload: {usage_date: dateString[1]}})
    }
    return (
        <div className={PublicStyle.Box}>
            <div className={style.title}>输入延期时间</div>
            <div className={style.choseTime}>
                <RangePicker
                    defaultValue={[
                        moment(moment().format("YYYY-MM-DD")),
                        moment(moment().format("YYYY-MM-DD"))
                    ]}
                    disabledDate={disabledDate}
                    placeholder={["开始日期", "截至日期"]} format="YYYY-MM-DD"
                    onChange={onChange}/>
            </div>
        </div>
    )
}
export default DelayModal
