import {TranslateType} from "@/interface";

type UserInfoMap = {
    "replace_page_size": { MarketEducation: number } | { MarketEnterprise: number } | { OperationEducation: number } | { OperationEnterprise: number } | { OperationPersonal: number }
    "clear_page_size": {}
}

let init: {
    MarketEducation: number,
    MarketEnterprise: number,
    OperationEducation: number,
    OperationEnterprise: number,
    OperationPersonal: number
} = {
    MarketEducation: 0,
    MarketEnterprise: 0,
    OperationEducation: 0,
    OperationEnterprise: 0,
    OperationPersonal: 0,
}

const reducer = (dataInit = init, data: TranslateType<UserInfoMap>): {
    MarketEducation: number,
    MarketEnterprise: number,
    OperationEducation: number,
    OperationEnterprise: number,
    OperationPersonal: number
} => {
  // console.log('============================数据类型：' + data.type + '============================')
    // switch (data.type) {
        // case "replace_page_size":
            return {...dataInit, ...data.payload}
        // case "clear_page_size":
        //     return {
        //         MarketEducation: 0,
        //         MarketEnterprise: 0,
        //         OperationEducation: 0,
        //         OperationEnterprise: 0,
        //         OperationPersonal: 0,
        //     }
        // default:
        //     return {
        //         MarketEducation: 0,
        //         MarketEnterprise: 0,
        //         OperationEducation: 0,
        //         OperationEnterprise: 0,
        //         OperationPersonal: 0,
        //     }
        // 
        
    }
export default reducer
