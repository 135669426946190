import {TableType} from "@/interface/tableType";

const marketEnterprise: TableType[] = [
    {
        title: "序号",
        key: "SerialNumber",
    }, {
        title: "客户名称",
        key: "CustomerName",
    }, {
        title: "账户数量",
        key: "NumberOfAccounts",
    }, {
        title: "SaaS账户/密码",
        key: "SaaSAccount",
    }, {
        title: "到期时间",
        key: "ExpirationTime",
    }, {
        title: "账户权限",
        key: "AccountAuthority",
    }, {
        title: "市场单价",
        key: "MarketUnitPrice",
    }, {
        title: "折扣单价",
        key: "DiscountUnitPrice",
    }, {
        title: "状态",
        key: "state",
    }, {
        title: "企业等级",
        key: "EnterpriseLevel",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "编辑", title: "市场端企业编辑", key: "MarketEnterpriseModal", symbol: "MarketEnterprise"},
            {buttonTitle: "删除", title: "删除", key: "DeleteModal", symbol: "MarketEnterprise"},
            {
                buttonTitle: "延期",
                title: "延期",
                key: "DelayModal", symbol: "MarketEnterprise"
            }
        ]
    },
]
export default marketEnterprise
