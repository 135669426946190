import React, { useEffect } from 'react'
import style from './index.module.scss'

import { Checkbox, Input, message, Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const SeattleaitrainerPNG =
  require('@/assets/img/software/Seattlepro-p.png').default

interface IProps {}

let offlineInitData = 0,
  onloneInitData = 0

const Seattleaitrainer = (props: IProps) => {
  const Seattleaitrainer = useRootStore(
    (state) => state.OperationPersonal.recent.soft.Seattleaitrainer
  )
  const onChangeExport = (value: boolean) => {
    GlobalDispatch({
      type: 'change_Recent_Seattleaitrainer',
      payload: { file_export: value ? 1 : 0 },
    })
  }

  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={SeattleaitrainerPNG} alt="" className={style.imgWidth} />
        <div className={style.title}>Seattleaitrainer权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineFristName}>
            SeattlePro Retargeter文件导出权限：
          </div>
          <div className={style.chosePart}>
            <Switch
              checked={Boolean(Seattleaitrainer.file_export)}
              onChange={onChangeExport}
            />
          </div>
        </div>
        {/* <div className={style.Line} style={{"display":Boolean(Seattlepro.offline)?"flex":"none"}}>
                  <div className={style.lineName}>帧数：</div>
                  <div className={style.chosePart}>
                     <Input style={{width: "30%", margin: "0 10px"}}
                         value={Seattlepro.offline_frame === -1 ? "无限" : Seattlepro.offline_frame}
                         onChange={changeOfflineFrame}
                         spellCheck={false} suffix="帧"/>
                     <Checkbox checked={Seattlepro.offline_frame === -1}
                            onChange={Seattlepro_frame_Checkbox}>无限制</Checkbox>
                  </div>
              </div>
              <div className={style.Line}>
                  <div className={style.lineFristName}>SeattlePro实时计算：</div>
                  <div className={style.chosePart}>
                      <Switch checked={Boolean(Seattlepro.online)} onChange={onChangeOnline}/>
                  </div>
              </div>
              <div className={style.Line} style={{"display":Boolean(Seattlepro.online)?"flex":"none"}}>
                  <div className={style.lineName}>时长：</div>
                  <div className={style.chosePart}>
                      <Input style={{width: "30%", margin: "0 10px"}}
                             value={Seattlepro.online_time === -1 ? "无限" : Seattlepro.online_time}
                             onChange={changeOnlineTime}
                             spellCheck={false} suffix="分钟"/>
                      <Checkbox checked={Seattlepro.online_time === -1}
                                onChange={Seattlepro_time_Checkbox}>无限制</Checkbox>
                  </div>
              </div>
              <div className={style.Line}>
                  <div className={style.lineFristName}>Fgmodeledit修改开关：</div>
                  <div className={style.chosePart}>
                      <Switch checked={Boolean(Seattlepro.fgmodeledit)} onChange={onChangeEditFgmodeledit}/>
                  </div>
              </div> */}
      </div>
    </div>
  )
}

export default Seattleaitrainer
