import {TableType} from "@/interface/tableType";

const MarketEducationTable: TableType[] = [
    {
        title: "序号",
        key: "SerialNumber",
    }, {
        title: "客户名称",
        key: "CustomerName",
    }, {
        title: "账户数量",
        key: "NumberOfAccounts",
    }, {
        title: "SaaS账户/密码",
        key: "SaaSAccount",
    }, {
        title: "账户权限",
        key: "AccountAuthority",
    }, {
        title: "等级类型",
        key: "LevelType",
    }, {
        title: "市场单价",
        key: "MarketUnitPrice",
    }, {
        title: "折扣单价",
        key: "DiscountUnitPrice",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "编辑", title: "市场端教育编辑", key: "MarketEducationModal", symbol: "MarketEducation"},
            {buttonTitle: "删除", title: "删除", key: "DeleteModal", symbol: "MarketEducation"}
        ]
    },
]
export default MarketEducationTable
