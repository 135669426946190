import { TranslateType } from '@/interface'
import {
  A_OperationEducationReduxType,
  OperationEducationRecent,
  OperationEducationReduxType,
} from '@/interface/recentType'

type recentDataMap = {
  replace_allData_OperationEducation_Add: OperationEducationReduxType[]
  replace_Recent_OperationEducation_Add: OperationEducationRecent
  change_Recent_OperationEducation_option_Add:
    | { grade_id: number }
    | { grade_image: string }
    | { grade_name: string }
    | { grade_type: number }
  change_Recent_grade_setting_OperationEducation_option_Add:
    | { lifelong_price: number }
    | { user_min: number }
    | { user_max: number }
    | { market_edit: number }
  change_Recent_OperationEducation_soft_option_Add: {
    [key: string]: { soft_on: number; limit_setting: {} }
  }
  clear_Recent_OperationEducation_data_Add: {}
}

let init: A_OperationEducationReduxType = {
  // current_page:1,
  allData: [],
  recent: {
    grade_id: 0,
    grade_image: '',
    grade_name: '',
    grade_setting: {
      lifelong_price: 0,
      user_min: 0,
      user_max: 0,
      market_edit: 0,
    },
    grade_type: 0,
    soft: {
      Autoface: {
        soft_on: 0,
        default_template: 0,
        custom_template: 0,
        limit_setting: {},
      },
      Livedrive: { soft_on: 0, limit_setting: {} },
      Muscledrive: { soft_on: 0, limit_setting: {} },
      Retargeter: {
        soft_on: 0,
        keyframe_solve: 0,
        expression_library_solve: 0,
        limit_setting: {},
      },
      Rigger: {
        soft_on: 0,
        arkit_template: 0,
        emily_template: 0,
        limit_setting: {},
      },
      Streamer: { soft_on: 0, limit_setting: {} },
      Tracker: {
        soft_on: 0,
        ai01: 0,
        ai02: 0,
        ai03: 0,
        ai04: 0,
        ai_library: 0,
        ai_template: 0,
        limit_setting: {},
      },
      Batchrobot: {
        soft_on: 0,
        tracker_batch_processing: 0,
        retargeter_batch_processing: 0,
        limit_setting: {},
      },
      Seattlepro: { soft_on: 0, limit_setting: {} },
      Seattleaitrainer: { soft_on: 0, limit_setting: {} },
      Fgmodeledit: { soft_on: 0, limit_setting: {} },
      Riggermeta: { soft_on: 0, limit_setting: {} },
      Posemakerposeasset: { soft_on: 0, limit_setting: {} },
      Posemakerfbx: { soft_on: 0, limit_setting: {} },
      Retargeterofflinearkit: { soft_on: 0, limit_setting: {} },
      Retargeterofflinemeta: { soft_on: 0, limit_setting: {} },
      Retargeterofflineposeasset: { soft_on: 0, limit_setting: {} },
      Retargeterseattleaiofflinecustom: { soft_on: 0, limit_setting: {} },
      Livedriverealtimemeta: { soft_on: 0, limit_setting: {} },
      Livedriverealtimeposeasset: { soft_on: 0, limit_setting: {} },
      Livedriverealtimecustom: { soft_on: 0, limit_setting: {} },
      Batchrobotarkit: { soft_on: 0, limit_setting: {} },
      Batchrobotmeta: { soft_on: 0, limit_setting: {} },
      Batchrobotposeasset: { soft_on: 0, limit_setting: {} },
      Batchrobotcustom: { soft_on: 0, limit_setting: {} },
    },
  },
}

const reducer = (
  dataInit = init,
  data: TranslateType<recentDataMap>
): A_OperationEducationReduxType => {
  switch (data.type) {
    case 'replace_allData_OperationEducation_Add':
      return {
        ...dataInit,
        allData: data.payload,
      }
    case 'replace_Recent_OperationEducation_Add':
      return { ...dataInit, recent: data.payload }
    case 'change_Recent_OperationEducation_option_Add':
      return { ...dataInit, recent: { ...dataInit.recent, ...data.payload } }
    case 'change_Recent_grade_setting_OperationEducation_option_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          grade_setting: { ...dataInit.recent.grade_setting, ...data.payload },
        },
      }
    case 'change_Recent_OperationEducation_soft_option_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            ...data.payload,
          },
        },
      }
    case 'clear_Recent_OperationEducation_data_Add':
      return {
        // current_page:1,
        allData: dataInit.allData,
        recent: {
          grade_id: 0,
          grade_image: '',
          grade_name: '',
          grade_setting: {
            lifelong_price: 0,
            user_min: 0,
            user_max: 0,
            market_edit: 0,
          },
          grade_type: 0,
          soft: {
            Autoface: {
              soft_on: 0,
              default_template: 0,
              custom_template: 0,
              limit_setting: {},
            },
            Livedrive: { soft_on: 0, limit_setting: {} },
            Muscledrive: { soft_on: 0, limit_setting: {} },
            Retargeter: {
              soft_on: 0,
              keyframe_solve: 0,
              expression_library_solve: 0,
              limit_setting: {},
            },
            Rigger: {
              soft_on: 0,
              arkit_template: 0,
              emily_template: 0,
              limit_setting: {},
            },
            Streamer: { soft_on: 0, limit_setting: {} },
            Tracker: {
              soft_on: 0,
              ai01: 0,
              ai02: 0,
              ai03: 0,
              ai04: 0,
              ai_library: 0,
              ai_template: 0,
              limit_setting: {},
            },
            Batchrobot: {
              soft_on: 0,
              tracker_batch_processing: 0,
              retargeter_batch_processing: 0,
              limit_setting: {},
            },
            Seattlepro: { soft_on: 0, limit_setting: {} },
            Seattleaitrainer: { soft_on: 0, limit_setting: {} },
            Fgmodeledit: { soft_on: 0, limit_setting: {} },
            Riggermeta: { soft_on: 0, limit_setting: {} },
            Posemakerposeasset: { soft_on: 0, limit_setting: {} },
            Posemakerfbx: { soft_on: 0, limit_setting: {} },
            Retargeterofflinearkit: { soft_on: 0, limit_setting: {} },
            Retargeterofflinemeta: { soft_on: 0, limit_setting: {} },
            Retargeterofflineposeasset: { soft_on: 0, limit_setting: {} },
            Retargeterseattleaiofflinecustom: { soft_on: 0, limit_setting: {} },
            Livedriverealtimemeta: { soft_on: 0, limit_setting: {} },
            Livedriverealtimeposeasset: { soft_on: 0, limit_setting: {} },
            Livedriverealtimecustom: { soft_on: 0, limit_setting: {} },
            Batchrobotarkit: { soft_on: 0, limit_setting: {} },
            Batchrobotmeta: { soft_on: 0, limit_setting: {} },
            Batchrobotposeasset: { soft_on: 0, limit_setting: {} },
            Batchrobotcustom: { soft_on: 0, limit_setting: {} },
          },
        },
      }
    default:
      return { ...dataInit }
  }
}
export default reducer
