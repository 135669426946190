import React from 'react'
import style from './index.module.scss'
import { GlobalDispatch, OperationMap, useRootStore } from '@/store'
import { Switch } from 'antd'
import { softNameMap } from '@/components/SoftwareInfo'

interface IProps {
  recentName: OperationMap
}

const SoftwareControl = ({ recentName }: IProps) => {
  // debugger
  // /index/soft接口返回数据
  const softwareList = useRootStore((state) => state.software)
  const recentData = useRootStore((state) => state[recentName]).recent
  // console.log(recentData.soft, '666')

  // console.log(softwareList[0], '4444444')
  const onChange = (checked: boolean, softwareKey: softNameMap) => {
    if (recentName === 'OperationEducation') {
      GlobalDispatch({
        type: 'change_Recent_OperationEducation_soft_option',
        payload: {
          [softwareKey]: { soft_on: checked ? 1 : 0, limit_setting: {} },
        },
      })
    }
    if (recentName === 'OperationEducation_Add') {
      GlobalDispatch({
        type: 'change_Recent_OperationEducation_soft_option_Add',
        payload: {
          [softwareKey]: { soft_on: checked ? 1 : 0, limit_setting: {} },
        },
      })
    }
    if (recentName === 'OperationPersonal') {
      GlobalDispatch({
        type: 'change_Recent_OperationPersonal_soft_option',
        payload: {
          [softwareKey]: {
            ...recentData.soft[softwareKey],
            soft_on: checked ? 1 : 0,
          },
        },
      })
    }
    if (recentName === 'OperationPersonal_Add') {
      GlobalDispatch({
        type: 'change_Recent_OperationPersonal_soft_option_Add',
        payload: {
          [softwareKey]: {
            ...recentData.soft[softwareKey],
            soft_on: checked ? 1 : 0,
          },
        },
      })
    }
    if (recentName === 'OperationEnterprise') {
      GlobalDispatch({
        type: 'change_Recent_OperationEnterprise_soft_option',
        payload: {
          [softwareKey]: { soft_on: checked ? 1 : 0, limit_setting: {} },
        },
      })
    }
    if (recentName === 'OperationEnterprise_Add') {
      GlobalDispatch({
        type: 'change_Recent_OperationEnterprise_soft_option_Add',
        payload: {
          [softwareKey]: { soft_on: checked ? 1 : 0, limit_setting: {} },
        },
      })
    }
  }
  return (
    <div>
      <div className={style.softBox}>
        {softwareList[0].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            {/* {console.log(recentData.soft[item.service_title].soft_on,'11111')
            } */}
            <Switch
              checkedChildren="on"
              checked={Boolean(recentData.soft[item.service_title].soft_on)}
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[1].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              checked={Boolean(recentData.soft[item.service_title].soft_on)}
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[2].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              checked={Boolean(recentData.soft[item.service_title].soft_on)}
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
      <div className={style.softBox}>
        {softwareList[3].map((item: any) => (
          <div className={style.software} key={item.service_title}>
            <img src={item.service_icon} alt="" />
            <div className={style.title}>{item.service_desc}</div>
            <Switch
              checkedChildren="on"
              checked={Boolean(recentData.soft[item.service_title].soft_on)}
              onChange={(checked) => {
                onChange(checked, item.service_title)
              }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default SoftwareControl
