import { TranslateType } from '@/interface'
import {
  A_OperationPersonalReduxType,
  OperationPersonalRecent,
  OperationPersonalReduxType,
} from '@/interface/recentType'

type recentDataMap = {
  replace_allData_OperationPersonal_Add: OperationPersonalReduxType[]
  replace_Recent_OperationPersonal_Add: OperationPersonalRecent
  change_Recent_OperationPersonal_option_Add:
    | { grade_id: number }
    | { grade_image: string }
    | { grade_tag: string }
    | { grade_param: string }
    | { grade_name: string }
    | { grade_type: number }
  change_Recent_grade_setting_OperationPersonal_option_Add:
    | { user_min: number }
    | { user_max: number }
    | { price_setting: any }
    | { is_show: number }
  // {user_min: number} | {user_max: number} | { price_setting: any }
  //{ grade_setting_value: number, arrayAddress: [number, number] }

  change_Recent_OperationPersonal_soft_option_Add: {
    gift?: number
    frame?: number
    count?: number
    soft_on?: number
    open3d?: number
    capture?: number
    timecode?: number
    multichannel_recording?: number
    seattle_ai_arkit?: number
    usage_time?: number
    algorithm?: string
  }

  change_Recent_Autoface_Add:
    | { count: number }
    | { gift: number }
    | { default_template: number }
    | { custom_template: number }

  change_Recent_Livedrive_Add:
    | { open3d: number }
    | { gift: number }
    | { capture: number }
    | { timecode: number }
    | { seattle_ai_arkit: number }
    | { usage_time: number }
    | { algorithm: string }

  change_Recent_Rigger_Add:
    | { count: number }
    | { gift: number }
    | { arkit_template: number }
    | { emily_template: number }

  change_Recent_Riggermeta_Add: { count: number } | { gift: number }

  change_Recent_Retargeter_Add:
    | { frame: number }
    | { gift: number }
    | { keyframe_solve: number }
    | { expression_library_solve: number }

  change_Recent_Tracker_Add:
    | { frame: number }
    | { gift: number }
    | { ai01: number }
    | { ai02: number }
    | { ai03: number }
    | { ai04: number }
    | { ai_library: number }
    | { ai_template: number }

  change_Recent_Muscledrive_Add: { count: number } | { gift: number }

  change_Recent_Streamer_Add:
    | { timecode: number }
    | { capture: number }
    | { multichannel_recording: number }

  change_Recent_Batchrobot_Add:
    | { count: number }
    | { gift: number }
    | { tracker_batch_processing: number }
    | { retargeter_batch_processing: number }

  change_Recent_Seattlepro_Add:
    | { offline: number }
    | { gift: number }
    | { offline_frame: number }
    | { fgmodeledit: number }
    | { online: number }
    | { online_time: number }
  change_Recent_Seattleaitrainer_Add: { file_export: number }
  change_Recent_Fgmodeledit_Add: {}

  change_Recent_Posemakerfbx_Add: {}

  change_Recent_Posemakerposeasset_Add: {}

  change_Recent_Retargeterofflinearkit_Add: {}

  change_Recent_Retargeterofflinemeta_Add: {}

  change_Recent_Retargeterofflineposeasset_Add: {}

  change_Recent_Retargeterseattleaiofflinecustom_Add: {}

  change_Recent_Livedriverealtimemeta_Add: {}
  change_Recent_Livedriverealtimeposeasset_Add: {}
  change_Recent_Livedriverealtimecustom_Add: {}
  change_Recent_Batchrobotarkit_Add: {}
  change_Recent_Batchrobotmeta_Add: {}
  change_Recent_Batchrobotposeasset_Add: {}
  change_Recent_Batchrobotcustom_Add: {}

  clear_Recent_OperationPersonal_data_Add: {}
}

let init: A_OperationPersonalReduxType = {
  allData: [],
  // currentPage: 1,
  recent: {
    grade_id: 0,
    grade_image: '',
    grade_name: '',
    grade_type: 0,
    price_setting: [],
    is_show: 0,
    grade_tag: '',
    grade_param: '',
    // grade_setting: [
    //     [0, 0, 0, 0],
    //     [0, 0, 0, 0],
    // ],
    soft: {
      Streamer: {
        soft_on: 0,
        timecode: 0,
        capture: 0,
        multichannel_recording: 0,
      },
      Tracker: {
        soft_on: 0,
        frame: 0,
        gift: 0,
        ai01: 0,
        ai02: 0,
        ai03: 0,
        ai04: 0,
        ai_library: 0,
        ai_template: 0,
      },
      Retargeter: {
        soft_on: 0,
        frame: 0,
        gift: 0,
        keyframe_solve: 0,
        expression_library_solve: 0,
      },

      Livedrive: {
        soft_on: 0,
        capture: 0,
        timecode: 0,
        open3d: 0,
        usage_time: 0,
        algorithm: '',
        gift: 0,
        seattle_ai_arkit: 0,
      },
      Autoface: {
        count: 0,
        soft_on: 0,
        gift: 0,
        default_template: 0,
        custom_template: 0,
      },
      Rigger: {
        soft_on: 0,
        count: 0,
        gift: 0,
        arkit_template: 0,
        emily_template: 0,
      },
      Riggermeta: {
        soft_on: 0,
        count: 0,
        gift: 0,
      },
      Muscledrive: {
        soft_on: 0,
        count: 0,
        gift: 0,
      },
      Batchrobot: {
        soft_on: 0,
        count: 0,
        gift: 0,
        tracker_batch_processing: 0,
        retargeter_batch_processing: 0,
      },
      Seattlepro: {
        soft_on: 0,
        gift: 0,
        offline: 0, //1开启0关闭
        offline_frame: 0, // 离线帧数，-1位无限制
        online: 0, //1开启0关闭
        online_time: 0, //时长，-1无限制
        fgmodeledit: 0,
      },
      Seattleaitrainer: {
        soft_on: 0,
        file_export: 0,
      },
      Fgmodeledit: {
        soft_on: 0,
      },
      Seattlelitemetahuman: {
        soft_on: 0,
      },
      Posemakerfbx: {
        soft_on: 0,
      },
      Posemakerposeasset: {
        soft_on: 0,
      },
      Retargeterofflinearkit: {
        soft_on: 0,
      },
      Retargeterofflinemeta: {
        soft_on: 0,
      },
      Retargeterofflineposeasset: {
        soft_on: 0,
      },
      Retargeterseattleaiofflinecustom: {
        soft_on: 0,
      },
      Livedriverealtimemeta: { soft_on: 0 },
      Livedriverealtimeposeasset: { soft_on: 0 },
      Livedriverealtimecustom: { soft_on: 0 },
      Batchrobotarkit: { soft_on: 0 },
      Batchrobotmeta: { soft_on: 0 },
      Batchrobotposeasset: { soft_on: 0 },
      Batchrobotcustom: { soft_on: 0 },
    },
  },
}

const reducer = (
  dataInit = init,
  data: TranslateType<recentDataMap>
): A_OperationPersonalReduxType => {
  switch (data.type) {
    case 'replace_allData_OperationPersonal_Add':
      return {
        ...dataInit,
        allData: data.payload,
      }
    case 'replace_Recent_OperationPersonal_Add':
      return { ...dataInit, recent: data.payload }
    case 'change_Recent_OperationPersonal_option_Add':
      return { ...dataInit, recent: { ...dataInit.recent, ...data.payload } }
    case 'change_Recent_grade_setting_OperationPersonal_option_Add':
      // let newArray: number[] = []
      // let newArray2: number[] = []
      // let firstArray = dataInit.recent.grade_setting[0]
      // let twoArray = dataInit.recent.grade_setting[1]
      // if (data.payload.arrayAddress[0] === 0) {
      //     newArray2 = dataInit.recent.grade_setting[1]
      //     newArray = firstArray.map((item, index) => {
      //         if (index !== data.payload.arrayAddress[1]) {
      //             return item
      //         }
      //         return data.payload.grade_setting_value
      //     })
      // }
      // if (data.payload.arrayAddress[0] === 1) {
      //     newArray = dataInit.recent.grade_setting[0]
      //     newArray2 = twoArray.map((item, index) => {
      //         if (index !== data.payload.arrayAddress[1]) {
      //             return item
      //         }
      //         return data.payload.grade_setting_value
      //     })
      // }
      return {
        ...dataInit,

        recent: {
          ...dataInit.recent,
          ...dataInit.recent.price_setting,
          ...data.payload,
          // grade_setting: [newArray, newArray2]
        },
      }
    case 'change_Recent_OperationPersonal_soft_option_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            ...data.payload,
          },
        },
      }
    case 'change_Recent_Autoface_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Autoface: {
              ...dataInit.recent.soft.Autoface,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Livedrive_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Livedrive: {
              ...dataInit.recent.soft.Livedrive,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Retargeter_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Retargeter: {
              ...dataInit.recent.soft.Retargeter,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Rigger_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Rigger: {
              ...dataInit.recent.soft.Rigger,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Riggermeta_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Riggermeta: {
              ...dataInit.recent.soft.Riggermeta,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Tracker_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Tracker: {
              ...dataInit.recent.soft.Tracker,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Streamer_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Streamer: {
              ...dataInit.recent.soft.Streamer,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Muscledrive_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Muscledrive: {
              ...dataInit.recent.soft.Muscledrive,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Batchrobot_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Batchrobot: {
              ...dataInit.recent.soft.Batchrobot,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Seattlepro_Add':
      // console.log('dataInit.recent-----', dataInit)
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Seattlepro: {
              ...dataInit.recent.soft.Seattlepro,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Seattleaitrainer_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Seattleaitrainer: {
              ...dataInit.recent.soft.Seattleaitrainer,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Posemakerfbx_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Posemakerfbx: {
              ...dataInit.recent.soft.Posemakerfbx,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Posemakerposeasset_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Posemakerposeasset: {
              ...dataInit.recent.soft.Posemakerposeasset,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Retargeterofflinearkit_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Retargeterofflinearkit: {
              ...dataInit.recent.soft.Retargeterofflinearkit,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Retargeterofflinemeta_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Retargeterofflinemeta: {
              ...dataInit.recent.soft.Retargeterofflinemeta,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Retargeterofflineposeasset_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Retargeterofflineposeasset: {
              ...dataInit.recent.soft.Retargeterofflineposeasset,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Retargeterseattleaiofflinecustom_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Retargeterseattleaiofflinecustom: {
              ...dataInit.recent.soft.Retargeterseattleaiofflinecustom,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Fgmodeledit_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Fgmodeledit: {
              ...dataInit.recent.soft.Fgmodeledit,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Livedriverealtimemeta_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Livedriverealtimemeta: {
              ...dataInit.recent.soft.Livedriverealtimemeta,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Livedriverealtimeposeasset_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Livedriverealtimeposeasset: {
              ...dataInit.recent.soft.Livedriverealtimeposeasset,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Livedriverealtimecustom_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Livedriverealtimecustom: {
              ...dataInit.recent.soft.Livedriverealtimecustom,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Batchrobotarkit_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Batchrobotarkit: {
              ...dataInit.recent.soft.Batchrobotarkit,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Batchrobotmeta_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Batchrobotmeta: {
              ...dataInit.recent.soft.Batchrobotmeta,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Batchrobotposeasset_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Batchrobotposeasset: {
              ...dataInit.recent.soft.Batchrobotposeasset,
              ...data.payload,
            },
          },
        },
      }
    case 'change_Recent_Batchrobotcustom_Add':
      return {
        ...dataInit,
        recent: {
          ...dataInit.recent,
          soft: {
            ...dataInit.recent.soft,
            Batchrobotcustom: {
              ...dataInit.recent.soft.Batchrobotcustom,
              ...data.payload,
            },
          },
        },
      }

    case 'clear_Recent_OperationPersonal_data_Add':
      return {
        allData: dataInit.allData,
        // currentPage: 1,
        recent: {
          grade_id: 0,
          grade_image: '',
          grade_name: '',
          grade_type: 0,
          price_setting: [],
          is_show: 0,
          grade_tag: '',
          grade_param: '',
          // grade_setting: [
          //     [0, 0, 0, 0],
          //     [0, 0, 0, 0],
          // ],
          soft: {
            Streamer: {
              soft_on: 0,
              timecode: 0,
              capture: 0,
              multichannel_recording: 0,
            },
            Tracker: {
              soft_on: 0,
              frame: 0,
              gift: 0,
              ai01: 0,
              ai02: 0,
              ai03: 0,
              ai04: 0,
              ai_library: 0,
              ai_template: 0,
            },
            Retargeter: {
              soft_on: 0,
              frame: 0,
              gift: 0,
              keyframe_solve: 0,
              expression_library_solve: 0,
            },

            Livedrive: {
              soft_on: 0,
              capture: 0,
              timecode: 0,
              open3d: 0,
              usage_time: 0,
              algorithm: '',
              gift: 0,
              seattle_ai_arkit: 0,
            },
            Autoface: {
              count: 0,
              soft_on: 0,
              gift: 0,
              default_template: 0,
              custom_template: 0,
            },
            Rigger: {
              soft_on: 0,
              count: 0,
              gift: 0,
              arkit_template: 0,
              emily_template: 0,
            },
            Riggermeta: {
              soft_on: 0,
              count: 0,
              gift: 0,
            },
            Muscledrive: {
              soft_on: 0,
              count: 0,
              gift: 0,
            },
            Batchrobot: {
              soft_on: 0,
              count: 0,
              gift: 0,
              tracker_batch_processing: 0,
              retargeter_batch_processing: 0,
            },
            Seattlepro: {
              soft_on: 0,
              gift: 0,
              offline: 0, //1开启0关闭
              offline_frame: 0, // 离线帧数，-1位无限制
              online: 0, //1开启0关闭
              online_time: 0, //时长，-1无限制
              fgmodeledit: 0,
            },
            Seattleaitrainer: {
              soft_on: 0,
              file_export: 0,
            },
            Fgmodeledit: {
              soft_on: 0,
            },
            Seattlelitemetahuman: {
              soft_on: 0,
            },
            Posemakerfbx: {
              soft_on: 0,
            },
            Posemakerposeasset: {
              soft_on: 0,
            },
            Retargeterofflinearkit: {
              soft_on: 0,
            },
            Retargeterofflinemeta: {
              soft_on: 0,
            },
            Retargeterofflineposeasset: {
              soft_on: 0,
            },
            Retargeterseattleaiofflinecustom: {
              soft_on: 0,
            },
            Livedriverealtimemeta: { soft_on: 0 },
            Livedriverealtimeposeasset: { soft_on: 0 },
            Livedriverealtimecustom: { soft_on: 0 },
            Batchrobotarkit: { soft_on: 0 },
            Batchrobotmeta: { soft_on: 0 },
            Batchrobotposeasset: { soft_on: 0 },
            Batchrobotcustom: { soft_on: 0 },
          },
        },
      }
    default:
      return { ...dataInit }
  }
}
export default reducer
