import React from 'react';
import {GlobalDispatch, useRootStore} from "@/store";
import PublicStyle from "../index.module.scss"
import {Input, message, Switch} from "antd";
import SoftwareControl from "@/components/OperationSoftwareControl";

interface IProp {
}

const OperationEducationModal = (props: IProp) => {
    const OperationEducation = useRootStore(state => state.OperationEducation.recent)
    const onChange = (checked: any) => {
        GlobalDispatch({
            type: "change_Recent_grade_setting_OperationEducation_option",
            payload: {market_edit: checked ? 1 : 0}
        })
    }
    const changeGrade_name = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_OperationEducation_option", payload: {grade_name: e.target.value}})
    }
    const changeLifelong_price = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({
            type: "change_Recent_grade_setting_OperationEducation_option",
            payload: {lifelong_price: Number(e.target.value)}
        })
    }
    const changeUser_min = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({
            type: "change_Recent_grade_setting_OperationEducation_option",
            payload: {user_min: Number(e.target.value)}
        })
    }
    const changeUser_max = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({
            type: "change_Recent_grade_setting_OperationEducation_option",
            payload: {user_max: Number(e.target.value)}
        })
    }
    return (<div className={PublicStyle.Box}>
        <div className={PublicStyle.title}>基础设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>等级名称：</span><Input
                value={OperationEducation.grade_name} onChange={changeGrade_name} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>是否允许市场人员修改权限：</span><Switch
                checked={Boolean(OperationEducation.grade_setting.market_edit)} onChange={onChange}/>
            </div>
        </div>
        <div className={PublicStyle.title}>收费设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>单价：</span><Input
                value={OperationEducation.grade_setting.lifelong_price} onChange={changeLifelong_price}
                style={{width: "45%"}} suffix="元/终身"/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>用户数量：</span>
                <Input.Group compact style={{padding: "4px 15px"}}>
                    <Input style={{
                        width: 100,
                        textAlign: 'center',
                        borderRightWidth: "1"
                    }}
                           spellCheck={false}
                           value={OperationEducation.grade_setting.user_min}
                           onChange={changeUser_min}
                           placeholder="min"
                           suffix="个"/>
                    <Input className="site-input-split"
                           style={{
                               width: 30,
                               borderRight: 0,
                               pointerEvents: 'none',
                               backgroundColor: "#fff",
                           }}
                           spellCheck={false}
                           placeholder="~"
                           disabled
                    />
                    <Input
                        style={{
                            width: 100,
                            textAlign: 'center',
                        }}
                        value={OperationEducation.grade_setting.user_max}
                        onChange={changeUser_max}
                        placeholder="Max"
                        suffix="个"
                    />
                </Input.Group>
            </div>
        </div>
        <div className={PublicStyle.title}>软件权限</div>
        <div className={PublicStyle.infoBox}>
            <SoftwareControl recentName={"OperationEducation"}/>
        </div>
    </div>)
}
export default OperationEducationModal
