import React, { useEffect } from 'react'
import style from './index.module.scss'

import { Checkbox, Input, message,Switch } from 'antd'
import { GlobalDispatch, useRootStore } from '@/store'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const RiggermetaPNG = require('@/assets/img/software/rigger.png').default

interface IProps {}

let initData = 0
const Riggermeta = (props: IProps) => {
  const Riggermeta = useRootStore(
    (state) => state.OperationPersonal_Add.recent.soft.Riggermeta
  )
  const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("e.target.value:" + e.target.value)
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Riggermeta_Add',
      payload: { count: Number(e.target.value) },
    })
  }
  const Riggermeta_frame_Checkbox = (e: CheckboxChangeEvent) => {
    GlobalDispatch({
      type: 'change_Recent_Riggermeta_Add',
      payload: { count: e.target.checked ? -1 : initData },
    })
  }
  const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
    GlobalDispatch({
      type: 'change_Recent_Riggermeta_Add',
      payload: { gift: Number(e.target.value) },
    })
  }
 

  useEffect(() => {
    if (Riggermeta.count === -1) return
    initData = Riggermeta.count
  }, [])
  return (
    <div className={style.softwareContainer}>
      <div className={style.imgAndTitle}>
        <img src={RiggermetaPNG} alt="" />
        <div className={style.title}>Riggermeta权限</div>
      </div>
      <div className={style.content}>
        <div className={style.Line}>
          <div className={style.lineName}>每月可用总个数:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Riggermeta.count === -1 ? '无限' : Riggermeta.count}
              onChange={changeDay}
              spellCheck={false}
              suffix="个"
            />
            <Checkbox
              checked={Riggermeta.count === -1}
              onChange={Riggermeta_frame_Checkbox}
            >
              无限制
            </Checkbox>
          </div>
        </div>
        <div className={style.Line}>
          <div className={style.lineName}>赠送:</div>
          <div className={style.chosePart}>
            <Input
              style={{ width: '30%', margin: '0 10px' }}
              value={Riggermeta.gift}
              onChange={changeGift}
              disabled={Riggermeta.count === -1}
              spellCheck={false}
              suffix="个"
            />
          </div>
        </div>
      
      </div>
    </div>
  )
}

export default Riggermeta
