const userType = {
    admin: 1,
    root: 2,
    Operation: 3,
    Market: 4
}
const OperationLoginSetType = {
    personal: 1,
    enterprise: 2,
    education: 3
}
const OperationType = {
    enterprise: 1,
    education: 2,
}
const MarketType = {
    enterprise: 1,
    education: 2,
}

export {
    userType, OperationLoginSetType, MarketType, OperationType
}
