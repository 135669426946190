import React from 'react'
import style from './index.module.scss'
import {Switch} from "antd";
import {GlobalDispatch, useRootStore} from "@/store";

const streamerPNG = require("@/assets/img/software/streamer.png").default

interface IProps {

}

const Streamer = (props: IProps) => {
    const Streamer = useRootStore(state => state.OperationPersonal.recent.soft.Streamer)
    const onChangeTimeCodeCheckbox = (value: boolean) => {
        GlobalDispatch({type: "change_Recent_Streamer", payload: {timecode: value ? 1 : 0}})
    }
    const onChangeCaptureCheckbox = (value: boolean) => {
        GlobalDispatch({type: "change_Recent_Streamer", payload: {capture: value ? 1 : 0}})
    }
    const onChangeMultichannelRecording = (value: boolean) => {
      GlobalDispatch({type: "change_Recent_Streamer", payload: {multichannel_recording: value ? 1 : 0}})
  }
    return (
        <div className={style.softwareContainer}>
            <div className={style.imgAndTitle}>
                <img src={streamerPNG} alt=""/>
                <div className={style.title}>Streamer权限</div>
            </div>
            <div className={style.content}>
                <div className={style.Line}>
                    <div className={style.lineName}>时码功能:</div>
                    <div className={style.chosePart}>
                        <Switch checked={Boolean(Streamer.timecode)} onChange={onChangeTimeCodeCheckbox}/>
                    </div>
                    <div className={style.lineName}>多路录制:</div>
                        <div className={style.chosePart}>
                            <Switch checked={Boolean(Streamer.multichannel_recording)}
                                    onChange={onChangeMultichannelRecording}/>
                        </div>
                    <div className={style.chosePart}>
                        <div className={style.lineName}>动捕触发功能:</div>
                        <Switch checked={Boolean(Streamer.capture)} onChange={onChangeCaptureCheckbox}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Streamer
