import React, {useEffect, useState} from 'react';
import {GlobalDispatch, useRootStore} from "@/store";
import PublicStyle from "../index.module.scss"
import {Button, DatePicker, Dropdown, Input, message} from "antd";
import moment from 'moment';
import SoftwareControl from "@/components/MarketSoftwareControl";
import {DownOutlined} from "@ant-design/icons";
import CreatAntdMenu from "@/utils/creatAntdUI";
import {isNullOrUndefined} from "@/utils/util";
import {account_softApi} from "@/api/MarketApi";

const {RangePicker} = DatePicker;

interface IProp {
}

const MarketEnterpriseModal = (props: IProp) => {
    const MarketEnterprise = useRootStore(state => state.MarketEnterprise.recent)
    const EnterpriseDropdown = useRootStore(state => state.DropdownTitle.EnterpriseDropdown)
    const MarketAccount_soft = useRootStore(state => state.MarketAccount_soft)
    const [DropOption, setDropOption] = useState<string>("")
    const disabledDate = (current: any) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    const getDropdownOption = async (name: { grade_id: number, grade_name: string }) => {
        GlobalDispatch({type: "replace_Recent_MarketEnterprise_soft_init", payload: {}})
        let account_soft = await account_softApi("0", name.grade_id)
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {grade_id: name.grade_id}})
        if (!isNullOrUndefined(account_soft.data.data)) {
            GlobalDispatch({type: "replace_Account_soft", payload: account_soft.data.data})
            GlobalDispatch({
                type: "replace_Recent_MarketEnterprise_Account_soft_to_soft_config",
                payload: account_soft.data.data
            })
        } else {
            GlobalDispatch({type: "clear_Account_soft", payload: []})
        }
        GlobalDispatch({type: "replace_Recent_MarketEnterprise_soft_init", payload: {}})
        setDropOption(name.grade_name)
    }
    const onChange = (value: any, dateString: any) => {
        GlobalDispatch({
            type: "change_Recent_MarketEnterprise_option",
            payload: {company_effect_date: dateString[0]}
        })
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {company_usage_day: dateString[1]}})
    }

    const changeCompany_title = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {company_account_num: e.target.value}})
    }
    const changeCompany_name = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {company_name: e.target.value}})
    }
    const changeCompany_show_pass = (e: React.ChangeEvent<HTMLInputElement>) => {
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {company_show_pass: e.target.value}})
    }
    const changeCompany_account_num = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_MarketEnterprise_option", payload: {company_account_num: e.target.value}})
    }
    useEffect(() => {
        if (MarketEnterprise.grade_id === 0) {
            setDropOption('请选择')
            return
        }
        setDropOption(EnterpriseDropdown.find(item => item.grade_id === MarketEnterprise.grade_id)!.grade_name)
    }, [EnterpriseDropdown, MarketEnterprise])
    return (<div className={PublicStyle.Box}>
        <div className={PublicStyle.title}>基础设定</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>公司名称：</span><Input
                value={MarketEnterprise.company_title} onChange={changeCompany_title} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>登录账号：</span><Input
                value={MarketEnterprise.company_name} onChange={changeCompany_name} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>登录密码：</span><Input
                value={MarketEnterprise.company_show_pass} onChange={changeCompany_show_pass} style={{width: "45%"}}/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>账号数量：</span><Input
                value={MarketEnterprise.company_account_num} onChange={changeCompany_account_num} style={{width: "45%"}}
                suffix="个"/>
            </div>
            <div className={PublicStyle.part}><span className={PublicStyle.partName}>账户类型：</span>
                <Dropdown overlay={CreatAntdMenu(EnterpriseDropdown, getDropdownOption)} placement="bottomCenter">
                    <Button>{DropOption}<DownOutlined/></Button>
                </Dropdown>
            </div>
        </div>
        <div className={PublicStyle.title}>有效期</div>
        <div className={PublicStyle.infoBox}>
            <div className={PublicStyle.part}>
                <RangePicker
                    defaultValue={[
                        moment(isNullOrUndefined(MarketEnterprise.company_effect_date) ? moment().format("YYYY-MM-DD") : MarketEnterprise.company_effect_date, 'YYYY-MM-DD'),
                        moment(isNullOrUndefined(MarketEnterprise.company_usage_day) ? moment().format("YYYY-MM-DD") : MarketEnterprise.company_usage_day, 'YYYY-MM-DD')
                    ]}
                    disabledDate={disabledDate}
                    placeholder={["生效日期", "截至日期"]} format="YYYY-MM-DD"
                    onChange={onChange}/></div>
        </div>
        {isNullOrUndefined(MarketAccount_soft) ? null :
            <div className={PublicStyle.infoBox}>
                <SoftwareControl recentName={"MarketEnterprise"}/>
            </div>}
    </div>)
}
export default MarketEnterpriseModal
