import {TableType} from "@/interface/tableType";

const OperationEducationTable: TableType[] = [
    {
        title: "序号",
        key: "SerialNumber",
    }, {
        title: "会员等级",
        key: "MembershipLevel",
    }, {
        title: "账户数量",
        key: "NumberOfAccounts",
    }, {
        title: "账户权限",
        key: "AccountAuthority",
    }, {
        title: "市场单价",
        key: "MarketUnitPrice",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "编辑", title: "运营端教育编辑", key: "OperationEducationModal", symbol: "OperationEducation"},
            {buttonTitle: "删除", title: "删除", key: "DeleteModal", symbol: "OperationEducation"}
        ]
    },
]
export default OperationEducationTable
