import React, { useEffect } from 'react'
import { GlobalDispatch, useRootStore } from '@/store'
import PublicStyle from '../index.module.scss'
import { Card, Form, Input, message, Select, Switch, Button, Space } from 'antd'
import SoftwareControl from '@/components/OperationSoftwareControl'
import SoftWare, { softNameMap } from '@/components/SoftwareInfo_Add'
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons'
const { Option } = Select
const { useForm } = Form

interface IProp {}

const OperationPersonalModal = (props: IProp) => {
  const recent = useRootStore((state) => state.OperationPersonal_Add.recent)
  let ArrayList = {
    market_price: 0,
    discount_price: 0,
    cycle_num: 0,
    cycle_type: 1,
  }
  const [form] = useForm()
  const soft = recent.soft
  let softString = Object.keys(soft)
  let softArray = softString as softNameMap[]
  let newArray = softArray.map((item: softNameMap) => {
    if (soft[item].soft_on === 1) {
      return { show: true, service_title: item }
    }
    return { show: false, service_title: item }
  })

  const changeGrade_name = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationPersonal_option_Add',
      payload: { grade_name: e.target.value },
    })
  }
  const changeGrade_image = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationPersonal_option_Add',
      payload: { grade_image: e.target.value },
    })
  }
  const changeGrade_tag = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationPersonal_option_Add',
      payload: { grade_tag: e.target.value },
    })
  }
  const changeGrade_param = (e: React.ChangeEvent<HTMLInputElement>) => {
    GlobalDispatch({
      type: 'change_Recent_OperationPersonal_option_Add',
      payload: { grade_param: e.target.value },
    })
  }
  // const changeGrade_setting = (e: React.ChangeEvent<HTMLInputElement>, arrayAddress: [number, number]) => {
  //     if ((/[^\d]/g).test(e.target.value)) {
  //         message.error("只能输入数字");
  //         return
  //     }
  //     GlobalDispatch({
  //         type: "change_Recent_grade_setting_OperationPersonal_option_Add",
  //         payload: {grade_setting_value: Number(e.target.value), arrayAddress}
  //     })
  // }
  const onFinish = (changedValues: any, allValues: any) => {
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationPersonal_option_Add',
      payload: { price_setting: allValues },
    })
  }
  const changeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/[^\d]/g.test(e.target.value)) {
      message.error('只能输入数字')
      return
    }
  }
  const onIsShowChange = (checked: any) => {
    GlobalDispatch({
      type: 'change_Recent_grade_setting_OperationPersonal_option_Add',
      payload: { is_show: checked ? 1 : 0 },
    })
  }
  useEffect(() => {
    form.setFieldsValue({
      setting: recent.price_setting.setting,
    })
  }, [form])

  return (
    <div className={PublicStyle.Box}>
      <div className={PublicStyle.title}>基础设定</div>
      <div className={PublicStyle.infoBox}>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>等级名称：</span>
          <Input
            value={recent.grade_name}
            onChange={changeGrade_name}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>适用人群：</span>
          <Input
            value={recent.grade_image}
            onChange={changeGrade_image}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>标签(选填)：</span>
          <Input
            value={recent.grade_tag}
            onChange={changeGrade_tag}
            placeholder={'以逗号分隔。例如：xxx,xxx'}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>参数文档：</span>
          <Input
            value={recent.grade_param}
            onChange={changeGrade_param}
            style={{ width: '45%' }}
          />
        </div>
        <div className={PublicStyle.part}>
          <span className={PublicStyle.partName}>是否Avatary页面显示：</span>
          <Switch checked={Boolean(recent.is_show)} onChange={onIsShowChange} />
        </div>
      </div>
      <div className={PublicStyle.title}>收费设定</div>
      <div className={PublicStyle.infoBox} style={{ flexDirection: 'row' }}>
        <Form
          name="dynamic_form_nest_item"
          onValuesChange={onFinish}
          autoComplete="off"
          form={form}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <Form.List name="setting">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      padding: 20,
                      margin: '20px',
                      border: '1px solid #e1e1e1',
                      position: 'relative',
                      width: '300px',
                      height: '200px',
                    }}
                  >
                    <Form.Item
                      label="单 价："
                      labelCol={{ span: 7 }}
                      {...restField}
                      name={[name, 'market_price']}
                    >
                      <Input onChange={changeData} />
                    </Form.Item>
                    <Form.Item
                      label="折扣价："
                      labelCol={{ span: 7 }}
                      {...restField}
                      name={[name, 'discount_price']}
                    >
                      <Input onChange={changeData} />
                    </Form.Item>
                    <div className={PublicStyle.part}>
                      <Form.Item
                        label="周 期"
                        labelCol={{ span: 9 }}
                        style={{ marginBottom: 0 }}
                        {...restField}
                        name={[name, 'cycle_num']}
                      >
                        <Input onChange={changeData} />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        {...restField}
                        name={[name, 'cycle_type']}
                      >
                        <Select>
                          <Option value={1}>月</Option>
                          <Option value={12}>年</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <CloseCircleFilled
                      onClick={() => remove(name)}
                      className={PublicStyle.add}
                      style={{ fontSize: 22 }}
                    />
                  </div>
                ))}
                <Form.Item>
                  <Card
                    style={{ width: '250px', height: 200, marginTop: 20 }}
                    onClick={() => add(ArrayList)}
                  >
                    <PlusCircleFilled
                      className={PublicStyle.PlusCircleFilled}
                    />
                  </Card>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </div>

      <div className={PublicStyle.title}>软件权限</div>
      <div className={PublicStyle.infoBox}>
        <SoftwareControl recentName={'OperationPersonal_Add'} />
      </div>

      {newArray.map((item) => {
        // @ts-ignore
        if (item.show && item.service_title !== 'Seattlelitemetahuman') {
          return <SoftWare name={item.service_title} key={item.service_title} />
        }
        return null
      })}
    </div>
  )
}
export default OperationPersonalModal
