import {HashRouter, Route, Switch} from 'react-router-dom'
import React from "react"
import MarketEnterprise from "../view/MarketEnterprise"
import MarketEducation from "../view/MarketEducation"
import OperationEducation from "../view/OperationEducation"
import OperationEnterprise from "../view/OperationEnterprise"
import OperationPersonal from "../view/OperationPersonal"
import UserLoginRestrictions from "../view/UserLoginRestrictions"
import {userType} from "@/interface/EnumList";
import {GlobalState} from "@/store";

const router = () => {
    return (
        <HashRouter>
            <Switch>
                <Route path="/home"
                       component={GlobalState.userInfo.type === userType.Market ? MarketEnterprise : OperationPersonal}
                       exact/>
                <Route path="/home/market_enterprise" exact component={MarketEnterprise}/>
                <Route path="/home/market_education" exact component={MarketEducation}/>
                <Route path="/home/operation_personal" exact component={OperationPersonal}/>
                <Route path="/home/operation_enterprise" exact component={OperationEnterprise}/>
                <Route path="/home/operation_education" exact component={OperationEducation}/>
                <Route path="/home/user_login_restrictions" exact component={UserLoginRestrictions}/>
            </Switch>
        </HashRouter>
    )
}
export default router
