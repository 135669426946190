import React, {useEffect} from 'react'
import style from './index.module.scss'

import {Checkbox, Input, message} from "antd";
import {GlobalDispatch, useRootStore} from "@/store";
import {CheckboxChangeEvent} from "antd/es/checkbox";

const muscledrivePNG = require("@/assets/img/software/muscledrive.png").default

interface IProps {

}

let initData = 0
const MuscleDrive = (props: IProps) => {
    const Muscledrive = useRootStore(state => state.OperationPersonal_Add.recent.soft.Muscledrive)
    const changeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Muscledrive_Add", payload: {count: Number(e.target.value)}})
    }
    const changeGift = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ((/[^\d]/g).test(e.target.value)) {
            message.error("只能输入数字");
            return
        }
        GlobalDispatch({type: "change_Recent_Muscledrive_Add", payload: {gift: Number(e.target.value)}})
    }
    const Muscledrive_frame_Checkbox = (e: CheckboxChangeEvent) => {
        GlobalDispatch({type: "change_Recent_Muscledrive_Add", payload: {count: e.target.checked ? -1 : initData}})
    }
    useEffect(() => {
        if (Muscledrive.count === -1) return
        initData = Muscledrive.count
    }, [])
    return (
        <div className={style.softwareContainer}>
            <div className={style.imgAndTitle}>
                <img src={muscledrivePNG} alt=""/>
                <div className={style.title}>Muscledrive权限</div>
            </div>
            <div className={style.content}>
                <div className={style.Line}>
                    <div className={style.lineName}>每月可用总个数:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Muscledrive.count === -1 ? "无限" : Muscledrive.count}
                               onChange={changeDay}
                               spellCheck={false} suffix="个"/>
                        <Checkbox checked={Muscledrive.count === -1}
                                  onChange={Muscledrive_frame_Checkbox}>无限制</Checkbox>
                    </div>
                </div>
                <div className={style.Line}>
                    <div className={style.lineName}>赠送:</div>
                    <div className={style.chosePart}>
                        <Input style={{width: "30%", margin: "0 10px"}}
                               value={Muscledrive.gift}
                               onChange={changeGift}
                               disabled={Muscledrive.count === -1}
                               spellCheck={false} suffix="个"/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MuscleDrive
