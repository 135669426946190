import React, {useEffect, useState} from 'react'
import style from './index.module.scss'
import {UserLoginReduxType} from "@/interface/recentType";
import {GlobalDispatch, useRootStore} from "@/store";
import {isNullOrUndefined} from "@/utils/util";
import {Button, Space, Table} from "antd";
import {EducationLoginTable, EnterpriseLoginTable, UserLoginTable} from "@/UISetting/UserLoginTable";
import Dialog from "@/components/Dialog";
import {expire_infoApi, login_settingApi} from "@/api/OperationApi";
import {OperationLoginSetType} from "@/interface/EnumList";
import {spaceOptionType} from "@/interface/tableType";

const {Column} = Table;

interface IProps {

}

const UserLoginRestrictions = (props: IProps) => {
    const LoginSetting = useRootStore(state => state.LoginSetting)
    const [visible, setVisible] = useState(false);
    const [OptionType, setOptionType] = useState<spaceOptionType>({
        buttonTitle: "",
        title: "",
        key: "LimitSettingsModal",
        symbol: "EducationLogin"
    });
    const TableList = [
        {
            title: "个人用户登录限制",
            Table: UserLoginTable,
            data: LoginSetting.allData.filter(item => OperationLoginSetType.personal === Number(item.key)) || []
        },
        {
            title: "企业用户登录限制",
            Table: EnterpriseLoginTable,
            data: LoginSetting.allData.filter(item => OperationLoginSetType.enterprise === Number(item.key)) || []
        },
        {
            title: "教育用户登录限制",
            Table: EducationLoginTable,
            data: LoginSetting.allData.filter(item => OperationLoginSetType.education === Number(item.key)) || []
        }
    ]
    const operationClickHandle = (e: any, item: spaceOptionType, recode: UserLoginReduxType) => {
        GlobalDispatch({type: "write_recent_UserLogin", payload: recode})
        setOptionType(item)
        setVisible(true)
    }
    useEffect(() => {
        (async () => {
            let login_setting_enterprise = await login_settingApi(OperationLoginSetType.enterprise)
            let login_setting_education = await login_settingApi(OperationLoginSetType.education)
            let login_setting_personal = await login_settingApi(OperationLoginSetType.personal)
            let expire_info = await expire_infoApi()
            GlobalDispatch({type: "write_allData_UserLogin", payload: login_setting_enterprise.data.data[0]})
            GlobalDispatch({type: "write_allData_UserLogin", payload: login_setting_education.data.data[0]})
            GlobalDispatch({
                type: "write_allData_UserLogin",
                payload: {...expire_info.data.data[0], ...login_setting_personal.data.data[0]}
            })
        })()
    }, [])
    return (
        <div className={style.container}>
            {TableList.map(item =>
                <div key={item.title}>
                    <div className={style.TableTitle}>
                        {item.title}
                    </div>
                    <div className={style.Table}>
                        <Table dataSource={item.data}
                               pagination={false}>
                            {item.Table.map((item) => {
                                    return <Column title={item.title} dataIndex={item.key} key={item.key}
                                                   render={(text: string, recode: UserLoginReduxType, index: number) => {
                                                       if (isNullOrUndefined(item.SpaceOption)) {
                                                           return text
                                                       }
                                                       return <Space size="middle">
                                                           {item.SpaceOption!.map(item => {
                                                               return <Button type="primary" key={item.key}
                                                                              onClick={(e) => {
                                                                                  operationClickHandle(e, item, recode)
                                                                              }}>{item.buttonTitle}</Button>
                                                           })}
                                                       </Space>
                                                   }}/>
                                }
                            )}
                        </Table>
                    </div>
                </div>
            )}
            <Dialog OptionType={OptionType} ModuleVisible={visible}
                    setModuleVisible={setVisible}/>
        </div>
    )
}
export default UserLoginRestrictions
