import packageConfig from '../../package.json'
//http://vendor.avatary.cc:83  英文测试
//https://en.avatary.cc 英文正式
//https://api.facegood.cc  中文正式
//http://vendor.avatary.cc:81  中文测试
//测试
// let Url = packageConfig.isEnglish ? "http://vendor.avatary.cc:83" : "http://vendor.avatary.cc:81"
//正式
 let Url = packageConfig.isEnglish ? "https://en.avatary.cc" : "https://api.facegood.cc"
let Token = JSON.parse(localStorage.getItem("token")!)
export {Url, Token}
