import React, {useEffect, useState} from 'react';
import {Modal} from "antd";
import CreatModal, {ModalMapType} from "./DialogTypeComponent"
import {isNullOrUndefined} from "@/utils/util";
import {spaceOptionType} from "@/interface/tableType";
import {
    DelayModal_callback,
    DeleteModal_callback,
    LimitSettingsModal_callback,
    MarketEducationModal_Add_callback,
    MarketEducationModal_callback,
    MarketEnterpriseModal_Add_callback,
    MarketEnterpriseModal_callback,
    OperationEducationModal_Add_callback,
    OperationEducationModal_callback,
    OperationEnterpriseModal_Add_callback,
    OperationEnterpriseModal_callback,
    OperationPersonalModal_Add_callback,
    OperationPersonalModal_callback,
    ReminderSettingsModal_callback
} from "@/components/Dialog/dialogOkCallback";
import {GlobalDispatch, GlobalState} from "@/store";

interface IProp {
    OptionType: spaceOptionType
    ModuleVisible: boolean
    setModuleVisible: (flag: boolean) => void
}

const Dialog = ({OptionType, ModuleVisible, setModuleVisible}: IProp) => {

    const [currentDialog, setCurrentDialog] = useState<ModalMapType>("DelayModal")
    const titleName = () => {
        if (!isNullOrUndefined(OptionType.key)) {
            setCurrentDialog(OptionType.key)
        }
    }
    const ModuleHandle = async () => {
        switch (currentDialog) {
            case "ReminderSettingsModal":
                ReminderSettingsModal_callback().then(_ => {
                })
                break
            case "OperationPersonalModal":
                OperationPersonalModal_callback().then(_ => {
                })
                break
            case "OperationEnterpriseModal":
                OperationEnterpriseModal_callback().then(_ => {
                })
                break
            case "OperationEducationModal":
                OperationEducationModal_callback().then(_ => {
                })
                break
            case "OperationPersonalModal_Add":
                OperationPersonalModal_Add_callback().then(_ => {
                })
                break
            case "OperationEnterpriseModal_Add":
                OperationEnterpriseModal_Add_callback().then(_ => {
                })
                break
            case "OperationEducationModal_Add":
                OperationEducationModal_Add_callback().then(_ => {
                })
                break
            case "MarketEnterpriseModal":
                MarketEnterpriseModal_callback().then(_ => {
                })
                break
            case "MarketEducationModal":
                MarketEducationModal_callback().then(_ => {
                })
                break
            case "MarketEnterpriseModal_Add":
                MarketEnterpriseModal_Add_callback().then(_ => {
                })
                break
            case "MarketEducationModal_Add":
                MarketEducationModal_Add_callback().then(_ => {})
                break
            case "LimitSettingsModal":
                LimitSettingsModal_callback(OptionType).then(_ => {
                })
                break
            case "DeleteModal":
                DeleteModal_callback().then(_ => {
                })
                break
            case "DelayModal":
                DelayModal_callback().then(_ => {
                })
            default:
                break
        }
        setModuleVisible(false)
        // window.location.reload() 
    }
    const moduleCancelHandle = () => {
        setModuleVisible(false)
        GlobalDispatch({type: "clear_del_array", payload: []})
        GlobalDispatch({type: "clear_delay_array", payload: {}})
    }
    useEffect(() => {
        titleName()
    }, [titleName])
    return (
        <Modal
            title={OptionType.title}
            centered
            destroyOnClose
            maskClosable={false}
            visible={ModuleVisible}
            onOk={ModuleHandle}
            onCancel={moduleCancelHandle}
            width={800}
        >
            <CreatModal ModuleName={currentDialog}/>
        </Modal>

    )
}
export default Dialog
