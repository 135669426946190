import {TableType} from "@/interface/tableType";

const OperationEnterpriseTable: TableType[] = [
    {
        title: "序号",
        key: "SerialNumber",
    }, {
        title: "会员等级",
        key: "MembershipLevel",
    }, {
        title: "账户数量",
        key: "NumberOfAccounts",
    }, {
        title: "账户权限",
        key: "AccountAuthority",
    }, {
        title: "市场单价",
        key: "MarketUnitPrice",
    }, {
        title: "折扣单价",
        key: "DiscountUnitPrice",
    }, {
        title: "操作",
        key: "Action",
        SpaceOption: [
            {buttonTitle: "编辑", title: "运营端企业编辑", key: "OperationEnterpriseModal", symbol: "OperationEnterprise"},
            {buttonTitle: "删除", title: "删除", key: "DeleteModal", symbol: "OperationEnterprise"}
        ]
    },
]
export default OperationEnterpriseTable
