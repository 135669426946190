import React, {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Router from "../../router/secondaryRouter"
import {Dropdown, Menu, message} from 'antd';
import {BarChartOutlined, DownOutlined, RadarChartOutlined} from '@ant-design/icons';
import style from "./index.module.scss";
import {TopBar} from "@/UISetting/DropdownSetting";
import CreatAntdMenu from "@/utils/creatAntdUI";
import {GlobalDispatch, useRootStore} from "@/store";
import {isNullOrUndefined} from "@/utils/util";
import {userType} from "@/interface/EnumList";
import {grade_Fun, soft_Fun} from "@/api/OperationApiFun";
import packageConfig from '../../../package.json'

const {SubMenu} = Menu;

interface IProps {
}

const Home = (props: IProps) => {
    let history = useHistory();
    const userInfo = useRootStore(state => state.userInfo)
    let localAccount = JSON.parse(localStorage.getItem("account")!)
    let localToken = JSON.parse(localStorage.getItem("token")!)
    const handleClick = (e: any) => {
        switch (e.key) {
            case "MarketEnterprise":
                history.push("/home/market_enterprise")
                break
            case "MarketEducation":
                history.push("/home/market_education")
                break
            case "OperationPersonal":
                history.push("/home/operation_personal")
                break
            case "OperationEnterprise":
                history.push("/home/operation_enterprise")
                break
            case "OperationEducation":
                history.push("/home/operation_education")
                break
            case "UserLoginRestrictions":
                history.push("/home/user_login_restrictions")
                break
            default:
                history.push("/home/market_education")
        }
    };
    const getDropdownOption = (menuOption: { grade_id: number; grade_name: string }) => {
        switch (menuOption.grade_id) {
            case 10009:
                GlobalDispatch({type: "Clear_INFO", payload: {type: 0, token: "", name: ""}})
                localStorage.setItem("token", JSON.stringify(""));
                history.push("/")
                break
            default:
                break
        }
    }
    useEffect(() => {
        soft_Fun().then(_ => {
        })
        grade_Fun().then(_ => {
        })
        if (isNullOrUndefined(localToken)) {
            message.error("Token为空,非法登录");
            history.push("/")
        }
    }, [])
    return (
        <div className={style.container}>
            <div className={style.topBar}>
                <span>FACEGOOD后台</span>
                <div className={style.topBarOption}>欢迎
                    <Dropdown overlay={CreatAntdMenu(TopBar, getDropdownOption)}>
                        <div className={style.name}>{localAccount}<DownOutlined/></div>
                    </Dropdown>
                </div>
            </div>
            <div className={style.context}>
                <Menu
                    onClick={handleClick}
                    style={{width: 256, height: "100%"}}
                    defaultSelectedKeys={userInfo.type === userType.Market ? ['MarketEnterprise'] : ['OperationPersonal']}
                    defaultOpenKeys={userInfo.type === userType.Market ? ['MarketSideAccountManagement'] : ['OperationEndAccountManagement']}
                    mode="inline"
                >
                    {userInfo.type === userType.Operation ? null :
                        <SubMenu key="MarketSideAccountManagement" icon={<BarChartOutlined/>} title="市场端账户管理">
                            <Menu.Item key="MarketEnterprise">
                                企业版账户
                            </Menu.Item>
                            <Menu.Item key="MarketEducation">
                                教育版账户
                            </Menu.Item>
                        </SubMenu>
                    }
                    {userInfo.type === userType.Market ? null :
                        <SubMenu key="OperationEndAccountManagement" icon={<RadarChartOutlined/>} title="运营端账户管理">
                            <Menu.Item key="OperationPersonal">
                                个人版账户
                            </Menu.Item>
                            <Menu.Item key="OperationEnterprise">
                                企业版账户
                            </Menu.Item>
                            <Menu.Item key="OperationEducation">
                                教育版账户
                            </Menu.Item>
                            <template v-if='!packageConfig.isEnglish'>
                            <Menu.Item  key="UserLoginRestrictions">
                                用户登录限制
                            </Menu.Item>
                            </template>
                        </SubMenu>
                    }
                </Menu>
                <Router/>
            </div>
        </div>
    )
}
export default Home
